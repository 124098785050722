import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import media from "../styles"
import { useAuth0 } from "@auth0/auth0-react";
import Board from "../../images/svg/approve.svg"
import { WindowNew } from '@styled-icons/fluentui-system-filled/WindowNew'
import Highlights from "../body/highlights"
import { FilePdfFill } from '@styled-icons/bootstrap/FilePdfFill'


const Gold = styled.span`
background: linear-gradient(#F0CB35, 80%, #C02425);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

`



const PDF = styled(FilePdfFill)`
width: 40px;
margin-bottom: 12px;
fill: #006bff;

${media.phone`
width: 50px;

                `}

${media.desktop`
width: 100px;

                `}

`


const Open = styled(WindowNew)`
height: 12px;
margin-top:-10px;
color: #FF8C78;

`

const Set = styled.div`

width: auto;


${media.desktop`
width: 500px;
margin-right: 20px;

                `}
`







const Whiteboard = styled(Board)`

width: 275px;

${media.tablet`

width: 275px;


                `}

`
const Shift = styled.div`
margin: 0 auto 40px auto;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
flex-wrap: wrap;
width: 100%;
max-width: 300px;


img {
//transform: translate(0px, 20px);
}

${media.desktop`

margin: auto;



                `}

`



const SignUpButton = () => {
  const { loginWithRedirect } = useAuth0();

  return <SignUp onClick={() => loginWithRedirect({screen_hint:"signup"})}>Create your unique brand</SignUp>;
};


const SignUp = styled.button`
font-family: -apple-system, BlinkMacSystemFont, “Segoe UI”, Roboto, Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 17px;
//background: #A6FF00;
background-color: rgb(29 155 240);
border: none;
border-radius: 9999px;
padding: 10px 0 10px 0;
min-height: 44px;
width: 100%;
margin-top: 24px;
cursor: pointer;
//letter-spacing: -0.01em;
//color: #FF0063;
color: rgba(255,255,255,1);

${media.xsmall`
                
width: 300px;
               

                `}


`


const Wrap = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
flex-wrap: wrap;
width: 90%;
padding: 20px 20px 60px 20px;
box-sizing: border-box;
color: rgba(0,0,0,0.95);
max-width: 1200px;
margin: auto;
margin-bottom: 80px;
border-radius: 16px;
//box-shadow: rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 6%) 0px 2px 12px, rgb(0 0 0 / 4%) 0px 8px 14px, rgb(0 0 0 / 2%) 0px 12px 16px;
a {
  text-decoration: none;
  color: rgba(0,0,0,0.95);
  text-decoration: underline 2px solid #FF8C78;
text-decoration-skip-ink: none;
}


//background-color: #e2fff8;
//border: 2px solid;
  //border-color: white;
//background-color: #fff0a9;


  
  line-height: 23px;
  //margin: 28px 0 0 0;
  text-align: left;

  
  ${media.xsmall`
                
  padding: 20px 40px 60px 40px;
                             
              
                              `}


  h1 {
font-family: AkzidenzGrotesk_Regular;

font-size: 64px;
line-height: 76px;
white-space: pre-wrap;
margin-bottom: 18px;
margin-top: 5px;

  }

  h2 {


    font-family: 'AkzidenzGrotesk_Light_Bold';
    font-weight: normal;
    font-variant-ligatures: none;
    letter-spacing: -1px;
font-size: 40px;
line-height: 48px;
white-space: pre-wrap;

margin-bottom: 18px;
color: rgba(0,0,0,0.85);


  }

  p {


    font-size: 16px;
line-height: 24px;
white-space: pre-wrap;
margin: 0 0 18px 0;
color: rgba(0,0,0,0.75);


  }

  ul {
    
    
    margin: 20px 0 0 0;
    
  list-style: none;
  padding: none;
  -webkit-padding-start: 0;
 //margin: 0;
  text-align: center;
  font-size: 14px;
}

${media.tablet`
padding: 40px;

                `}

  ${media.desktop`
  flex-direction: row-reverse;
align-items: center;
justify-content: center;
//box-shadow: rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 6%) 0px 2px 12px, rgb(0 0 0 / 4%) 0px 8px 14px, rgb(0 0 0 / 2%) 0px 12px 16px;
flex-wrap: nowrap;
//margin-bottom: 35px;
//border: 2px solid;
  //border-color: rgb(239 243 244);
  border-radius: 16px;
  width: 90%;

                `}

                

div {

  
}

`


const Italic = styled.span `
font-style: italic;

`

const Ratings = styled.div `
display: flex;
align-items: center;
justify-content: center;
flex-direction: row;
margin-top: 50px;

p {
  color: rgba(0,0,0,0.5)
}

div {
  margin: 0 25px 0 25px;
}

`

const Width = styled.div `
width: 100%;

${media.desktop`
max-width: 600px;
padding-right: 60px;

              `}

`



const Tag = styled.span`
//background-color: #FF8C78;
//background-color: rgb(224 242 237);
//background: #78ffd6; /* fallback for old browsers */
//background: -webkit-linear-gradient(to right, #78ffd6, #a8ff78);  /* Chrome 10-25, Safari 5.1-6 */
//background: linear-gradient(to right, #78ffd6, #a8ff78); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

padding: 2px 5px 2px 2px;
color: rgba(0,0,0,0.8);
border-radius: 4px;
font-size: 13px;
//font-family: AkzidenzGrotesk_Light;
font-weight: bold;
//text-transform: uppercase;
//letter-spacing: 2px;


${media.desktop`



                `}

`

const Highlight = styled.span`

background-color: rgb(224 242 237);
padding: 2px 5px 2px 5px;
color: rgba(0,0,0,0.8);
border-radius: 4px;

//margin-left: 2px;

`

  
  
const Headstart = () => (
 
  <StaticQuery
  query={graphql`
    query {
      placeholderImage: file(relativePath: { eq: "screen_g.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `}


    

  render={data => (
 


    <>

 

   <Wrap>
     
       <Shift>
       <PDF /><PDF /><PDF /><PDF /><PDF /><PDF /></Shift>
       <Width>
       <Tag>SLICK PDFs <Gold>Premium+</Gold></Tag>
     <h2>Kickstart your branding</h2>

     <p>Get a headstart on your brand design. Each name is delivered as an individual, professionally designed PDF with beautiful color, contrast and typography. Visualize your company name across different digital mediums, and be creatively inspired for the next stage in your journey. Perfect for use in pitch decks, sharing or discussion.</p>
     
     
     </Width>
    
    
     
    </Wrap>
 
  
</>

)}
 />
)


export default Headstart
