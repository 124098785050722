import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import media from "../styles"
import { useAuth0 } from "@auth0/auth0-react";
import Star from "../../images/svg/stars.svg"
import moment from 'moment'
import Moment from 'react-moment';


const Stars = styled(Star)`
height: 15px;
`


const Underline = styled.span`
text-decoration: underline 3px solid #ffcf00;
text-decoration-skip-ink: none;

`


const SignUpButton = () => {
  const { loginWithRedirect } = useAuth0();

  return <SignUp onClick={() => loginWithRedirect({screen_hint:"signup"})}>Get started for free</SignUp>;
};


const SignUp = styled.button`
font-family: -apple-system, BlinkMacSystemFont, “Segoe UI”, Roboto, Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 17px;
//background: #A6FF00;
background-color: rgb(29 155 240);
//background-color: #CBF6FF;
border: none;
border-radius: 9999px;
padding: 10px 0 10px 0;
min-height: 44px;
width: 200px;
margin-top: 24px;
cursor: pointer;
//letter-spacing: -0.01em;
//color: #FF0063;
color: rgba(255,255,255,1);
//color: rgba(0,0,0,0.9);

${media.xsmall`
width: 300px;

                `}


`
const BG = styled.div`
width: 100%;
background-color: rgb(247 249 249);
border-bottom: 2px solid;
  border-color: rgb(239 243 244);
`

const Wrap = styled.div`
display: flex;
flex-direction: row;
align-items: flex-start;
justify-content: center;
flex-wrap: wrap;
width: 100%;
padding: 20px 30px 20px 30px;
box-sizing: border-box;
color: rgba(0,0,0,0.95);
max-width: 1200px;
margin: auto;
height: auto;
//margin-bottom: 50px;
padding-bottom: 50px;
padding-top: 50px;


  line-height: 23px;
  //margin: 28px 0 0 0;
  text-align: center;

  
  h1 {
font-family: 'AkzidenzGrotesk_Light_Bold';
font-variant-ligatures: none;
letter-spacing: -2px;
margin-top: 0;
font-size: 52px;
line-height: 52px;
white-space: pre-wrap;
margin-bottom: 24px;
font-weight: normal;
color: rgba(0,0,0,0.9);
//text-align: left;

${media.phone`

text-align: center;




                `}


${media.tablet`
font-size: 64px;
line-height: 64px;

                `}

                ${media.large`

text-align: left;
                `}

  }

  h2 {

    //font-family: AkzidenzGrotesk_Regular;
    font-weight: normal;
font-size: 22px;
line-height: 30px;
white-space: pre-wrap;
//max-width: 300px;
margin-bottom: 20px;
//color: rgb(38 38 39);
color: rgba(0,0,0,0.7);
text-align: center;


${media.phone`

text-align: center;
max-width: none;




                `}

${media.tablet`
font-size: 24px;
line-height: 32px;
margin-bottom: 32px;
width: auto;
max-width: none;

                `}
                ${media.large`

text-align: left;
                `}

  }

  p {


font-size: 18px;
line-height: 26px;
white-space: pre-wrap;
margin: 0 0 0 0;


  }

  ul {
    
    //font-family: AkzidenzGrotesk_Light;
    font-weight: normal;
    margin: 15px 0 0 0;
    
  list-style: none;
  padding: none;
  -webkit-padding-start: 0;
 //margin: 0;
 text-align: center;
  font-size: 14px;

  ${media.large`
  text-align: center;
  //padding-left: 5px;



                `}
  
}

li {
  

} 



  ${media.tablet`
  flex-direction: row;
align-items: center;
justify-content: center;
//height: 600px;
//margin-bottom: 50px;
padding-bottom: 100px;
padding-top: 100px;



                `}



`


const Italic = styled.span `
font-style: italic;

`

const Ratings = styled.div `
display: flex;
align-items: center;
justify-content: center;
flex-direction: row;
margin-top: 50px;
font-weight: normal;


                ${media.tablet`
                margin-top: 100px;
                `}
                ${media.large`
                margin-top: 150px;
                `}

//font-family: AkzidenzGrotesk_Light;

p {
  color: rgba(0,0,0,0.5);
}

div {
  margin: 0 25px 0 25px;
}

`

const Width = styled.div `
width: 100%;





${media.tablet`
  max-width: 630px;

                `}

                ${media.large`
                max-width: 500px;
  display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

                `}
`
const ImageWidth = styled.div`
max-width: 275px;
width: 100%;
margin: auto;
padding-top: 48px;
filter: drop-shadow(.35rem .35rem .35rem rgba(0,0,0,0.5));


                ${media.large`
               
padding-top: 0;
                `}


`
const HeadWidth = styled.div `
width: 100%;
max-width: 545px;


${media.large`
  max-width: 545px;
  display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;


                `}
`

const RatingWidth = styled.div `
width: 100%;



`

const Highlight = styled.span`

background-color: #CBF6FF;
padding: 2px 5px 2px 5px;
color: rgba(0,0,0,0.8);
border-radius: 4px;



`
  var momentDate = moment()
  
const Hero = () => (
 
<StaticQuery
  query={graphql`
    query {
      placeholderImage: file(relativePath: { eq: "hero_phone.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            aspectRatio
            ...GatsbyImageSharpFluid
          }
          
        }
      }
    
      beseye: file(relativePath: { eq: "beseye.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            aspectRatio
            ...GatsbyImageSharpFluid
          }
          
        }
      }

      eo: file(relativePath: { eq: "eo_black_tall.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            aspectRatio
            ...GatsbyImageSharpFluid
          }
          
        }
      }


    }

    
  `}

  

  render={data => ( 

    <>

 
<BG>
   <Wrap>
     <HeadWidth>
      
     <h1>A better way to name your business.</h1>
     <h2>Nameflow uncomplicates naming. Submit a brief to our global creative network, and receive names that you and your customers love—guaranteed.</h2>
     <SignUpButton />
     <ul>
     
       
       <li>✓ &nbsp;Receive your names by <Moment add={{ days: 3 }} format ='dddd, MMMM Do'>{momentDate}</Moment>
        </li>
       
        
        
        
        
        
       
        
        
     </ul>
     </HeadWidth>
     <Width>
       <ImageWidth>
     <Img fluid={data.placeholderImage.childImageSharp.fluid} />
    </ImageWidth>
     
      
     
     
     {/*<ul>
     
       
       <li>✓ &nbsp;Real human brand strategists
        </li>
        <li>✓ &nbsp;Create your brief online
        </li>
        <li>✓ &nbsp;Agency process worth €1000s
        </li>
        <li>✓ &nbsp;Trademark & domain screening
        </li>
        <li>✓ &nbsp;Beautiful PDF presentation
        </li>
        <li>✓ &nbsp;Supercharged—<Highlight>ready in three days</Highlight>
        </li>
        
        
        
        
        
       
        
        
     </ul>*/}
      </Width>
      
<RatingWidth>
       <Ratings>

         <div>
     <Stars />
     <p><Italic>"quality work"</Italic></p>
     </div>
     
     <div>
     <Stars />
     <p><Italic>"perfect solution for company names"</Italic></p>
     </div>

     <div>
     <Stars />
     <p><Italic>"super value"</Italic></p>
     </div>
     
     </Ratings>    
     </RatingWidth>
    </Wrap>
 </BG>
  
</>

)}

  />

  )


export default Hero
