/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

 import React from "react"
 import PropTypes from "prop-types"
 import Helmet from "react-helmet"
 import { useStaticQuery, graphql } from "gatsby"
 
 function SEO({ description, lang, meta, keywords, title, type, image, url, favicon, applefav, applepng }) {
   const { site } = useStaticQuery(
     graphql`
       query {
         site {
           siteMetadata {
             title
             description
             author
             type
             image
           }
         }
       }
     `
   )
 
   const metaDescription = description || site.siteMetadata.description
   const metaType = type || site.siteMetadata.type
   const metaImage = image || site.siteMetadata.image
   const favIcon = favicon
   const appleFav = applefav
   const applePng = applepng
   
 
   return (
     <Helmet
       htmlAttributes={{
         lang,
       }}
       title={title}
       titleTemplate={`%s`}
       link={[ {
         rel: 'icon',
              href: favIcon,
              type: 'image/svg+xml',
       },
       {
        rel: 'icon',
             href: applePng,
             type: 'image/png',
      },
      
       {
        rel: 'mask-icon',
             href: appleFav,
             color: '#000000',
      },
      {
        rel: 'apple-touch-icon',
             href: applePng,
      },
      {
        rel: 'apple-touch-icon',
            sizes: '114 x 144',
             href: applePng,
      },
      {
        rel: 'apple-touch-icon',
            sizes: '72 x 72',
             href: applePng,
      },
      ]}
   
       meta={[
         {
           name: `description`,
           content: metaDescription,
         },
         {
           property: `og:title`,
           content: title,
         },
         {
           property: `og:description`,
           content: metaDescription,
         },
         {
           property: `og:type`,
           content: metaType,
         },
         {
           property: `og:image`,
           content: metaImage,
         },
         {
           property: `og:url`,
           content: url,
         },
         {
           name: `twitter:card`,
           content: `summary`,
         },
         {
           name: `twitter:creator`,
           content: site.siteMetadata.author,
         },
         {
           name: `twitter:title`,
           content: title,
         },
         {
           name: `twitter:description`,
           content: metaDescription,
         },
       ]

       
         .concat(
           keywords.length > 0
             ? {
                 name: `keywords`,
                 content: keywords.join(`, `),
               }
             : []
         )
         .concat(meta)}


         
     />
   )
 }
 
 SEO.defaultProps = {
   lang: `en`,
   meta: [],
   keywords: [],
 }
 
 SEO.propTypes = {
   description: PropTypes.string,
   lang: PropTypes.string,
   meta: PropTypes.array,
   link: PropTypes.array,
   keywords: PropTypes.arrayOf(PropTypes.string),
   title: PropTypes.string.isRequired,
   type: PropTypes.string.isRequired,
   image: PropTypes.string.isRequired,
   url: PropTypes.string.isRequired,
   favicon: PropTypes.string.isRequired,
   applefav: PropTypes.string.isRequired,
   applepng: PropTypes.string.isRequired,
   
 }
 
 export default SEO
 