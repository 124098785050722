import React, { useState, useEffect } from 'react';
import styled from "styled-components"
import { useAuth0 } from '@auth0/auth0-react';




/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const Card = styled.div`
  
  position: relative;
  //background-color: #DCFF93;
  //background-color: rgb(247 249 249);
  //background-color: #FFF0A9;
  //background-color: #CCFCFF;
  //background: #00d2ff;  /* fallback for old browsers */
  background: #E9FFF5; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #78ffd6, #a8ff78);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #78ffd6, #a8ff78); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

 //border: 1px solid;
  //border-color: rgb(239 243 244);
  //color: #796400;
  color: rgb(14 98 69);
  border-radius: 16px;
  padding: 32px 32px 32px 32px;
 
  font-family: -apple-system, BlinkMacSystemFont, “Segoe UI”, Roboto, Helvetica, Arial, sans-serif;
  
  
  
  margin-bottom: 16px;

  h2 {
    font-weight: 900;
  font-size: 23px;
  line-height: 31px;
  margin: 0;
  }

  p {
    font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  margin: 0;
  
  
  
  }

  span{
    opacity: 0.6;
    font-size: 18px;
  line-height: 26px;
  font-weight: normal;
  }

  

`

const Italic = styled.span`

    font-style: italic;
    
`

const Underline = styled.span`
//border-bottom: 3px solid rgba(29, 155, 240);
    //text-decoration: underline solid rgb(29 155 240);
    //text-decoration-thickness: 2px;
    //text-decoration-skip-ink: none;
    font-style: italic;
    
`

const Title = styled.div`
display: flex;
flex-direction: column;

align-items: flex-start;
justify-content: center;


`



const PaymentReceived = () => {
  const { user } = useAuth0();
  
  
  //var newdate = new Date().toLocaleString()
  
  
  return (
    
      <>
        <Card>
          <Title>
        
        
        <h2>Thank you. We've received your payment.<br /><span>We'll be in touch soon. Take a breather⁠—you've earned it.</span></h2>
          
</Title>
          


        </Card>
      </>
    )
  
}

export default PaymentReceived
