import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Arrow, FullHeight, RotatorWrapper, Title, BlockContent, HighLight, MenuLink, HomePageBlock } from "../components/homepageblock"
import ContactBlock from "../components/contactblock"
import Hero from "../components/hero"
import Naming from "../components/leads/left"
import Promise from "../components/leads/promise"
import Green from "../components/leads/banner"
import Branding from "../components/leads/branding"
import About from "../components/leads/title"
import AboutColumn from "../components/leads/columns"
import BannerB from "../components/leads/bannerb"
import Collab from "../components/leads/collab"
import Help from "../components/leads/help"
import ProductsTitle from "../components/leads/productstitle"
import Products from "../components/leads/products"
import ProductsGrid from "../components/leads/productsgrid"


export default ({ data }) => (
  
  
  
  <Layout>
    <SEO title="The Human-First Growth Consultancy since 2019 | Human 8" favicon="/faveight.svg" applepng="/fav8.png" keywords={[`brand`, `business`, `design`]} />
    
    <div id="hero"><Hero/></div><ProductsTitle /><div id="products"><Products /><ProductsGrid /><Green /></div><div id="about"><BannerB /><About /></div><div id="contact"><Help /></div><Promise /><Collab />{/*<Naming /><AboutColumn /> <Branding />
   
    
    
    
    
    
    
  
      <HomePageBlock 
        title="ABOUT"
        blockcontent={
          <>
            <p>Human 8 is a human-centered brand innovation consultancy.</p>
            <p>We help aspirational partners and leaders make sense of today's fast-moving maelstrom of culture, commerce and consumerism.</p>
            <p>In rapidly changing times, brands that thrive are those that experiment, move fast and reorient.</p>
            <p>Using deep research and design-thinking we conceive themes, clarity and meaning around an organization's existence. Helping to unite and inspire teams, steer decisions and more effectively relate to shifting market trends.</p>
            <p>We'll challenge legacy business models, reorient the marketing maze, make sense of digital transformation, push emerging categories forward and strike balance between insight-driven ideas and those that no focus group would ever think to suggest.</p>
            <p>We deliver strategic creative and design assets to communicate original, culturally relevant ideas and experiences. And we use technology and agile entrepreneurial mindsets to quickly solve problems in nontraditional ways.</p>
            <p>We believe that rewards are for risk-takers, and we don't wait for the future.</p>
          </>
                    }
      />

      <HomePageBlock 
        title="CAPABILITIES"
        blockcontent={
          <>
             <p>design thinking, business and brand design (differentiation, identity, purpose, stories and philosophies), unique proposition development, design sprints, fast prototyping, product naming, taglines and campaign creation, copywriting and content production, packaging engineering, experience design, advertising and storytelling, product and services development, trends and culture intelligence, market and opportunity research.</p>
          </>
                      }
        />

      <HomePageBlock 
        title="SELECT CLIENTS WE'VE WORKED FOR"
        blockcontent={
          <>
            <p>Samsung, T-Mobile, Edimax, Everyday Object, Adam Elements, Bitplay, Askey, Biochem.</p>
          </>
                    }
      />

      <ContactBlock />
                  */}
  </Layout>
  
)



export const query = graphql`
query {contentfulHomePage {
  introCopy {
    introCopy
  }bodyCopy {
    bodyCopy
  }
  hero {
    title
    file {
      url}
      fluid(maxWidth: 450) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
  }
 
  allContentfulContentPost (sort: { fields: [createdAt], order: DESC } limit:2)

{
edges {
node {
id
title
slug
createdAt(formatString: "MMMM DD, YYYY")
featuredImage {
  file {url}
  fluid(maxWidth: 500){
    ...GatsbyContentfulFluid_withWebp
  }
 

}
content {childMarkdownRemark {excerpt}}
}
}
}
}




  `
