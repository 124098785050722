import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"
import { StaticQuery, graphql } from "gatsby"
//import Img from "gatsby-image"
import styled from "styled-components"

import media from "../styles"

const Pink = styled.span`
color: #FF0063;
`




const Italic = styled.span`
font-family: 'AkzidenzGrotesk_bold';
font-size: 36px;
letter-spacing: -0.05em;

${media.tablet`
font-size: 48px;


                `}
`

const Goudy = styled.span`
font-family: 'GoudyOldStyleItalic';
letter-spacing: 0;
font-size: 27px;
`

const Wrap = styled.div`

grid-row-start: 1;
justify-self: end;
margin-top: 80px;
margin-bottom: 30px;


${media.desktop`
grid-column-start: 5;
grid-column-end: 11;
margin-top: 120px;
margin-bottom: 0;

                `}


`

const Intro = styled.div`
font-family: 'GoudyOldStyleItalicBold';
font-size: 22px;
//letter-spacing: -0.01em;
line-height: 1.6em;
color: rgba(0,0,0,0.95);

${media.tablet`
font-size: 36px;


                `}
`


  
  
const Highlights = () => 
 
 /* <StaticQuery
  query={graphql`
    query {
      fileName: file(relativePath: { eq: "gatsby-atrsonaut.png" }) {
        childImageSharp {
         fluid(maxWidth: 400, maxHeight: 250) {
            ...GatsbyImageSharpFluid
        }
      }
    }
    }
  `}


  render={data => */

    <>


   <Wrap>
     
     
      <Intro>
      Highlights <Pink>_</Pink> <Italic>product names</Italic>
      </Intro>
    </Wrap>
 
  
</>


export default Highlights
