import React from "react"
import styled, { keyframes } from "styled-components"
import { useAuth0 } from '@auth0/auth0-react';
import Checkout from "../checkout";
import { Check } from '@styled-icons/bootstrap/Check'
import { Cross } from '@styled-icons/entypo/Cross'

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const CheckIcon = styled(Check)`

width: 25px;
color: rgb(14 98 69);


`

const CrossIcon = styled(Cross)`

width: 25px;
color: red;

`
const Faded = styled.span`

opacity: 0.5;

`


const Card = styled.div`
 
  background-color: rgb(247 249 249);
  //box-shadow: rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 6%) 0px 2px 12px, rgb(0 0 0 / 4%) 0px 8px 14px, rgb(0 0 0 / 2%) 0px 12px 16px;
  border-radius: 16px;
  padding: 32px;
  color: rgba(0,0,0,0.95);
  font-family: -apple-system, BlinkMacSystemFont, “Segoe UI”, Roboto, Helvetica, Arial, sans-serif;
  //display: flex;
  //flex-direction: row;
  //align-items: center;
  //justify-content: center;
  margin-bottom: 16px;
  
  
  h2 {
    font-weight: 900;
  font-size: 28px;
  line-height: 28px;
  }

  h3 {
    font-weight: 900;
  font-size: 23px;
  line-height: 28px;
  margin: 0;
  }

  p {
    font-weight: normal;
  font-size: 15px;
  line-height: 23px;
  margin: 8px 0 0 0;
  }

  ul {
    list-style-type: none;
    margin: 24px 0 24px 0;
    padding: 48px 32px 48px 32px;
    border-radius: 16px;
    //border: 2px solid;
    border-color: rgb(239 243 244);
    //background-color: rgba(255,255,255, 0.75);
    box-shadow: rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 6%) 0px 2px 12px, rgb(0 0 0 / 4%) 0px 8px 14px, rgb(0 0 0 / 2%) 0px 12px 16px;
    
  
    li {
      margin-bottom: 6px;
      font-size: 14px;
      
      
    }

  
  }

`

const Border = styled.span`
ul {
 
}
`

const Features = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin-top: 24px;


`

const Tag = styled.span`
font-size: 12px;
line-height: 20px;
background-color: rgb(203 244 201);
margin-left: 8px;
padding: 2px 5px 2px 5px;
border-radius: 4px;
`

const RecTag = styled.span`
font-size: 12px;
line-height: 20px;
background: -webkit-linear-gradient(to right, #FDB99B, #CF8BF3, #A770EF);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #FDB99B, #CF8BF3, #A770EF); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
margin-left: 8px;
padding: 2px 5px 2px 5px;
border-radius: 4px;
`

const Price = styled.div`

font-weight: 900;
  font-size: 28px;
  line-height: 28px;
  color: rgba(0,0,0, 0.25);
  text-align: left;
  margin: 24px 0 0 0;

`

const Heading = styled.div`

padding-left: 8px;
margin-bottom: 16px;
h3 {
font-weight: 900;
  font-size: 18px;
  line-height: 28px;
  color: rgba(0,0,0, 0.95);
  text-align: left;
}
span {
  color: rgba(0,0,0, 0.95);
}
p {
  margin: 0;
  padding: 0;
  font-size: 13px;
  line-height: 21px;
  color: rgba(0,0,0, 0.6);
}
`

const Yellow = styled.span`
color: rgb(251, 206, 55);
padding-right: 15px;
font-size: 38px;
`

const Button = styled.button`

  min-height: 44px;
  border: none;
  width: 100%;
  border-radius: 9999px;
  background-color: rgb(29 155 240);
  color: white;
  margin-top: 28px;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;

`


const Title = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;

p {

  margin: 0;
}
`
const fadeIn = keyframes`
  from {
    background-color: rgba(255,255,255,1);
  }

  to {
    background-color: rgb(203 244 201);
  }
`

const slideIn = keyframes`
  from {
    margin-left: -7px;
    opacity: 0;
  }

  to {
    margin-left: -4px;
    opacity: 1;
  }
`




const Highlight = styled.span`

background-color: rgb(203 244 201);
padding: 2px 5px 2px 5px;
color: rgba(0,0,0,0.8);
border-radius: 4px;

//margin-left: 2px;

`
const Popular = styled.div`



color: rgba(0,0,0,0.8);




`

const Strikethrough = styled.span`




text-decoration-line: line-through;
text-decoration-style: solid;
text-decoration-thickness: 1px;
-webkit-text-decoration-line: line-through;
-webkit-text-decoration-style: solid;
-webkit-text-decoration-thickness: 1px;

`

const Offer = () => {
  

  const { user } = useAuth0()
 
 

  return (
    
      <>
        <Card>
          <Title>
        <h3>Kick off your project</h3>
        
        </Title>

        <div>
        <p>Select your creative package &#8594;</p>
        <Features>
        <Border>
        <ul><Popular><RecTag><b>Recommended</b></RecTag></Popular>
        <Heading><h3>€99 <span>Essentials Package</span></h3><p>Get creative, professional names quickly</p></Heading>
          
        <li><CheckIcon />Custom name development
          </li>
          <li><CheckIcon />Agency process worth €1000s
          </li>
          <li><CheckIcon /><Highlight><b>Three</b></Highlight> unique company names
          </li>
          
          <li><CheckIcon />Low cost⁠—<Highlight>only <b>€33</b> per name</Highlight>
          </li>
          <li><CheckIcon />Supercharged—ready in three days
          </li>
          <li><CheckIcon />Based on your custom brief
          </li>
          <li><CheckIcon />Real human brand strategists
          </li>
          <li><CheckIcon />Great way to try Nameflow
          </li>
          <li><Faded><CrossIcon />Preliminary trademark & domain screening</Faded>
          </li>
          <li><Faded><CrossIcon />No feedback round</Faded>
          </li>
          <li><Faded><CrossIcon />Beautiful PDF presentation</Faded>
          </li>
          <li><Faded><CrossIcon />Custom brand color theme creation</Faded>
          </li>
          <li><Faded><CrossIcon />Priority customer support</Faded>
          </li>
          
          <Checkout lineitem="price_1KLGqDLDxKtMxkBNXyHSWktM" />
        </ul></Border>

        <ul><Popular><Tag><b>Popular</b></Tag></Popular>
        <Heading><h3>€249 <span>Founders Package</span></h3><p>Create the perfect name and build your business</p></Heading>
          
        <li><CheckIcon />Custom name development
          </li>
          <li><CheckIcon />Agency process worth €1000s
          </li>
          <li><CheckIcon /><Highlight><b>Five</b></Highlight> unique company names
          </li>
          <li><CheckIcon />First round ready in three days
          </li>
          <li><CheckIcon />Based on your custom brief
          </li>
          <li><CheckIcon />Real human brand strategists
          </li>
          <li><CheckIcon />Preliminary trademark & domain screening
          </li>
          <li><CheckIcon />One feedback round after three names
          </li>
          <li><CheckIcon />Beautiful PDF presentation
          </li>
          <li><CheckIcon />Custom brand color theme creation
          </li>
          <li><CheckIcon />Priority customer support
          </li>
          <li><Faded><CrossIcon />Brand tagline creation</Faded>
          </li>
          <li><Faded><CrossIcon />Custom wordmark & typography development</Faded>
          </li>
          <Checkout lineitem="price_1KIMlGLDxKtMxkBNwkQr0Utb" />
        </ul>

        <ul>
        <Heading><h3>€599 <span>MVB Program</span></h3><p>Launch your Minimum Viable Brand <span>✨</span></p></Heading>
          
        <li><CheckIcon />Custom name development
          </li>
          <li><CheckIcon />Agency process worth €1000s
          </li>
          <li><CheckIcon /><Highlight><b>Ten</b></Highlight> unique company names
          </li>

          <li><CheckIcon />First round ready in three days
          </li>
          <li><CheckIcon />Based on your custom brief
          </li>
          <li><CheckIcon />Real human brand strategists
          </li>
          <li><CheckIcon />Preliminary trademark & domain screening
          </li>
          <li><CheckIcon /><Highlight><b>Multiple</b></Highlight> feedback rounds
          </li>
          <li><CheckIcon />Beautiful PDF presentation
          </li>
          <li><CheckIcon />Custom brand color theme creation
          </li>
          <li><CheckIcon />Priority customer support
          </li>




          <li><CheckIcon /><Highlight><b>Brand tagline creation</b></Highlight>
          </li>
          <li><CheckIcon /><Highlight><b>Custom wordmark & typography development</b></Highlight>
          </li>
        
          <Checkout lineitem="price_1KLGq5LDxKtMxkBNfnyzqrdZ"/>
        </ul>
        
        </Features>
        </div>

        <div>
          
        
        
        </div>

        </Card>
      </>
    )
  
}

export default Offer
