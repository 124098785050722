import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import media from "../styles"
import { useAuth0 } from "@auth0/auth0-react";
import Board from "../../images/svg/approve.svg"
import Circle from "../../images/svg/promise_n.svg"
import { HomePageTextRotator } from "../homepagetextrotator"
import { Rotator } from "../homepagetextrotator copy"

const Nflow = styled(Circle)`

height: 23px;
width: auto;
fill: white;
`


const Image = styled.div`

width: 30%;
height: auto;

img {
  filter: opacity(95%) saturate(135%) blur(0px) drop-shadow(2px 2px 5px rgb(0 0 0 / 0.25)) contrast(110%) brightness(102%);
}

`

const ImageWrap = styled.div`

display: flex;
flex-direction: row;
justify-content: center:
align-items: center;
align-self: flex-end;
max-width: 400px;
width: 100%;
${media.large`
                margin-right: -100px;

                `}

                

`


const Whiteboard = styled(Board)`

width: 400px;
`
const Shift = styled.div`
margin: 0 auto 20px auto;
width: 150px;
`



const SignUpButton = () => {
  const { loginWithRedirect } = useAuth0();

  return <SignUp onClick={() => loginWithRedirect({screen_hint:"signup"})}>Get Started</SignUp>;
};


const SignUp = styled.button`
font-family: -apple-system, BlinkMacSystemFont, “Segoe UI”, Roboto, Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 17px;
background: none;
//background-color: rgb(29 155 240);
border: 1px solid white;
border-radius: 9999px;
padding: 10px 0 10px 0;
min-height: 44px;
width: 300px;
margin-top: 24px;
cursor: pointer;
//letter-spacing: -0.01em;


//color: #FF0063;
color: rgba(255,255,255,1);


`
const BackG = styled.div`
//background-color: #F0E2FC;
//background-color: #4E4E78;
background: #0f0c29;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #24243e, #302b63, #0f0c29);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #24243e, #302b63, #0f0c29); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
border-bottom: 2px solid;
  
width: 100%;
display: flex;
//border-bottom: 1px solid;
  //border-color: grey;
  border-bottom: 2px solid;
  border-color: rgb(239 243 244);
  color: white;
  padding: 40px 0 40px 0;
`

const Wrap = styled.div`
display: flex;
max-width: 1200px;
flex-direction: column;
align-items: center;
justify-content: center;
flex-wrap: wrap;
width: 100%;
padding: 0px 20px 100px 20px;
box-sizing: border-box;

//max-width: 1200px;
margin: auto;
//margin-bottom: 35px;
//background-image: linear-gradient(-20deg, #b721ff 0%, #21d4fd 100%);
//margin-bottom: 100px;
//background-color: #EBEBF8;
//background-color: white;
//border: 2px solid;
  //border-color: rgb(239 243 244);
  //background-color: rgb(224 242 237);
  //background-color: #CBF6FF;

  //ackground: #78ffd6; /* fallback for old browsers */
//background: -webkit-linear-gradient(to right, #78ffd6, #a8ff78);  /* Chrome 10-25, Safari 5.1-6 */
//background: linear-gradient(to right, #78ffd6, #a8ff78); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

border-radius: 0;
  
  line-height: 23px;
  //margin: 28px 0 0 0;
  text-align: center;

  ${media.xsmall`
                
  padding: 0px 40px 0px 40px;
                             
              
                              `}
  
                              h2 {
                                font-family: 'AkzidenzGrotesk_Light_Bold';
-webkit-font-smoothing: antialiased;
font-variant-ligatures: none;
letter-spacing: -2px;
margin-top: 0;
font-size: 52px;
line-height: 52px;
white-space: pre-wrap;
margin-bottom: 0;
font-weight: normal;
color: white;
//border-left: 10px solid rgb(230, 255, 0);
//text-align: left;

${media.phone`

text-align: center;




                `}


${media.tablet`
font-size: 48px;
line-height: 64px;

                `}

                ${media.large`

text-align: center;

                `}

  }

  {/*
  
  h2 {

    font-family: 'GoudyOldStyleRegular';
    
font-size: 22px;
letter-spacing: -1px;
//line-height: 30px;
white-space: pre-wrap;
//max-width: 300px;
//margin-bottom: 20px;
//color: rgb(38 38 39);
color: #121212;
text-align: center;
font-weight: normal;

${media.phone`

text-align: center;
max-width: none;




                `}

${media.tablet`
font-size: 24px;
line-height: 32px;
margin-bottom: 32px;
width: auto;
max-width: none;

                `}
                ${media.large`

text-align: center;
                `}

  }

  */}

  p {


line-height: 26px;
white-space: pre-wrap;
margin: 8px auto 0 auto;

${media.large`

text-align: center;

                `}


  }

  ul {
    
    
    margin: 20px 0 0 0;
    
  list-style: none;
  padding: none;
  -webkit-padding-start: 0;
 //margin: 0;
  text-align: center;
  font-size: 14px;
}

  ${media.tablet`
  
align-items: center;
justify-content: center;
padding: 0 20px 0px 20px;
flex-wrap: nowrap;
text-align: center;
//margin-bottom: 200px;


                `}

                ${media.large`
                //border-radius: 16px;
                flex-direction: row;
                justify-content: start;
                `}


`


const Italic = styled.span `
font-style: italic;

`

const Ratings = styled.div `
display: flex;
align-items: center;
justify-content: center;
flex-direction: row;
margin-top: 50px;

p {
  color: rgba(0,0,0,0.5)
}

div {
  margin: 0 25px 0 25px;
}

`

const Width = styled.div `
width: 100%;

`



const Tag = styled.span`

background-color: #FF8C78;
padding: 2px 5px 2px 5px;
color: rgba(0,0,0,0.8);
border-radius: 4px;
font-size: 13px;
font-weight: bold;
margin-left: 2px;


`

const Underline = styled.span`
text-decoration: underline 2px solid #e6ff00;
text-decoration-skip-ink: none;

`


const Grid = styled.div`

display: flex;
flex-direction: column;
justify-content: center;
align-items: start;
p{
  margin-bottom: 60px;
}

${media.desktop`
  flex-direction: row;
  padding-left: 100px;
  p{
  margin-bottom: 24px;
 
}



                `}

`

const Item = styled.div`

display: flex;
flex-direction: column;
justify-content: center;
align-items: start;
max-width: 300px;
border-left: 1px solid #f2f2f2;
padding-left: 24px;
position: relative;

h3 {
  
  margin: 0;
  padding: 0;
  font-size: 28px;
  font-family: 'AkzidenzGrotesk_Bold';
  letter-spacing: -1px;
  white-space: pre-wrap;
  text-align: left;
  line-height: 36px;
}

p{
  text-align: left;
  width: 75%;
  margin-top: 24px;
  font-size: 16px;
  line-height: 24px;
  
}

`



const Title = styled.div`

//margin-bottom: 80px;
width: 100%;

${media.large`
 width: 100%;
 padding-right: 60px;



                `}



{/*

h2{
  font-size: 16px;
  font-family: 'AkzidenzGrotesk_Regular';
  letter-spacing: -0.3px;
  white-space: pre-wrap;
 margin: 0;
 margin-bottom: 16px;
  line-height: 26px;
  color: rgba(0,0,0,0.5);
}

*/}

h3 {

  font-size: 48px;
  font-family: 'AkzidenzGrotesk_Bold';
  letter-spacing: -2px;
  margin: 0;
  margin-bottom: 48px;
  
 
  line-height: 56px;

}

p {

  
  letter-spacing: -0.25px;
  max-width: 650px;
  width: 100%;
 
  -webkit-font-smoothing: antialiased;
font-variant-ligatures: none;
color: #fff;
//margin-left: 48px;
}


`



const Highlight = styled.span`

background-color: #CBF6FF;
padding: 2px 5px 2px 5px;
color: rgba(0,0,0,0.8);
border-radius: 4px;


font-size: 12px;
line-height: 20px;

`

const Goudy = styled.span`

font-family: 'GoudyOldStyleItalic';
font-size: 82px;
`

const Normal = styled.span`

font-family: 'AkzidenzGrotesk_Medium';
color: rgba(0,0,0,0.6);

`
  
  
const Help = () => (
 
<StaticQuery
  query={graphql`
    query {
      placeholderImage: file(relativePath: { eq: "man.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            aspectRatio
            ...GatsbyImageSharpFluid
          }
          
        }
      }
    
      woman: file(relativePath: { eq: "woman3.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            aspectRatio
            ...GatsbyImageSharpFluid
          }
          
        }
      }

    


    }

    
  `}

  render={data => (
  
 

    <>

 
<BackG>
   <Wrap>
     <Title>
      
       
     
       <h2>How can we help you?</h2>
       <p>Get in touch with us below or email <b>growth@human-8.com</b></p>
      
           
       
           
       
       
     </Title>
     {/*<ImageWrap>
     
     
    <Image>
     <Img fluid={data.placeholderImage.childImageSharp.fluid} />
    </Image>
  </ImageWrap>*/}
     
     
    </Wrap>
 </BackG>
  
</>
 )}

 />

 )

export default Help
