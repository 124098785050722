import React from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { Link } from "gatsby"
import Logo from "../../images/brand/nameflow.svg"
import styled, { keyframes } from "styled-components"
import moment from 'moment'
import Moment from 'react-moment';
import 'moment-timezone';
import Check from "../../images/svg/checkmark.svg"
import media from "../styles"

const opacityFadeIn = keyframes`
 from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`



const CheckMark = styled(Check) `

height: 9px;
width: 9px;
animation: ${opacityFadeIn} 0.75s ease-in;

animation-iteration-count: 1;

`

const CheckWrap = styled.div`
display: flex;
justify-content: center;
align-items: center;
color: rgb(14 98 69);




margin: 1px 0.5px 0 0;
`

const Left = styled.div`
//grid-column-start: 1;
//grid-column-end: 2;
//grid-row-start: 1;
//grid-row-end: 2;
//align-self: start;
//justify-self: start;
//max-width: 490px;
border-bottom: 1px solid;
border-color: rgba(0,0,0, 5%);
${media.tablet`

`}
${media.desktop`
position: sticky;
  top: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  
  align-items: center;
  justify-content: space-between;
  padding: 65px 0 50px 0;

`}



position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  flex-direction: row-reverse;
  padding: 17px 20px 22px 20px;
  align-items: start;
  justify-content: space-between;
  background-color: rgb(247 249 249);
  
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, “Segoe UI”, Roboto, Helvetica, Arial, sans-serif;

  h2 {
    font-weight: 900;
  font-size: 28px;
  line-height: 28px;
  }

  h3 {
    font-weight: 900;
  font-size: 23px;
  line-height: 28px;
  margin: 0;
  }

  p {
    font-weight: normal;
  font-size: 14px;
  line-height: 23px;
  margin: 4px 0 4px 0;


  ${media.desktop`
  margin: 8px 0 0 0;

`}
  }


`

const Status = styled.div`
margin: auto;
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

div {
  width: 100%;
  text-align: left;

  ${media.desktop`
text-align: left;
width: 200px;

`}
}

`

const Foot = styled.div`
text-align: center;
display: none;

a {
text-decoration: none;
color: #000000f2;
}

${media.desktop`
display: inherit;
  

`}
  
`

const Top = styled.div`
text-align: center;
display: none;

${media.desktop`
display: inherit;
  

`}


span {
font-size: 11px;
margin: 0;
background-color: rgb(203 244 201);
padding: 2px 5px 2px 5px;
color: rgb(14 98 69);
border-radius: 4px;
font-weight: bold;
  }
`
const Bold = styled.span`
font-weight: bold;
`

const fadeIn = keyframes`
  from {
    background-color: rgba(255,255,255,1);
  }

  to {
    background-color: rgb(203 244 201);
  }
`



const slideIn = keyframes`
  from {
    margin-left: -7px;
    opacity: 0;
  }

  to {
    margin-left: -4px;
    opacity: 1;
  }
`

const BriefComplete = styled.div`

background-color: rgb(203 244 201);
color: rgb(14 98 69);
margin-left: 6px;
height: 24px;
width: 24px !important;
border-radius: 50%;
//margin-left: -4px;
animation: ${fadeIn} 0.5s ease-in;

animation-iteration-count: 1;
display: inline-flex;
align-items: center;
justify-content: center;


`

const BriefInComplete = styled.span`


padding: 2px 5px 2px 5px;
color: rgba(0,0,0,0.95);
border-radius: 4px;
margin-left: -4px;

`


const Mark = styled(Logo)`
width: 100px;
//margin: 30px 200px 10px 220px;
height: auto;
opacity: 0.9;
padding-top: 1px;
visibility: hidden;



${media.desktop`
width: 85px;
padding-top: 0;
opacity: 0.9;
visibility: inherit;

`}

`

const MyButton = styled.span`
cursor: pointer;

`

const LogoutButton = () => {
  const { logout } = useAuth0()

  return (
    <MyButton onClick={() => logout({ returnTo: process.env.GATSBY_AUTH0_LOGOUTURI })}>
      Log out
    </MyButton>
  )
}




const Menu = () => {
  const { user } = useAuth0()

    const Incomplete = 'Complete your brief \u2192'
    const Complete = 'Complete your brief'
    const Checkmark = '\u2714'

    const PaymentInComplete = 'Kick off your project \u2192'
    const PaymentComplete = 'Kick off your project'

    const NamesUnderway = 'Name creation in progress...'
    var momentDate = moment()
 

  return (
    
      <>
        <Left>

          <Link to="/nameflow/welcome"><Mark /></Link>
          <Top>
          
          <p>{user.email} <span>verified</span></p>
          
          </Top>

          <Status>
            <div>
          <h3>Next up</h3>
          <p>{user['https://my-app.example.com/brief'] == "completed" ? <><BriefInComplete>{Complete}</BriefInComplete><BriefComplete><CheckWrap><CheckMark /></CheckWrap></BriefComplete></> : <BriefInComplete>{Incomplete}</BriefInComplete>}</p>
          <p>{user['https://my-app.example.com/brief'] == "completed" && user['https://my-app.example.com/status'] == "paid" && <><BriefInComplete>{PaymentComplete}</BriefInComplete><BriefComplete><CheckWrap><CheckMark /></CheckWrap></BriefComplete></> }</p>
          <p>{user['https://my-app.example.com/brief'] == "completed" && user['https://my-app.example.com/status'] != "paid" && <BriefInComplete>{PaymentInComplete}</BriefInComplete> }</p>
          <p>{user['https://my-app.example.com/brief'] != "completed" && user['https://my-app.example.com/status'] == "paid" && <><BriefInComplete>{PaymentComplete}</BriefInComplete><BriefComplete><CheckWrap><CheckMark /></CheckWrap></BriefComplete></> }</p>
          <p>{user['https://my-app.example.com/brief'] == "completed" && user['https://my-app.example.com/status'] == "paid" && <BriefInComplete>{NamesUnderway}</BriefInComplete> }</p>
          </div>
          </Status>

          <Foot>
          
          <p><LogoutButton />{/*&nbsp; &#183; &nbsp;<Link to="/nameflow/help">Get help</Link>*/}</p>
          </Foot>

          
        </Left>
      </>
    
  )
}

export default Menu
