import React, { useState }  from "react"
import axios from "axios";
import { Link } from "gatsby"
import { Spinner8 } from '@styled-icons/icomoon/Spinner8'
import { CheckCircle } from '@styled-icons/bootstrap/CheckCircle'
import styled, { keyframes } from "styled-components"

const CenterButton = styled.div`

grid-column: 2;
`

const FileInput = styled.input`

pointer-events:none;
//opacity: 0;
&:hover {
    //background-color: rgba(230, 255, 0, 1);
    cursor: pointer;
  }

::file-selector-button {
  pointer-events: initial;
}

::file-selector-button:hover {
  pointer-events: initial;
  cursor: pointer;
}
  }

`



const Continue = styled.button`
  min-height: 44px;
  border: none;
  width: 100%;
  border-radius: 9999px;
  color: #121212;
  margin-top: 16px;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  background-color: rgb(230, 255, 0);

  &:disabled {
    background-color: rgba(230, 255, 0, 1);
    cursor: not-allowed;
  }
`

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Spinner = styled(Spinner8)`

height: 25px;
color: #121212;

animation: 1.25s linear ${spin} infinite;
`
const opacityFadeIn = keyframes`
 from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const Confirm = styled(CheckCircle)`

grid-column: 3;
height: 25px;
color: #121212;
animation: ${opacityFadeIn} 0.75s ease-in;
margin-left: auto;

animation-iteration-count: 1;

//margin-left: auto;
`

const Text = styled.div`

form {
text-align: left;
    width: 100%;
    margin: auto;
    
font-weight: bold;
font-size: 14px;

}

label {
text-align: left;
display: block;
    width: 100%;
    margin-bottom: 6px;
    margin-left: 6px;
    //margin: auto;
    pointer-events:none;
    
    
font-weight: normal;
font-size: 14px;

}


input {
  width: 100%;
  margin-bottom: 16px;
}



textarea {
    box-sizing: border-box;
    font-size: 16px;
    line-height: 28px;
    padding: 8px 16px;
    //width: 100%;
    //min-height: 44px;
    border: unset;
    border-radius: 4px;
    outline-color: rgb(84 105 212 / 0.5);
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, 
                rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                rgba(0, 0, 0, 0) 0px 0px 0px 0px;
                width: 100%;
  margin-bottom: 16px;
font-family: -apple-system, BlinkMacSystemFont, "“Segoe UI”", Roboto, Helvetica, Arial, sans-serif;
}





`

const Check = styled.div`


label {
  font-size: 11px;
  position: relative;
  vertical-align: middle;
  bottom: 1.25px;
  font-weight: normal;

}
`

const Form = styled.div`

`



const FullForm = () => {

  const [isDisabled, setIsDisabled] = useState(false);

  const [inputs, setInputs] = useState({consent: true});

  const [success, setSuccess] = useState({success: null});

  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);

  


  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleChange = event => {
    if (!isValidEmail(event.target.value)) {
      setError(true);
    } else {
      setError(null);
    }

    setEmail(event.target.value);
  };


  const onChange = (event) => {
  
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    const shouldSetValue = value.length < 30
    
    if (shouldSetValue) setInputs(values => ({...values, [name]: value}))
  }

  const onMessageChange = (event) => {
  
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    const shouldSetValue = value.length < 3000
    
    if (shouldSetValue) setInputs(values => ({...values, [name]: value}))
  }

  const onBoxChange = (event) => {
  
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    setInputs(values => ({...values, [name]: value}))
  }
    
    const [serverState, setServerState] = useState({
      submitting: false,
      status: null
    });
    const handleServerResponse = (ok, msg, form) => {
      setServerState({
        submitting: false,
        status: { ok, msg }
      });
      if (ok) {
        form.reset();
        setInputs({
          name: "",
          message: "",
        });
        setEmail(
          ''
        );
        setSuccess({
          success: true,
        });
      }
    };
    const handleOnSubmit = e => {
      e.preventDefault();
      const form = e.target;
      setServerState({ submitting: true });
      axios({
        method: "post",
        url: "https://getform.io/f/9d49ed87-572c-4085-bad2-a3819556fe25",
        data: new FormData(form)
      })
        .then(r => {
          handleServerResponse(true, "Thanks!", form);
        })

        /*.catch(function (error) {
          handleServerResponse(false, "Failed!", form);
          console.log(error);
        });*/

        .catch(r => {
          handleServerResponse(false, r.response.data.error, form);
          setSuccess({
            success: false,
          });
        });
        
    };

    const Confirmed = styled.div`

    display: grid;
    padding: 0 8px 0 8px;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;

    p{

     animation: ${opacityFadeIn} 0.25s ease-in;

animation-iteration-count: 1;
margin: 0;
 
    }


`
    const Received = 'Received'
  

    return (
        
    
    <Form>
         <div>
            
            <form onSubmit={handleOnSubmit}>
              <Text>
                <input type="text" name="name" placeholder="Your Name" value={inputs.name || ""} onChange={onChange} disabled={isDisabled} />
                <input id="email" name="email" placeholder="Your Work Email" value={email || ""} onChange={handleChange} disabled={isDisabled} />
                <input type="text" name="role" placeholder="Your Job/Role" disabled={isDisabled} />
                <textarea id="message" name="message" rows="4" cols="50" placeholder="Tell us what you're interested in" value={inputs.message || ""} onChange={onMessageChange}></textarea>
                <label for="file">Attach RFP or other file under 5MB</label><FileInput type="file" id="file" name="file"></FileInput>
              </Text>
              
               
              <Check>
              <input type="checkbox" name="consent" id="consent" checked={inputs.consent} onChange={onBoxChange} /> <label for="consent"> Get occasional emails from Human 8. Unsubscribe anytime. </label></Check>
              <Continue type="submit" disabled={serverState.submitting || !inputs.name || !inputs.message || !email || error === true}>{ serverState.submitting ? <Spinner /> : success.success === true ? <Confirmed><CenterButton>{Received}</CenterButton><Confirm /></Confirmed> : success.success === false ? "Failed" : "Send" }</Continue>
          </form>
        </div>
      </Form>  
    
 
     
    )

    
  
  }
  
  export default FullForm;