import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import media from "./styles"
import Star from "../images/svg/stars.svg"
import moment from 'moment'
import Moment from 'react-moment'
import Check from "../images/svg/checkmark.svg"
import MyForm from "./contact"



const Spacer = styled.div`

height: 100px;

`
const New = styled.div`
display: flex;
align-items: center;
justify-content: center;
padding: 20px;
border-radius: 40px;
background-color: #e6ff00;
line-height: 0;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 13px;
    box-sizing: border-box;
    margin-right: 14px;

`

const Banner = styled.div`

display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
margin-bottom: 24px;
font-size: 13px;

`

const Bold = styled.span`

font-weight: bold;

`



const Stars = styled(Star)`
height: 15px;
`


const Underline = styled.span`
text-decoration: underline 3px solid #ffcf00;
text-decoration-skip-ink: none;

`




const SignUp = styled.button`
font-family: -apple-system, BlinkMacSystemFont, “Segoe UI”, Roboto, Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 17px;
//background: #A6FF00;
background-color: rgb(29 155 240);
//background-color: #CBF6FF;
border: none;
border-radius: 9999px;
padding: 10px 0 10px 0;
min-height: 44px;
width: 200px;
margin-top: 24px;
cursor: pointer;
//letter-spacing: -0.01em;
//color: #FF0063;
color: rgba(255,255,255,1);
//color: rgba(0,0,0,0.9);

${media.xsmall`
width: 300px;

                `}


`
const BG = styled.div`
width: 100%;
background-color: #EBEBF8;
//border-bottom: 2px solid;
  border-color: rgb(239 243 244);


  h1,h2,h2,p {
    -webkit-font-smoothing: antialiased;
    color: rgb(17, 25, 12);
  }
`

const Wrap = styled.div`
display: flex;
flex-direction: row;
align-items: flex-start;
justify-content: center;
flex-wrap: wrap;
width: 100%;
padding: 20px 30px 20px 30px;
box-sizing: border-box;
color: rgba(0,0,0,0.95);
max-width: 1200px;
margin: auto;
height: auto;
//margin-bottom: 50px;
padding-bottom: 50px;
padding-top: 50px;


  line-height: 23px;
  //margin: 28px 0 0 0;
  text-align: center;

  
  h1 {
font-family: 'AkzidenzGrotesk_Light_Bold';
-webkit-font-smoothing: antialiased;
font-variant-ligatures: none;
letter-spacing: -2px;
margin-top: 0;
font-size: 52px;
line-height: 52px;
white-space: pre-wrap;
margin-bottom: 24px;
font-weight: normal;
color: #121212;
//text-align: left;

${media.phone`

text-align: center;




                `}


${media.tablet`
font-size: 64px;
line-height: 64px;

                `}

                ${media.large`

text-align: left;

                `}

  }

  h2 {

    font-family: 'AkzidenzGrotesk_Bold';
    
font-size: 22px;
letter-spacing: -1px;
//line-height: 30px;
white-space: pre-wrap;
//max-width: 300px;
//margin-bottom: 20px;
//color: rgb(38 38 39);
color: #121212;
text-align: center;


${media.phone`

text-align: center;
max-width: none;




                `}

${media.tablet`
font-size: 24px;
line-height: 32px;
margin-bottom: 32px;
width: auto;
max-width: none;

                `}
                ${media.large`

text-align: left;
                `}

  }

  p {


font-size: 18px;
line-height: 26px;
white-space: pre-wrap;
margin: 0 0 48px 0;

${media.large`

text-align: left;

                `}


  }

  ul {
    
    //font-family: AkzidenzGrotesk_Light;
    font-weight: normal;
    margin: 15px 0 0 0;
    
  list-style: none;
  padding: none;
  -webkit-padding-start: 0;
 //margin: 0;
 text-align: center;
  font-size: 14px;

  ${media.large`
  text-align: center;
  //padding-left: 5px;



                `}
  
}

li {
  

} 



  ${media.tablet`
  flex-direction: row;
align-items: stretch;
justify-content: center;
//height: 600px;
//margin-bottom: 50px;
padding-bottom: 200px;
padding-top: 150px;



                `}

                ${media.large`
 
justify-content: space-between;




                `}



`


const Italic = styled.span `
font-style: italic;

`

const Ratings = styled.div `
display: flex;
align-items: center;
justify-content: center;
flex-direction: row;
margin-top: 50px;
font-weight: normal;


                ${media.tablet`
                margin-top: 100px;
                `}
                ${media.large`
                margin-top: 150px;
                `}

//font-family: AkzidenzGrotesk_Light;

p {
  color: rgba(0,0,0,0.5);
}

div {
  margin: 0 25px 0 25px;
}

`

const Width = styled.div `
width: 100%;





${media.tablet`
  max-width: 630px;

                `}

                ${media.large`
                max-width: 500px;
  display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

                `}
`
const ImageWidth = styled.div`
max-width: 275px;
width: 100%;
margin-top: -50px;
margin-left: auto;
margin-right: auto;
//margin: auto;
padding-top: 48px;
//filter: drop-shadow(.35rem .35rem .35rem rgba(0,0,0,0.5));
margin-bottom: 16px;


                ${media.large`
               
padding-top: 0;
                `}

img {
  border-radius: 50%;
  border-color: rgba(230, 255, 0, 1);
  box-shadow: 0 0 8px rgba(230, 255, 0, 1);
  
}
`



const FormWidth = styled.div`
max-width: 375px;
height: 100%;
width: 100%;
margin: auto;
padding: 36px;
background-color: #EBEBF8;
//box-shadow: 0 5px 18px rgba(37,35,40,.08), 0 2px 10px rgba(37,35,40,.05);
display: flex;
justify-content: flex-end;
align-content: flex-end;
flex-direction: column;


border-radius: 4px;
   // box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px;



                ${media.large`
               

                `}

  form {
    text-align: left;
    width: 100%;
    margin: auto;
    
font-weight: bold;
font-size: 14px;


  }

  

  input {
    box-sizing: border-box;
    font-size: 16px;
    line-height: 28px;
    padding: 8px 16px;
    //width: 100%;
    //min-height: 44px;
    border: unset;
    border-radius: 4px;
    outline-color: rgb(84 105 212 / 0.5);
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, 
                rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                rgba(0, 0, 0, 0) 0px 0px 0px 0px;
}


  }

`
const HeadWidth = styled.div `
width: 100%;
max-width: 545px;

h2 {
  border-bottom: solid rgba(0,0,0,0.1) 1px;
  width: 100%;
  padding-bottom: 16px;
  margin-bottom: 24px;
}


${media.large`
  max-width: 545px;
  display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;


                `}
`

const RatingWidth = styled.div `
width: 100%;



`

const Highlight = styled.span`

background-color: #CBF6FF;
padding: 2px 5px 2px 5px;
color: rgba(0,0,0,0.8);
border-radius: 4px;
`

const Goudy = styled.span`

font-family: 'GoudyOldStyleItalic';
`

const AkzItalic = styled.span`

font-family: 'AkzidenzGrotesk_Italic';
font-weight: normal;
`


const List = styled.div `
list-style-type: none;
  margin: 0 !important;
  padding: 0;

`

const CheckMark = styled(Check) `
height: 9px;
width: 9px;
color: black;



`

const CheckWrap = styled.div`
display: flex;
justify-content: center;
align-items: center;
background-color: #e6ff00;
margin: 1px 8px 0 0;
border-radius: 50%;
height: 24px;
width: 24px !important;
box-sizing: border-box;
padding: 2px 0.5px 0 0;

`

const ListItem= styled.div`
display: flex;
justify-content: flex-start;
align-items: start;
text-align: left;
padding: 16px 0 0 0;

`

const ListText= styled.div`
text-align: left;
width: 100%;

`

  var momentDate = moment()
  
const Hero = () => (
 
<StaticQuery
  query={graphql`
    query {
      placeholderImage: file(relativePath: { eq: "hero_b.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            aspectRatio
            ...GatsbyImageSharpFluid
          }
          
        }
      }
    
      beseye: file(relativePath: { eq: "beseye.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            aspectRatio
            ...GatsbyImageSharpFluid
          }
          
        }
      }

      eo: file(relativePath: { eq: "eo_black_tall.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            aspectRatio
            ...GatsbyImageSharpFluid
          }
          
        }
      }


    }

    
  `}

  

  render={data => ( 

    <>

 
<BG>
   <Wrap>
     <HeadWidth>
      

      <Banner><New>New</New><Bold>Growth Accelerants &nbsp;&#62;</Bold> &nbsp;&nbsp;Research & Insights Platform</Banner>
     <h1><Goudy>Uncommon</Goudy> Knowledge</h1>
     <p>Unique human-driven insights to help shape your next move and thrive in uncertain times. <Italic>Knowledge is power.</Italic></p>
     <h2>See what's <AkzItalic>really</AkzItalic> happening</h2>
    
     <List>

      <ListItem>
        <CheckWrap><CheckMark /></CheckWrap>
        <ListText>
          Access the world's most powerful customer research platform.
          </ListText>
      </ListItem>

      <ListItem>
        <CheckWrap><CheckMark /></CheckWrap>
        <ListText>Data-driven trends and consumer insights with fresh analysis updated daily and expansive global coverage.
        </ListText>
      </ListItem>

      <ListItem>
        <CheckWrap><CheckMark /></CheckWrap>
        <ListText>
        Accelerate growth and enhance capabilities with true customer-centric intelligence.
          </ListText>
      </ListItem>
      

     </List>
     
      
      
       
        
        
        
        
        
       
        
        
     
     </HeadWidth>

     <Width>
       <FormWidth>
        
       <ImageWidth>
     <Img fluid={data.placeholderImage.childImageSharp.fluid} alt="A human holding up and viewing important information on a piece of paper" />
    
    </ImageWidth>

    <h3>Join the world's leading companies</h3>

        <MyForm />
        
  

       



    </FormWidth>
     
      
     
     
    
      </Width>
     
      



    </Wrap>
    
 </BG>
  
</>

)}

  />

  )


export default Hero
