import { createGlobalStyle } from "styled-components"
import * as fonts from "./fonts"

export const GlobalStyles = createGlobalStyle`

    body {

      @font-face {
          font-family: 'Interstate_Regular' ;
          src: url('${fonts.Interstate_Regular}');
      }
      @font-face {
          font-family: 'Interstate_Light' ;
          src: url('${fonts.Interstate_Light}');
      }
      @font-face {
          font-family: 'AkzidenzGrotesk_Light' ;
          src: url('${fonts.AkzidenzGrotesk_Light}');
      }

      @font-face {
          font-family: 'AkzidenzGrotesk_Light_Bold' ;
          src: url('${fonts.AkzidenzGrotesk_Light_Bold}');
      }
      @font-face {
          font-family: 'AkzidenzGrotesk_Regular' ;
          src: url('${fonts.AkzidenzGrotesk_Regular}')format('woff2'),
         url('${fonts.AkzidenzGrotesk_Regular_OTF}')format('otf');
         font-weight: normal;
      }
      @font-face {
          font-family: 'AkzidenzGrotesk_Medium' ;
          src: url('${fonts.AkzidenzGrotesk_Medium}');
      }
      @font-face {
          font-family: 'AkzidenzGrotesk_Bold' ;
          src: url('${fonts.AkzidenzGrotesk_Bold}');
      }
      @font-face {
          font-family: 'AkzidenzGrotesk_Italic' ;
          src: url('${fonts.AkzidenzGrotesk_Italic}');
      }

      @font-face {
          font-family: 'EuclidFlex_Light' ;
            src: url('${fonts.EuclidFlex_Light}');
      }

      @font-face {
          font-family: 'EuclidFlex_Regular' ;
            src: url('${fonts.EuclidFlex_Regular}');
      }

      @font-face {
          font-family: 'EuclidFlex_Medium' ;
            src: url('${fonts.EuclidFlex_Medium}');
      }

      @font-face {
          font-family: 'EuclidFlex_Bold' ;
            src: url('${fonts.EuclidFlex_Bold}');
      }

      @font-face {
          font-family: 'GoudyOldStyleRegular' ;
            src: url('${fonts.GoudyOldStyleRegular}');
      }

      @font-face {
          font-family: 'GoudyOldStyleBold' ;
            src: url('${fonts.GoudyOldStyleBold}');
      }

      @font-face {
          font-family: 'GoudyOldStyleItalic' ;
            src: url('${fonts.GoudyOldStyleItalic}');
      }

      @font-face {
          font-family: 'GoudyOldStyleItalicBold' ;
            src: url('${fonts.GoudyOldStyleItalicBold}');
      }
        
      //background: #eae8e4;
      /*background: #f8f6f1;
      margin: 0 !important;
      max-width: 100%;
      overflow: auto !important;*/
}


h2 {
    margin: 0;
}

`
