import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import media from "../styles"
import { useAuth0 } from "@auth0/auth0-react";
import Star from "../../images/svg/stars.svg"
import Board from "../../images/svg/approve.svg"
import moment from 'moment'
import Moment from 'react-moment';



const Stars = styled(Star)`

height: 15px;

`


const Whiteboard = styled(Board)`

width: 400px;
`
const Shift = styled.div`
margin: 0 auto 20px auto;
width: 150px;
`



const SignUpButton = () => {
  const { loginWithRedirect } = useAuth0();

  return <SignUp onClick={() => loginWithRedirect({screen_hint:"signup"})}>Try Nameflow today</SignUp>;
};


const SignUp = styled.button`
font-family: -apple-system, BlinkMacSystemFont, “Segoe UI”, Roboto, Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 17px;
//background: #A6FF00;
background-color: rgb(29 155 240);
border: none;
border-radius: 9999px;
padding: 10px 0 10px 0;
min-height: 44px;
width: 100%;
margin-top: 24px;
cursor: pointer;
//letter-spacing: -0.01em;
//color: #FF0063;
color: rgba(255,255,255,1);



${media.xsmall`
              
width: 300px;
             

              `}


`


const Wrap = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
flex-wrap: wrap;
width: 100%;
padding: 20px 20px 60px 20px;
box-sizing: border-box;
//color: rgba(255,255,255,1);
color: rgba(0,0,0,1);
max-width: 1200px;
margin: auto;
margin-bottom: 35px;


background-color: white;
//border: 2px solid;
  //border-color: rgb(239 243 244);
  // > background-color: rgb(238 91 122);
border-radius: 0;
  
  line-height: 23px;
  //margin: 28px 0 0 0;
  text-align: left;

  ${media.xsmall`
                
  padding: 20px 40px 60px 40px;
               

                `}
  
  h1 {
font-family: 'AkzidenzGrotesk_Light_Bold';

font-weight: normal ;
//-webkit-font-weight: bold ;
//font-synthesis: weight;
font-size: 64px;
line-height: 76px;
white-space: pre-wrap;
margin-bottom: 36px;
margin-top: 0px;


  }

  h2 {


    font-family: 'AkzidenzGrotesk_Light';

font-size: 42px;
line-height: 56px;
white-space: pre-wrap;
font-weight: normal;
margin: 0;
color: rgba(0,0,0,0.8);
margin-top: 24px;


  }

  p {


font-size: 18px;
line-height: 26px;
white-space: pre-wrap;
margin: 0 0 18px 0;

${media.desktop`
                //width: 500px;
                margin: auto;

                `}


  }

  ul {
    
    //font-family: AkzidenzGrotesk_Light;
    font-weight: normal;
    margin: 15px 0 0 10px;
    
  list-style: none;
  
  -webkit-padding-start: 0;
 //margin: 0;
  text-align: left;
  font-size: 14px;
}

  ${media.tablet`
  flex-direction: row;
align-items: center;
justify-content: center;
padding: 100px 100px 100px 100px;
flex-wrap: nowrap;
text-align: left;


                `}

                ${media.desktop`
                border-radius: 16px;
                margin-bottom: 100px;

                `}

div {
  

  width: 100%;

${media.xsmall`
              
width: 900px;
             

              `}
}

`


const Italic = styled.span `
font-style: italic;

`

const Ratings = styled.div `
display: flex;
align-items: center;
justify-content: center;
flex-direction: row;
margin-top: 50px;

p {
  color: rgba(0,0,0,0.5)
}

div {
  margin: 0 25px 0 25px;
}

`

const Width = styled.div `
width: 100%;


`



const Tag = styled.span`

background-color: #FF8C78;
padding: 2px 5px 2px 5px;
//color: rgba(0,0,0,0.8);
border-radius: 4px;
font-size: 28px;
font-weight: bold;
margin-left: 2px;


`

const Program = styled.span`


padding: 2px 5px 2px 5px;
//color: rgba(0,0,0,0.8);
border-radius: 4px;
font-size: 28px;
font-weight: bold;
margin-left: 2px;


`



const Underline = styled.span`
//text-decoration: underline 5px solid yellow;
text-decoration-skip-ink: none;

text-decoration-line: underline;
text-decoration-style: solid;
text-decoration-thickness:5px;
text-decoration-color: yellow;
-webkit-text-decoration-line: underline;
-webkit-text-decoration-style: solid;
-webkit-text-decoration-thickness: 5px;
-webkit-text-decoration-color: yellow;

`

const Highlight = styled.span`

background-color: #CBF6FF;
padding: 2px 5px 2px 5px;
color: rgba(0,0,0,0.8);
border-radius: 4px;

//margin-left: 2px;

`


const Strikethrough = styled.span`

//text-decoration: solid line-through 3px !important;
text-decoration-line: line-through;
text-decoration-style: solid;
text-decoration-thickness: 3px;
-webkit-text-decoration-line: line-through;
-webkit-text-decoration-style: solid;
-webkit-text-decoration-thickness: 3px;

`

const Color = styled.span`
font-family: 'AkzidenzGrotesk_Light_Bold';
color: #FF8C78;
font-variant-ligatures: none;

`
  
const Calender = () => {
 

  var momentDate = moment()
  return (

    <>

 

   <Wrap>
     
       
       <div>
         
       
     <h2>Get your first <Color>three</Color> names by</h2><h1><Underline><Moment add={{ days: 3 }} format ='dddd, MMMM Do YYYY'>{momentDate}</Moment>.</Underline></h1>
     {/*<p>Submit your brief today and you'll be christening your business with a glittering new name in no time. 🍾✨</p>*/}
     <SignUpButton />
     

     
    
     </div>
    
    





     
    </Wrap>
 
  
</>
  )
}
export default Calender
