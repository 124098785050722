import React, { useState, useEffect } from 'react';
import styled from "styled-components"
import { useAuth0 } from '@auth0/auth0-react';
import Promise from "../../images/svg/promise_n.svg"

const PromiseLogo = styled(Promise)`
width: 200px;
height: auto;
fill: white;
shape-rendering: geometricPrecision;
margin-bottom: -3px;
margin-bottom: 8px;


`


/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const Card = styled.div`
  
  position: relative;
  //background-color: #DCFF93;
  //background-color: rgb(247 249 249);
  //background: #E9FFF5; /* fallback for old browsers */
  //background: #cbf4c9;
  //background: -webkit-linear-gradient(to right, #78ffd6, #a8ff78);  /* Chrome 10-25, Safari 5.1-6 */
//background: linear-gradient(to right, #78ffd6, #a8ff78); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
background-image: linear-gradient(-20deg, #b721ff 0%, #21d4fd 100%);
 border: 1px solid;
  border-color: rgb(239 243 244);
  
  color: rgb(14 98 69);
  border-radius: 16px;
  padding: 32px 32px 32px 32px;
 
  font-family: -apple-system, BlinkMacSystemFont, “Segoe UI”, Roboto, Helvetica, Arial, sans-serif;
  
  
  
  margin-bottom: 16px;

  h2 {
    font-weight: 900;
  font-size: 23px;
  line-height: 23px;
  margin: 0;
  color: white;
  }

  p {
    font-weight: normal;
  
  line-height: 23px;
  color: rgba(255,255,255,0.85);
  margin: 0;
  font-weight: normal;
  
  
  
  }

  span{
    opacity: 0.95;
    font-size: 18px;
  
  font-weight: normal;
  }

  

`

const Italic = styled.span`

    font-style: italic;
    
`

const Underline = styled.span`
//border-bottom: 3px solid rgba(29, 155, 240);
    //text-decoration: underline solid rgb(29 155 240);
    //text-decoration-thickness: 2px;
    //text-decoration-skip-ink: none;
    font-style: italic;
    
`

const Title = styled.div`
display: flex;
flex-direction: column;

align-items: flex-start;
justify-content: center;


`



const DashPromise = () => {
  const { user } = useAuth0();
  
  
  //var newdate = new Date().toLocaleString()
  
  
  return (
    
      <>
        <Card>
          <Title>
        
        <PromiseLogo />
        <p>If you aren't satisfied with the names you receive, we'll do it again and create more names <b>for free. ✔</b></p>
          
</Title>
          


        </Card>
      </>
    )
  
}

export default DashPromise
