import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import media from "../styles"
import { useAuth0 } from "@auth0/auth0-react";
import Star from "../../images/svg/stars.svg"
import Board from "../../images/svg/approve.svg"
import { WindowNew } from '@styled-icons/fluentui-system-filled/WindowNew'
import Highlights from "../body/highlights"

const Open = styled(WindowNew)`
height: 12px;
margin-top:-10px;
color: #FF8C78;

`

const Set = styled.div`

width: auto;


${media.desktop`
width: 500px;
margin-right: 20px;

                `}
`




const Stars = styled(Star)`

height: 15px;

`


const Whiteboard = styled(Board)`

width: 275px;

${media.tablet`

width: 400px;


                `}

`
const Shift = styled.div`
margin: 0 auto 40px auto;


${media.desktop`

margin-right: 30px;
margin-bottom: 0;


                `}

`



const SignUpButton = () => {
  const { loginWithRedirect } = useAuth0();

  return <SignUp onClick={() => loginWithRedirect({screen_hint:"signup"})}>Create your unique brand</SignUp>;
};


const SignUp = styled.button`
font-family: -apple-system, BlinkMacSystemFont, “Segoe UI”, Roboto, Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 17px;
//background: #A6FF00;
background-color: rgb(29 155 240);
border: none;
border-radius: 9999px;
padding: 10px 0 10px 0;
min-height: 44px;
width: 100%;
margin-top: 24px;
cursor: pointer;
//letter-spacing: -0.01em;
//color: #FF0063;
color: rgba(255,255,255,1);

${media.xsmall`
                
width: 300px;
               

                `}


`


const Wrap = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
flex-wrap: wrap;
width: 100%;
padding: 20px 20px 60px 20px;
box-sizing: border-box;
color: rgba(0,0,0,0.95);
max-width: 1200px;
margin: auto;
margin-bottom: 35px;

a {
  text-decoration: none;
  color: rgba(0,0,0,0.95);
  text-decoration: underline 2px solid #FF8C78;
text-decoration-skip-ink: none;
}


background-color: white;
border: 2px solid;
  border-color: white;
//background-color: #fff0a9;
border-radius: 0;
  
  line-height: 23px;
  //margin: 28px 0 0 0;
  text-align: left;

  
  ${media.xsmall`
                
  padding: 20px 40px 60px 40px;
                             
              
                              `}


  h1 {
font-family: AkzidenzGrotesk_Regular;

font-size: 64px;
line-height: 76px;
white-space: pre-wrap;
margin-bottom: 18px;
margin-top: 5px;

  }

  h2 {


    font-family: 'AkzidenzGrotesk_Light_Bold';
    font-weight: normal;
    font-variant-ligatures: none;
    letter-spacing: -1px;
font-size: 64px;
line-height: 76px;
white-space: pre-wrap;

margin-bottom: 18px;
color: rgba(0,0,0,0.85);


  }

  p {


    font-size: 18px;
line-height: 26px;
white-space: pre-wrap;
margin: 0 0 18px 0;
color: rgba(0,0,0,0.75);


  }

  ul {
    
    
    margin: 20px 0 0 0;
    
  list-style: none;
  padding: none;
  -webkit-padding-start: 0;
 //margin: 0;
  text-align: center;
  font-size: 14px;
}

${media.tablet`
padding: 100px 100px 100px 100px;

                `}

  ${media.desktop`
  flex-direction: row;
align-items: center;
justify-content: center;

flex-wrap: nowrap;
margin-bottom: 35px;
border: 2px solid;
  border-color: rgb(239 243 244);
  border-radius: 16px;


                `}

                

div {

  
}

`


const Italic = styled.span `
font-style: italic;

`

const Ratings = styled.div `
display: flex;
align-items: center;
justify-content: center;
flex-direction: row;
margin-top: 50px;

p {
  color: rgba(0,0,0,0.5)
}

div {
  margin: 0 25px 0 25px;
}

`

const Width = styled.div `
width: 100%;

${media.desktop`
width: 500px;

              `}

`



const Tag = styled.span`
//background-color: #FF8C78;
background-color: rgb(224 242 237);
//background: #78ffd6; /* fallback for old browsers */
//background: -webkit-linear-gradient(to right, #78ffd6, #a8ff78);  /* Chrome 10-25, Safari 5.1-6 */
//background: linear-gradient(to right, #78ffd6, #a8ff78); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

padding: 2px 5px 2px 5px;
color: rgba(0,0,0,0.8);
border-radius: 4px;
font-size: 13px;
//font-family: AkzidenzGrotesk_Light;
font-weight: bold;
//text-transform: uppercase;
//letter-spacing: 2px;


${media.desktop`



                `}

`

const Highlight = styled.span`

background-color: rgb(224 242 237);
padding: 2px 5px 2px 5px;
color: rgba(0,0,0,0.8);
border-radius: 4px;

//margin-left: 2px;

`

  
  
const Creatives = () => 
 


    <>

 

   <Wrap>
     
       <Shift>
       <Whiteboard /></Shift>
       <Width>
       <Tag>How it works</Tag>
     <h2>Your own branding team.</h2>

     <p>The right company name should be a platform for a meaningful brand. It's the very first <a href="https://en.wikipedia.org/wiki/Moment_of_truth_(marketing)#:~:text=Moment%20of%20truth%20(MOT)%20in,particular%20brand%2C%20product%20or%20service." target="blank">moment of truth<Open /></a> for your business. A great brand name <Italic>just feels right</Italic>—and it's no accident.</p>
     {/*<p>The best brands speak with consistent, distinctive and <Highlight>honest</Highlight> voices. Everything works in concert, with unified messaging that positions a company as relevant, distinctive, credible and superior. And it all starts with a name.</p>*/}
     <p>After completing your online brief, a specialist creative team from brand innovation agency <a href="https://www.human-8.com" target="blank">Human 8<Open /></a> work on your project. We develop bespoke name ideas based around the authentic story, values and attributes of your business, that will help to set you apart as <Italic>irreplaceably different</Italic> in your customer's minds.</p>
     <SignUpButton />
     </Width>
    
    
     
    </Wrap>
 
  
</>


export default Creatives
