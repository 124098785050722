import React, { useState } from "react"
import { loadStripe } from "@stripe/stripe-js"
import styled from "styled-components"
import { useAuth0 } from '@auth0/auth0-react';

const Pay = styled.button`
  min-height: 44px;
  border: none;
  width: 200px;
  border-radius: 9999px;
  //background-color: rgb(29 155 240);
  background-color: ${props => props.color || "rgb(29 155 240)"};
  border: ${props => props.border || "none"};

  color: white;
  margin-top: 28px;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
`

const buttonStyles = {

  
}

const buttonDisabledStyles = {
  opacity: "0.25",
  cursor: "not-allowed",
}

let stripePromise
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.GATSBY_STRIPE_SECRET_KEY)
  }
  return stripePromise
}



const Checkout = (props) => {

    const { user } = useAuth0();
    const email = user.email
    const myStripeId = user.sub

  const [loading, setLoading] = useState(false)

  const redirectToCheckout = async event => {
    event.preventDefault()
    setLoading(true)

    const stripe = await getStripe()
    const { error } = await stripe.redirectToCheckout({
      mode: "payment",
      
      lineItems: [{ price: props.lineitem, quantity: 1 },
                  ],
      
      successUrl: process.env.GATSBY_STRIPE_SUCCESS_URL,
      cancelUrl: process.env.GATSBY_STRIPE_CANCEL_URL,
      customerEmail: email,
      clientReferenceId: myStripeId,

      
    })

    if (error) {
      console.warn("Error:", error)
      setLoading(false)
    }
  }

  return (
    <Pay color={props.color} border={props.border}
      disabled={loading}
      style={
        loading ? { ...buttonStyles, ...buttonDisabledStyles } : buttonStyles
      }
      onClick={redirectToCheckout}
    >
      {user['https://my-app.example.com/brief'] == "completed" ? "Kick Off ✨" : "Kick Off ✨"}
    </Pay>
  )
}

export default Checkout