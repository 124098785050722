import React from "react"
import styled, { keyframes } from "styled-components"
import { useAuth0 } from '@auth0/auth0-react';
import Form from "../form"
import media from "../styles"


/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const Card = styled.div`
 
  background-color: white;
  border-radius: 16px;
  border: 1px solid;
  border-color: rgb(239 243 244);
  padding: 32px 32px 32px 32px;
  color: rgba(0,0,0,0.95);
  font-family: -apple-system, BlinkMacSystemFont, “Segoe UI”, Roboto, Helvetica, Arial, sans-serif;
  //display: flex;
  //flex-direction: row;
  //align-items: center;
  //justify-content: center;
  margin-bottom: 16px;
 
  
  
  h2 {
    font-weight: 900;
  font-size: 28px;
  line-height: 28px;
  
  
  
  }

  h3 {
    font-weight: 900;
  font-size: 23px;
  line-height: 28px;
  margin: 0;
  }

  p {
    font-weight: normal;
  font-size: 15px;
  line-height: 23px;
  margin: 8px 0 0 0;
  margin-top: 8px;
  margin-bottom: 24px;
  }

`

const Yellow = styled.span`
color: rgb(251, 206, 55);
padding-right: 15px;
font-size: 38px;
`

const Title = styled.div`
display: flex;
flex-direction: column-reverse;
align-items: start;
justify-content: end;

${media.desktop`
flex-direction: column-reverse;
align-items: start;

`}

p {

  margin: 0 0 8px 0;
  
}
`
const fadeIn = keyframes`
  from {
    background-color: rgba(255,255,255,1);
  }

  to {
    background-color: rgb(203 244 201);
  }
`

const slideIn = keyframes`
  from {
    margin-left: -7px;
    opacity: 0;
  }

  to {
    margin-left: -4px;
    opacity: 1;
  }
`

const BriefComplete = styled.span`

background: #cbf4c9; /* fallback for old browsers */
//background: #78ffd6; /* fallback for old browsers */
//background: -webkit-linear-gradient(to right, #78ffd6, #a8ff78);  /* Chrome 10-25, Safari 5.1-6 */
//background: linear-gradient(to right, #78ffd6, #a8ff78); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
padding: 2px 5px 2px 5px;
color: rgb(14 98 69);
border-radius: 4px;
//margin-left: -4px;
//margin-top: -32px;
animation: ${fadeIn} 0.5s ease-in;

animation-iteration-count: 1;
font-size: 12px;
font-weight: bold;




`

const BriefInComplete = styled.span`


padding: 2px 5px 2px 5px;
color: rgb(14 98 69);
border-radius: 4px;
margin-left: -4px;
font-size: 12px;

`



const ProjectBrief = () => {
  
  const { user } = useAuth0()
 
  const Incomplete = 'complete your brief \u2192'
  const Complete = 'Completed'

  return (
    
      <>
        <Card>
          <Title>
        <h2>Your Brief</h2>
        <p>{user['https://my-app.example.com/brief'] == "completed" && <BriefComplete>{Complete}</BriefComplete> }</p>
        </Title>

        <div>
        <p>Ready to get going? Hit the Start button anytime to begin creating your brief. You've got this!</p>
        </div>


        <div>
        <Form />
        </div>

        </Card>
      </>
    )
  
}

export default ProjectBrief
