import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import media from "../styles"
import { useAuth0 } from "@auth0/auth0-react";
import Star from "../../images/svg/stars.svg"
import Board from "../../images/svg/approve.svg"
import moment from 'moment'
import Moment from 'react-moment';
import Circle from "../../images/nflowfav.svg"

const Nflow = styled(Circle)`

height: 60px;
width: auto;
display: none;

${media.xsmall`
display: initial;      
transform: translate(280px,-40px);
                               
                
                                `}

                                `



const Stars = styled(Star)`

height: 15px;

`


const Whiteboard = styled(Board)`

width: 400px;
`
const Shift = styled.div`
margin: 0 auto 20px auto;
width: 150px;
`



const SignUpButton = () => {
  const { loginWithRedirect } = useAuth0();

  return <SignUp onClick={() => loginWithRedirect({screen_hint:"signup"})}>Try Nameflow today</SignUp>;
};


const SignUp = styled.button`
font-family: -apple-system, BlinkMacSystemFont, “Segoe UI”, Roboto, Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 17px;
//background: #A6FF00;
background-color: rgb(29 155 240);
border: none;
border-radius: 9999px;
padding: 10px 0 10px 0;
min-height: 44px;
width: 300px;
margin-top: 24px;
cursor: pointer;
//letter-spacing: -0.01em;


//color: #FF0063;
color: rgba(255,255,255,1);


`


const Wrap = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
flex-wrap: wrap;
width: 100%;
padding: 50px 20px 100px 20px;
box-sizing: border-box;
color: rgba(255,255,255,1);
max-width: 1200px;
margin: auto;
//margin-bottom: 70px;
//margin-top: 70px;


background-color: white;
//border: 2px solid;
  //border-color: rgb(239 243 244);
  // > background-color: #95CED7;
  //background-color: #CBF6FF;
border-radius: 0;
  
  line-height: 23px;
  //margin: 28px 0 0 0;
  text-align: left;

  ${media.xsmall`
                
  padding: 50px 40px 100px 40px;
                             
              
                              `}


  h1 {

font-size: 64px;
line-height: 76px;
white-space: pre-wrap;
margin-bottom: 18px;
margin-top: 5px;
color: rgba(0,0,0,0.9);
font-weight: normal;

  }

  h2 {

    font-family: AkzidenzGrotesk_Light;

font-size: 24px;
line-height: 36px;
white-space: pre-wrap;
font-weight: normal;
margin: 0;
color: rgba(0,0,0,0.8);


  }

  h3 {

    font-family: 'AkzidenzGrotesk_Light_Bold';
    font-variant-ligatures: none;
    letter-spacing: -1px;

font-size: 54px;
line-height: 68px;
white-space: pre-wrap;
font-weight: normal;
margin: 0;
color: rgba(0,0,0,0.8);



}

  p {


font-size: 18px;
line-height: 26px;
white-space: pre-wrap;
margin: 0 0 18px 0;


  }

  ul {
    
    
    margin: 20px 0 0 0;
    
  list-style: none;
  padding: none;
  -webkit-padding-start: 0;
 //margin: 0;
  text-align: center;
  font-size: 14px;
}

  ${media.tablet`
  flex-direction: row;
align-items: center;
justify-content: center;
padding: 0;
flex-wrap: nowrap;
text-align: left;
padding: 100px 100px 100px 100px;
//margin-bottom: 100px;


                `}

                ${media.desktop`
                //border-radius: 16px;

                `}

div {

}

`


const Italic = styled.span `
font-style: italic;

`

const Ratings = styled.div `
display: flex;
align-items: center;
justify-content: center;
flex-direction: row;
margin-top: 50px;

p {
  color: rgba(0,0,0,0.5)
}

div {
  margin: 0 25px 0 25px;
}

`

const Width = styled.div `
width: 100%;

`



const Tag = styled.span`

background-color: #FF8C78;
padding: 2px 5px 2px 5px;
color: rgba(0,0,0,0.8);
border-radius: 4px;
font-size: 13px;
font-weight: bold;
margin-left: 2px;


`

const Underline = styled.span`
text-decoration: underline 5px solid yellow;
text-decoration-skip-ink: none;

`

const Flex = styled.div`


  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

`

const Image = styled.div`
width: 205px;
height: 300px;

padding: 20px 20px 0 20px;
border-radius: 16px;
background-color: #C7B9FF;
display: flex;
flex-direction: row;
justify-content: center;
align-items: flex-end !important;
overflow: visible;
${media.xsmall`
                
width: 275px;
height: 400px;

                `}

div {

  width: 220px;

  ${media.xsmall`
                
width: 270px;
               

                `}
 

}

`

const Content = styled.div`

padding: 25px 0 25px 0;
//max-width: 300px;

${media.xsmall`
                
padding: 25px;
                             
              
                              `}

${media.desktop`
                margin-right: 100px;

                `}

`

const ImgWrap = styled.div`



`
  
const Mkts = () => (
 

  
  <StaticQuery
  query={graphql`
    query {
      placeholderImage: file(relativePath: { eq: "man.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            aspectRatio
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `}


  render={data => (

    <>

 

   <Wrap>
     
       
       <Flex><ImgWrap>
         <Image><div>
       <Img fluid={data.placeholderImage.childImageSharp.fluid} /></div>
       </Image><Nflow /></ImgWrap>
       <Content>
     <h2>Marketers</h2><h3>feel more confident with Nameflow</h3>
     </Content>
     </Flex>
    
    
     
    </Wrap>
 
  
</>
  

)}
 />
)

export default Mkts
