import React, { useState, useEffect } from 'react';
import styled from "styled-components"
import { useAuth0 } from '@auth0/auth0-react';
import HeroBG from "../../images/woman3bg.png"
import WelcomeBG from "../../images/manbg.png"
import { useStaticQuery, graphql } from 'gatsby'
import moment from 'moment'
import Moment from 'react-moment';
import 'moment-timezone';
import media from "../styles"


/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const Card = styled.div`
  
  position: relative;
  //background-color: #DCFF93;
  //background-color: rgb(247 249 249);
  background-color: white;
  border: 1px solid;
  border-color: rgb(239 243 244);
  border-radius: 16px;
  padding: 32px;
  color: rgba(0,0,0,0.95);
  font-family: -apple-system, BlinkMacSystemFont, “Segoe UI”, Roboto, Helvetica, Arial, sans-serif;
  height: 280px;
  overflow: hidden;
  margin-bottom: 16px;

  ${media.desktop`
  height: 240px;
`}

  h2 {
    font-weight: 900;
  font-size: 28px;
  line-height: 28px;
  }

  p {
    font-weight: normal;
  font-size: 15px;
  line-height: 23px;
  margin: 8px 0 0px 0;
  
  }

  &:before {

    


    background-image: url(${HeroBG});
    content: "";
    background-size: 185px;
    background-repeat: no-repeat;
    position: absolute;
      top: 190px;
      right: 0px;
      bottom: 0px;
      left: -30px;
      opacity: 0.55;

      ${media.xsmall`
      background-size: 205px;
      top: 170px;
      left: -12px;
      opacity: 0.55;
`}

      ${media.desktop`
      left: 13px;
      top: 130px;
`}


  }

  &:after {

   
background-image: url(${WelcomeBG});
content: "";
background-size: 280px;
background-repeat: no-repeat;
position: absolute;
  top: 186px;
  right: 0px;
  bottom: 0px;
  left: 74px;
  opacity: 0.5;

  ${media.xsmall`
  background-size: 300px;
  top: 166px;
  left: 132px;
  opacity: 0.5;
`}

  ${media.desktop`
  left: 162px;
  top: 126px;
`}

}

`

const Italic = styled.span`

    font-style: italic;
    
`

const Underline = styled.span`
//border-bottom: 3px solid rgba(29, 155, 240);
    //text-decoration: underline solid rgb(29 155 240);
    //text-decoration-thickness: 2px;
    //text-decoration-skip-ink: none;
    font-style: italic;
    
`

const Title = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;

p {
  font-size: 12px;
  margin: 0;
 
}
`



const DashWelcome = () => {
  const { user } = useAuth0();
  
  
  //var newdate = new Date().toLocaleString()
  
  var momentDate = moment()




  
  return (
    
      <>
        <Card>
          <Title>
        <h2>Welcome, {user.given_name}.</h2>
        {/*<p><Moment format ='dddd, MMMM Do YYYY'>{momentDate}</Moment></p>*/}
          </Title>

          <div>
        <p>{user['https://my-app.example.com/brief'] == "completed" && user['https://my-app.example.com/status'] == "paid" ? "Your company names are now being formulated by the team. Good times ✨" : 
        
        user['https://my-app.example.com/brief'] == "completed" ? "Looking good, your project is ready for kick off." : "Your creative strategists are raring to go. Create your brief to get started."
        
        } </p>
          </div>


        </Card>
      </>
    )
  
}

export default DashWelcome
