import React, { useState, useEffect } from 'react';
import styled from "styled-components"
import { useAuth0 } from '@auth0/auth0-react';
import HeroBG from "../../images/woman3.png"
import WelcomeBG from "../../images/man.png"
import moment from 'moment'
import Moment from 'react-moment';
import 'moment-timezone';


/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const Card = styled.div`
  
  position: relative;
  //background-color: #DCFF93;
  //background-color: rgb(247 249 249);
  background-color: white;
 border: 1px solid;
  border-color: rgb(239 243 244);
  border-radius: 16px;
  padding: 16px 32px 16px 32px;
  color: rgba(0,0,0,0.95);
  font-family: -apple-system, BlinkMacSystemFont, “Segoe UI”, Roboto, Helvetica, Arial, sans-serif;
  
  
  
  margin-bottom: 16px;

  h2 {
    font-weight: 900;
  font-size: 28px;
  line-height: 28px;
  }

  p {
    font-weight: normal;
  font-size: 15px;
  line-height: 23px;
  margin: 8px 0 160px 0;
  
  }

  &:before {

    background-image: url(${HeroBG});
    content: "";
    background-size: 25%;
    background-repeat: no-repeat;
    position: absolute;
      top: 120px;
      right: 0px;
      bottom: 0px;
      left: 20px;
      opacity: 0.28;


  }

  &:after {

background-image: url(${WelcomeBG});
content: "";
background-size: 46%;
background-repeat: no-repeat;
position: absolute;
  top: 116px;
  right: 0px;
  bottom: 0px;
  left: 190px;
  opacity: 0.25;


}

`

const Italic = styled.span`

    font-style: italic;
    
`

const Underline = styled.span`
//border-bottom: 3px solid rgba(29, 155, 240);
    //text-decoration: underline solid rgb(29 155 240);
    //text-decoration-thickness: 2px;
    //text-decoration-skip-ink: none;
    font-style: italic;
    
`

const Title = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;

p {
  font-size: 15px;
  margin: 0;
 
}
`



const Time = () => {
  const { user } = useAuth0();
  
  
  //var newdate = new Date().toLocaleString()
  
  var momentDate = moment()
  return (
    
      <>
        <Card>
          <Title>
        
        <p><Moment format ='dddd, MMMM Do YYYY'>{momentDate}</Moment></p>
          </Title>

          


        </Card>
      </>
    )
  
}

export default Time
