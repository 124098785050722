import { Redirect } from "@reach/router"
import React from "react"


import Redirecting from "../../components/dashboard/redirecting"

const RedirectTest = () => (
  <Redirecting />
)

export default RedirectTest
