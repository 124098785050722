
import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Img from "gatsby-image"

export default ({data}) => {
  return (
    <Layout>
      <div>{data.contentfulContentPost.title}</div>
      <div><Img fluid={data.contentfulContentPost.featuredImage.fluid} /></div>
      <div dangerouslySetInnerHTML={{__html:data.contentfulContentPost.content.childMarkdownRemark.html}} />
    </Layout>
  )
  }


  export const pageQuery = graphql`
  query ($slug: String!){
      contentfulContentPost(slug: {eq: $slug}) {
          title
          createdAt(formatString: "MMMM DD, YYYY")
          featuredImage
          {
            file {url}
            fluid(maxWidth: 500){
              ...GatsbyContentfulFluid_withWebp
            }
           
          
          }
          content {
              childMarkdownRemark {
                  html
              }
          }
      }
  }
`

