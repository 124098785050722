import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import media from "../styles"
import { useAuth0 } from "@auth0/auth0-react";
import Board from "../../images/svg/approve.svg"

import Circle from "../../images/nflowfav.svg"

const Nflow = styled(Circle)`

height: 40px;
margin: 0 auto 48px auto;
width: auto;
display: block;

${media.tablet`
                

                `}
`

const Image = styled.div`

width: 250px;
height: auto;

`

const ImageWrap = styled.div`

display: flex;
flex-direction: row;
justify-content: center:
align-items: center;

`


const Whiteboard = styled(Board)`

width: 400px;
`
const Shift = styled.div`
margin: 0 auto 20px auto;
width: 150px;
`



const SignUpButton = () => {
  const { loginWithRedirect } = useAuth0();

  return <SignUp onClick={() => loginWithRedirect({screen_hint:"signup"})}>Try Nameflow today</SignUp>;
};


const SignUp = styled.button`
font-family: -apple-system, BlinkMacSystemFont, “Segoe UI”, Roboto, Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 17px;
//background: #A6FF00;
background-color: rgb(29 155 240);
border: none;
border-radius: 9999px;
padding: 10px 0 10px 0;
min-height: 44px;
width: 300px;
margin-top: 24px;
cursor: pointer;
//letter-spacing: -0.01em;


//color: #FF0063;
color: rgba(255,255,255,1);


`


const Wrap = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
flex-wrap: wrap;
width: 100%;
padding: 100px 20px 100px 20px;
box-sizing: border-box;
color: black;
//max-width: 1200px;
margin: auto;
//margin-bottom: 35px;
background-color: white;
//margin-bottom: 100px;

//background-color: white;
//border: 2px solid;
  //border-color: rgb(239 243 244);
  //background-color: rgb(224 242 237);
  //background-color: #CBF6FF;

  //ackground: #78ffd6; /* fallback for old browsers */
//background: -webkit-linear-gradient(to right, #78ffd6, #a8ff78);  /* Chrome 10-25, Safari 5.1-6 */
//background: linear-gradient(to right, #78ffd6, #a8ff78); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

border-radius: 0;
  
  line-height: 23px;
  //margin: 28px 0 0 0;
  text-align: left;

  ${media.xsmall`
                
  padding: 0px 40px 0px 40px;
                             
              
                              `}
  
  h1 {

    font-family: 'AkzidenzGrotesk_Light_Bold';
    font-variant-ligatures: none;
    letter-spacing: -1px;

font-size: 42px;
line-height: 56px;
white-space: pre-wrap;
font-weight: normal;
margin-bottom: 0;
color: rgba(0,0,0,0.8);

  }

  h2 {

    font-family: 'AkzidenzGrotesk_Light';

font-size: 42px;
line-height: 56px;
white-space: pre-wrap;
font-weight: normal;
margin-bottom: 18px;
color: rgba(0,0,0,0.65);


  }

  p {


font-size: 18px;
line-height: 26px;
white-space: pre-wrap;
margin: 0 0 18px 0;


  }

  ul {
    
    
    margin: 20px 0 0 0;
    
  list-style: none;
  padding: none;
  -webkit-padding-start: 0;
 //margin: 0;
  text-align: center;
  font-size: 14px;
}

  ${media.tablet`
  flex-direction: column;
align-items: center;
justify-content: center;
padding: 100px 100px 0px 100px;
flex-wrap: nowrap;
text-align: center;
//margin-bottom: 200px;


                `}

                ${media.desktop`
                //border-radius: 16px;

                `}


`


const Italic = styled.span `
font-style: italic;

`

const Ratings = styled.div `
display: flex;
align-items: center;
justify-content: center;
flex-direction: row;
margin-top: 50px;

p {
  color: rgba(0,0,0,0.5)
}

div {
  margin: 0 25px 0 25px;
}

`

const Width = styled.div `
width: 100%;

`



const Tag = styled.span`

background-color: #FF8C78;
padding: 2px 5px 2px 5px;
color: rgba(0,0,0,0.8);
border-radius: 4px;
font-size: 13px;
font-weight: bold;
margin-left: 2px;


`

const Underline = styled.span`
text-decoration: underline 4px solid #006bff;
//text-decoration-skip-ink: none;
`




const Item = styled.div`

display: flex;
flex-direction: column;
justify-content: center;
align-items: start;
max-width: 300px;
border-left: 1px solid #f2f2f2;
padding-left: 24px;
position: relative;

h3 {
  
  margin: 0;
  padding: 0;
  font-size: 28px;
  font-family: 'AkzidenzGrotesk_Bold';
  letter-spacing: -1px;
  white-space: pre-wrap;
  text-align: left;
  line-height: 36px;
}

p{
  text-align: left;
  width: 75%;
  margin-top: 24px;
  font-size: 16px;
  line-height: 24px;
  
}

`



const Title = styled.div`

//margin-bottom: 80px;
width: 100%;




h2{
  font-size: 18px;
  font-family: 'AkzidenzGrotesk_Bold';
  letter-spacing: -1px;
  white-space: pre-wrap;
 margin: 0;
 margin-bottom: 16px;
  line-height: 26px;
}

h3 {

  font-size: 48px;
  font-family: 'AkzidenzGrotesk_Bold';
  letter-spacing: -2px;
  margin: 0;
  margin-bottom: 48px;
  
 
  line-height: 56px;

}

p {

  font-size: 24px;
  line-height: 32px;
  max-width: 750px;
  width: 100%;
  margin: auto;
  
}


`



const Highlight = styled.span`

background-color: #CBF6FF;
padding: 2px 5px 2px 5px;
color: rgba(0,0,0,0.8);
border-radius: 4px;


font-size: 12px;
line-height: 20px;

`
  
  
const Simplified = () => (
 
<StaticQuery
  query={graphql`
    query {
      placeholderImage: file(relativePath: { eq: "man.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            aspectRatio
            ...GatsbyImageSharpFluid
          }
          
        }
      }
    
      woman: file(relativePath: { eq: "woman3.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            aspectRatio
            ...GatsbyImageSharpFluid
          }
          
        }
      }

    


    }

    
  `}

  render={data => (
  
 

    <>

 

   <Wrap>
     <Title>
       
       <h3>Do more of what you do best</h3>
       <p>With Nameflow you can spend less time worrying about the name, and more time making your business awesome. We've got you covered.</p>
     </Title>
    
     
    </Wrap>
 
  
</>
 )}

 />

 )

export default Simplified
