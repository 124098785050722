import React from "react"
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react"
import { Link } from "gatsby"
import Logo from "../../images/brand/nameflow.svg"

import styled from "styled-components"


const Bottom = styled.div`
grid-column-start: 2;
grid-column-end: 3;
height: 200px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;



  font-family: -apple-system, BlinkMacSystemFont, “Segoe UI”, Roboto, Helvetica, Arial, sans-serif;

  h2 {
    font-weight: 900;
  font-size: 28px;
  line-height: 28px;
  }

  h3 {
    font-weight: 900;
  font-size: 23px;
  line-height: 28px;
  margin: 0;
  }

  p {
    font-weight: normal;
  font-size: 14px;
  line-height: 23px;
  margin: 8px 0 0 0;
  }


`



const Mark = styled(Logo)`
width: 85px;
//margin: 30px 200px 10px 220px;
height: auto;
opacity: 0.9;

`

const Footer = () => {
  

 

  return (
    
      <>
        <Bottom>

          <Mark />
          <div>
          <p>Start something special</p>
          </div>

          

          
        </Bottom>
      </>
    
  )
}

export default Footer
