import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import media from "../styles"
import { useAuth0 } from "@auth0/auth0-react";
import Board from "../../images/svg/approve.svg"
import Circle from "../../images/svg/promise_n.svg"
import { HomePageTextRotator } from "../homepagetextrotator"
import { Rotator } from "../homepagetextrotator copy"


const Tag = styled.span`

background-color: rgb(230, 255, 0);
padding: 2px 5px 2px 5px;
color: rgba(0,0,0,0.8);
border-radius: 4px;
font-size: 13px;
font-weight: bold;
margin-left: 2px;

`

const Highlight = styled.span`

background-color: #CBF6FF;
padding: 2px 5px 2px 5px;
color: rgba(0,0,0,0.8);
border-radius: 4px;


font-size: 12px;
line-height: 20px;

`

const New = styled.div`
display: flex;
align-items: center;
justify-content: center;
padding: 20px;
border-radius: 40px;
background-color: #e6ff00;
line-height: 0;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 13px;
    box-sizing: border-box;
    margin-right: 14px;

`

const BackG = styled.div`
//background-color: #F0E2FC;
background-color: #EBEBF8;
width: 100%;
display: flex;
//border-bottom: 1px solid;
  //border-color: grey;
  //border-bottom: 2px solid;
 // border-color: rgb(239 243 244);
  color: white;
  padding-bottom: 160px;
  padding-top: 60px;
`

const Wrap = styled.div`
display: grid;
//grid-template-columns: 1fr;
grid-template-columns: repeat(auto-fit, minmax(370px, 370px));
width: 100%;
gap: 40px;

margin: auto;
padding: 0 40px 0 40px;
justify-content: center;
box-sizing: border-box;


${media.phone`

//grid-template-columns: 1fr 1fr;




                `}

${media.large`
//grid-template-columns: 1fr 1fr 1fr;
max-width: 1300px;
padding: 0 20px 0 20px;
                `}


`

const Item = styled.div`
//border: 10px solid rgb(230, 255, 0);
border: 1px solid black;
//border-radius: 40px;
padding: 20px 20px 20px 20px;
display: flex;
flex-direction: row;
align-items: start;
justify-content: center;
color: black;
height: 270px;

`


const Content = styled.div`

display: flex;
flex-direction: column;
justify-content: space-between;
align-items: start;
height: 100%;
width: 100%;
 p {margin: 0;
 padding: 0;}
`
  
  
const ProductsGrid = () => (
 
<StaticQuery
  query={graphql`
    query {
      birds: file(relativePath: { eq: "birds.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            
            ...GatsbyImageSharpFluid
          }
          
        }
      }
    
      woman: file(relativePath: { eq: "woman3.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            aspectRatio
            ...GatsbyImageSharpFluid
          }
          
        }
      }

    


    }

    
  `}

  render={data => (
  
 

    <>

 
<BackG>
   <Wrap>

   
     <Item>
      <Content>
     <h2>Research & Insight</h2>
     <p>Bespoke consumer research with access to the world's deepest consumer insight platform through our global partners.</p>
  </Content>
     </Item>

     <Item>
      <Content>
     <h2>Ethos & Purpose</h2>
     <p>Customers and stakeholders care about what you stand for. Find, articulate and activate your organization's true purpose.</p>
  </Content>
     </Item>

     <Item>
      <Content>
     <h2>Artificial Intelligence</h2>
     <p>Leverage AI safely and create new value with people, customers and upcoming regulatory frameworks in mind.</p>
  </Content>
     </Item>

     <Item>
      <Content>
     <h2>Innovation</h2>
     <p>Work from first principles and sprint through design and testing to quickly iterate new human-friendly products, services and business models.</p>
  </Content>
     </Item>

     <Item>
      <Content>
     <h2>Organizational Performance</h2>
     <p>Build the human-centered culture, structures, systems and skills to multiply your organization's capabilities and drive lasting, holistic transformations.</p>
  </Content>
     </Item>

     <Item>
      <Content>
     <h2>Commerce & Marketing</h2>
     <p>Digital design, analytics and performance marketing built around technical excellence and original, human-driven creative.</p>
  </Content>
     </Item>

  

     
     
     
    </Wrap>
 </BackG>
  
</>
 )}

 />

 )

export default ProductsGrid
