import React, { useState, useEffect } from 'react';
import styled, { keyframes } from "styled-components"
import { Spinner8 } from '@styled-icons/icomoon/Spinner8'

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */




const Wrapper = styled.div`
  //height: 100vh;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  
`

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`



const Spinner = styled(Spinner8)`

height: 25px;
color: rgba(29, 155, 240, 1);

animation: 1.25s linear ${spin} infinite;
`


const LoadingAccount = () => {

 

  

  return (
    <Wrapper>
    <div><Spinner /></div>
    </Wrapper>
  );
}

export default LoadingAccount
