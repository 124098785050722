
import { useAuth0 } from '@auth0/auth0-react'
import { PopupButton  } from '@typeform/embed-react'
import React, { useEffect, useState } from 'react';
import { ChatHelp } from '@styled-icons/fluentui-system-filled/ChatHelp'
import styled from "styled-components"
import media from "../styles"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const ChatIcon = styled(ChatHelp)`

width: 75%;
color: rgb(29 155 240);
cursor: pointer;

`

const Icon = styled(PopupButton)`

background-color: rgba(255,255,255,1);
height: 75px;
width: 75px;
border-radius: 50%;
border:none;
position: fixed;
bottom: 10px;
right: 10px;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
box-shadow: rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 6%) 0px 2px 12px, rgb(0 0 0 / 4%) 0px 8px 14px, rgb(0 0 0 / 2%) 0px 12px 16px;

${media.tablet`
height: 100px;
width: 100px;
right: 20px;
right: 20px;
`}

${media.desktop`
height: 100px;
width: 100px;
bottom: 30px;
right: 30px;
`}

`

const ChatBot = () => {

    
    const { user, getAccessTokenSilently } = useAuth0();
    
    var firstName = user.given_name
    var userId = user.sub
    var userEmail = user.email
   

   


    
    





    return (

        
    <>


        <Icon id="raJ7OsH8"
  chat={true}
  size={50}
  hidden={{
    first_name: firstName,
    user_id: userId,
    user_email: userEmail
    
  }}
  onReady={() => {
    {/*console.log('form ready')*/}
  }}

  
  //hideFooter
  

  //enableSandbox
  
  
  >
    
    <ChatIcon />
    
     </Icon>
    
  







    </>
    )
}

export default ChatBot