import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import media from "../styles"
import { useAuth0 } from "@auth0/auth0-react";
import Star from "../../images/svg/stars.svg"
import Board from "../../images/svg/team.svg"




const Stars = styled(Star)`

height: 15px;

`


const Whiteboard = styled(Board)`

width: 400px;
`
const Shift = styled.div`

width: 100%;
margin-bottom: 40px;
${media.desktop`
width: 800px;
margin: 0 -375px -180px auto;

                `}

`

const Set = styled.div`

width: auto;


${media.desktop`
width: 500px;
margin-right: 20px;

                `}
`



const SignUpButton = () => {
  const { loginWithRedirect } = useAuth0();

  return <SignUp onClick={() => loginWithRedirect({screen_hint:"signup"})}>Get your beautiful PDFs</SignUp>;
};


const SignUp = styled.button`
font-family: -apple-system, BlinkMacSystemFont, “Segoe UI”, Roboto, Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 17px;
//background: #A6FF00;
background-color: rgb(29 155 240);
border: none;
border-radius: 9999px;
padding: 10px 0 10px 0;
min-height: 44px;
width: 100%;
margin-top: 24px;
cursor: pointer;
//letter-spacing: -0.01em;
//color: #FF0063;
color: rgba(255,255,255,1);

${media.xsmall`
                
width: 300px;
               

                `}

`


const Wrap = styled.div`
display: flex;
flex-direction: row-reverse;
align-items: center;
justify-content: center;
flex-wrap: wrap;
width: 100%;
padding: 35px 20px 60px 20px;
box-sizing: border-box;
color: rgba(0,0,0,0.95);
max-width: 1200px;
margin: auto;
margin-bottom: 35px;
border: 2px solid;
  border-color: white;
//background-color: #e9fff5;
background-color: white;
border-radius: 0;
overflow-y: hidden;
overflow-x: visible;
  
  line-height: 23px;
  //margin: 28px 0 0 0;
  text-align: left;

  ${media.xsmall`
                
  padding: 35px 40px 60px 40px;
               

                `}
  
  h1 {
font-family: AkzidenzGrotesk_Regular;

font-size: 64px;
line-height: 76px;
white-space: pre-wrap;
margin-bottom: 18px;
margin-top: 5px;

  }

  h2 {


    font-family: 'AkzidenzGrotesk_Light_Bold';
    font-weight: normal;
    font-variant-ligatures: none;
    letter-spacing: -1px;
font-size: 64px;
line-height: 76px;
white-space: pre-wrap;

margin-bottom: 18px;
color: rgba(0,0,0,0.85);


  }

  p {


font-size: 18px;
line-height: 26px;
white-space: pre-wrap;
margin: 0 0 18px 0;


  }

  ul {
    
    
    margin: 20px 0 0 0;
    
  list-style: none;
  padding: none;
  -webkit-padding-start: 0;
 //margin: 0;
  text-align: center;
  font-size: 14px;
}

${media.tablet`
 
padding: 100px 100px 100px 100px;


                `}

  ${media.desktop`
  flex-direction: row-reverse;
align-items: center;
justify-content: flex-end;
padding: 100px 100px 100px 100px;
flex-wrap: nowrap;
border-radius: 16px;
border: 2px solid;
  border-color: rgb(239 243 244);

                `}


`


const Italic = styled.span `
font-style: italic;

`

const Ratings = styled.div `
display: flex;
align-items: center;
justify-content: center;
flex-direction: row;
margin-top: 50px;

p {
  color: rgba(0,0,0,0.5)
}

div {
  margin: 0 25px 0 25px;
}

`

const Width = styled.div `
width: 100%;

`



const Tag = styled.span`

//background-color: #FF8C78;
background-color: rgb(224 242 237);
//background: #78ffd6; /* fallback for old browsers */
//background: -webkit-linear-gradient(to right, #78ffd6, #a8ff78);  /* Chrome 10-25, Safari 5.1-6 */
//background: linear-gradient(to right, #78ffd6, #a8ff78); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

padding: 2px 5px 2px 5px;
color: rgba(0,0,0,0.8);
border-radius: 4px;
font-size: 13px;
//font-family: AkzidenzGrotesk_Light;
font-weight: bold;
//text-transform: uppercase;
//letter-spacing: 2px;


`


const Highlight = styled.span`

background-color: rgb(224 242 237);
padding: 2px 5px 2px 5px;
color: rgba(0,0,0,0.8);
border-radius: 4px;

//margin-left: 2px;

`


  
  
const Pdf = () => (
 
  <StaticQuery
  query={graphql`
    query {
      placeholderImage: file(relativePath: { eq: "screen.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `}


    

  render={data => (

   <Wrap>
    
       <Shift><Img fluid={data.placeholderImage.childImageSharp.fluid} />
       </Shift>
       <Set>
       <Tag>How it works</Tag>
     <h2>Great names, beautiful PDFs.</h2>
     <p>Naming is a tricky, subjective endeavour. Working with <Italic>too many</Italic> ideas usually dilutes the quality, causes indecision and splits opinions. A tight focus works best.</p>
     <p>With Nameflow, you'll first receive three curated, high-calibre name suggestions—just the right amount—for a feedback round, followed by two more based on your feedback. Each delivered to you as individual, professionally designed PDFs.</p>
     <p>And with beautiful color, contrast and typography coordinated by your creative team, you can visualize your company name across different digital mediums, and be creatively inspired for the next stage in your journey. Also perfect for use in pitch decks, sharing or discussion with other stakeholders.</p>
     <SignUpButton />
     </Set>
    
    
     
    </Wrap>
 
 )}
 />
)




export default Pdf
