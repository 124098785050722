import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import media from "../styles"
import { useAuth0 } from "@auth0/auth0-react";
import Board from "../../images/svg/approve.svg"
import Circle from "../../images/svg/promise_n.svg"
import { HomePageTextRotator } from "../homepagetextrotator"
import { Rotator } from "../homepagetextrotator copy"

const Nflow = styled(Circle)`

height: 23px;
width: auto;
fill: white;
`


const Image = styled.div`

width: 100%;
height: auto;

img {
  filter: opacity(95%) saturate(135%) blur(0px) drop-shadow(2px 2px 5px rgb(0 0 0 / 0.25)) contrast(110%) brightness(102%);
}

`

const ImageWrap = styled.div`

display: flex;
flex-direction: row;
justify-content: center:
align-items: center;
align-self: flex-end;
//max-width: 600px;
width: 100%;
${media.large`
                //margin-right: -100px;

                `}

                

`


const Whiteboard = styled(Board)`

width: 400px;
`
const Shift = styled.div`
margin: 0 auto 20px auto;
width: 150px;
`




const BackG = styled.div`
//background-color: #F0E2FC;
background-color: #EBEBF8;
width: 100%;
display: flex;
//border-bottom: 1px solid;
  //border-color: grey;
  //border-bottom: 2px solid;
 // border-color: rgb(239 243 244);
  color: white;
  padding-bottom: 180px;
  border-bottom: 2px solid;
  border-color: rgb(239 243 244);
  
`

const Wrap = styled.div`
display: flex;
max-width: 1300px;
flex-direction: column;
align-items: center;
justify-content: center;
flex-wrap: wrap;
width: 100%;
padding: 0px 20px 100px 20px;
box-sizing: border-box;

//max-width: 1200px;
margin: auto;
//margin-bottom: 35px;
//background-image: linear-gradient(-20deg, #b721ff 0%, #21d4fd 100%);
//margin-bottom: 100px;
//background-color: #EBEBF8;
//background-color: white;
//border: 2px solid;
  //border-color: rgb(239 243 244);
  //background-color: rgb(224 242 237);
  //background-color: #CBF6FF;

  //ackground: #78ffd6; /* fallback for old browsers */
//background: -webkit-linear-gradient(to right, #78ffd6, #a8ff78);  /* Chrome 10-25, Safari 5.1-6 */
//background: linear-gradient(to right, #78ffd6, #a8ff78); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

border-radius: 0;
  
  line-height: 23px;
  //margin: 28px 0 0 0;
  text-align: left;

  ${media.xsmall`
                
  padding: 30px 40px 0px 40px;
                             
              
                              `}
  
                              h1 {
font-family: 'GoudyOldStyleBold';
-webkit-font-smoothing: antialiased;
font-variant-ligatures: none;
letter-spacing: -2px;
margin-top: 0;
font-size: 52px;
line-height: 52px;
white-space: pre-wrap;
margin-bottom: 24px;
font-weight: normal;
color: #121212;
border-left: 10px solid rgb(230, 255, 0);
//text-align: left;

${media.phone`

text-align: center;




                `}


${media.tablet`
font-size: 64px;
line-height: 64px;

                `}

                ${media.large`

text-align: left;

                `}

  }

  h2 {

    font-family: 'GoudyOldStyleRegular';
    
font-size: 22px;
letter-spacing: -1px;
//line-height: 30px;
white-space: pre-wrap;
//max-width: 300px;
//margin-bottom: 20px;
//color: rgb(38 38 39);
color: #121212;
text-align: center;
font-weight: normal;

${media.phone`

text-align: center;
max-width: none;




                `}

${media.tablet`
font-size: 24px;
line-height: 32px;
margin-bottom: 32px;
width: auto;
max-width: none;

                `}
                ${media.large`

text-align: left;
                `}

  }

  p {


font-size: 18px;
line-height: 26px;
white-space: pre-wrap;
margin: 0 0 48px 0;

${media.large`

text-align: left;

                `}


  }

  ul {
    
    
    margin: 20px 0 0 0;
    
  list-style: none;
  padding: none;
  -webkit-padding-start: 0;
 //margin: 0;
  text-align: center;
  font-size: 14px;
}

  ${media.tablet`
  
align-items: center;
justify-content: center;
padding: 30px 50px 0px 50px;
flex-wrap: nowrap;
text-align: left;
//margin-bottom: 200px;


                `}

                ${media.large`
                //border-radius: 16px;
                flex-direction: row;
                justify-content: start;
                `}


`


const Italic = styled.span `
font-style: italic;

`

const Ratings = styled.div `
display: flex;
align-items: center;
justify-content: center;
flex-direction: row;
margin-top: 50px;

p {
  color: rgba(0,0,0,0.5)
}

div {
  margin: 0 25px 0 25px;
}

`

const Width = styled.div `
width: 100%;

`



const Tag = styled.span`

background-color: #FF8C78;
padding: 2px 5px 2px 5px;
color: rgba(0,0,0,0.8);
border-radius: 4px;
font-size: 13px;
font-weight: bold;
margin-left: 2px;


`

const Underline = styled.span`
text-decoration: underline 2px solid yellow;
text-decoration-skip-ink: none;

`


const Grid = styled.div`

display: flex;
flex-direction: column;
justify-content: center;
align-items: start;
p{
  margin-bottom: 60px;
}

${media.desktop`
  flex-direction: row;
  padding-left: 100px;
  p{
  margin-bottom: 24px;
 
}



                `}

`

const Item = styled.div`

display: flex;
flex-direction: column;
justify-content: center;
align-items: start;
max-width: 300px;
border-left: 1px solid #f2f2f2;
padding-left: 24px;
position: relative;

h3 {
  
  margin: 0;
  padding: 0;
  font-size: 28px;
  font-family: 'AkzidenzGrotesk_Bold';
  letter-spacing: -1px;
  white-space: pre-wrap;
  text-align: left;
  line-height: 36px;
}

p{
  text-align: left;
  width: 75%;
  margin-top: 24px;
  font-size: 16px;
  line-height: 24px;
  
}

`



const Title = styled.div`

//margin-bottom: 80px;
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

${media.large`
 width: 100%;




                `}



h2{
  font-size: 16px;
  font-family: 'AkzidenzGrotesk_Regular';
  letter-spacing: 1px;
  white-space: pre-wrap;
 margin: 0;
 margin-bottom: 16px;
  line-height: 26px;
  color: rgba(0,0,0,0.5);
  text-align: center;
}

h3 {

  font-size: 48px;
  font-family: 'AkzidenzGrotesk_Bold';
  letter-spacing: -2px;
  margin: 0;
  margin-bottom: 48px;
  
 
  line-height: 56px;

}

p {

  font-size: 24px;
  line-height: 40px;
  letter-spacing: -0.25px;
  //max-width: 650px;
  width: 100%;
  font-family: 'AkzidenzGrotesk_Regular';
  margin-bottom: 24px;
  -webkit-font-smoothing: antialiased;
font-variant-ligatures: none;
color: rgba(0,0,0,0.9);
//margin-left: 48px;
}


`



const Highlight = styled.span`

background-color: #CBF6FF;
padding: 2px 5px 2px 5px;
color: rgba(0,0,0,0.8);
border-radius: 4px;


font-size: 12px;
line-height: 20px;

`

const Goudy = styled.span`

font-family: 'GoudyOldStyleItalic';
font-size: 82px;
`

const Normal = styled.span`

font-family: 'AkzidenzGrotesk_Medium';
color: rgba(0,0,0,0.6);

`
  
  
const About = () => (
 
<StaticQuery
  query={graphql`
    query {
      birds: file(relativePath: { eq: "birds.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            
            ...GatsbyImageSharpFluid
          }
          
        }
      }
    
      woman: file(relativePath: { eq: "woman3.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            aspectRatio
            ...GatsbyImageSharpFluid
          }
          
        }
      }

    


    }

    
  `}

  render={data => (
  
 

    <>

 
<BackG>
   <Wrap>

   
     <Title>
      
       {/*<ImageWrap>
     
     <Image>
     <Img fluid={data.birds.childImageSharp.fluid} />
    </Image>
    
  </ImageWrap>*/}
     
     <p>Human 8 is a human-centered growth consultancy established in 2019. We're a British-founded organization headquartered in Taipei, Taiwan with a global reach through distributed teams and a worldwide talent and partner network.</p>
     <p>Everything we do is based on human-centered values. Make things better for real people. For <Italic>all</Italic> people. Our mission is to empower change through human-centered design and research. That's why we've been called Human 8 since 2019. Humanate - it means "To Make Human".</p>
     <p>Through our original human-driven approach, we help aspirational partners and leaders unlock growth and make sense of today's fast-moving maelstrom of culture, commerce, technology and consumerism.
            In rapidly changing times, brands that thrive are those that experiment, move fast and reorient.
            Using deep research and design-thinking we conceive themes, clarity and meaning around an organization's existence and address urgent strategic challenges through a unique offering of fixed-package growth accelerants. Helping to unite and inspire teams, steer decisions and more effectively relate to shifting market trends.</p>
            <p>We'll challenge legacy business models, reorient the marketing maze, make sense of AI and digital transformation, push emerging categories forward and strike balance between insight-driven ideas and those that no focus group would ever think to suggest.
            We build and deliver strategic assets to communicate original, culturally relevant ideas and experiences. And we use technology and agile entrepreneurial mindsets to quickly solve problems in nontraditional ways.</p>

            <p>We believe that rewards are for risk-takers, and we don't wait for the future.</p>

            <p>Some clients we've worked for include Samsung, T-Mobile, Deliveroo, Askey and Edimax Technology - among many others.</p>


            

       
      
           
       
           
       
       
     </Title>
     
     
     
    </Wrap>
 </BackG>
  
</>
 )}

 />

 )

export default About
