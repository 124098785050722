import React from "react"
import styled, { keyframes } from "styled-components"
import { useAuth0 } from '@auth0/auth0-react';
import media from "../styles"



/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const Card = styled.div`
 
  background-color: white;
  border-radius: 16px;
  border: 1px solid;
  border-color: rgb(239 243 244);
  padding: 32px 32px 32px 32px;
  color: rgba(0,0,0,0.95);
  font-family: -apple-system, BlinkMacSystemFont, “Segoe UI”, Roboto, Helvetica, Arial, sans-serif;
  //display: flex;
  //flex-direction: row;
  //align-items: center;
  //justify-content: center;
  margin-bottom: 16px;
 
  
  
  h2 {
    font-weight: 900;
  font-size: 28px;
  line-height: 28px;
  

  
  
  }

  h3 {
    font-weight: 900;
  font-size: 23px;
  line-height: 28px;
  margin: 0;
  }

  p {
    font-weight: normal;
  font-size: 15px;
  line-height: 23px;
  margin: 10px 0 0 0;
  margin-top: 8px;
  margin-bottom: 24px;
  }

`

const Yellow = styled.span`
color: rgb(251, 206, 55);
padding-right: 15px;
font-size: 38px;
`

const Title = styled.div`
display: flex;
flex-direction: column-reverse;
align-items: start;
justify-content: space-between;

${media.desktop`
flex-direction: column-reverse;
align-items: start;

`}

p {

  margin: 0 0 8px 0;
}
`
const fadeIn = keyframes`
  from {
    background-color: rgba(255,255,255,1);
  }

  to {
    background-color: #fffbeb;
  }
`

const slideIn = keyframes`
  from {
    margin-left: -7px;
    opacity: 0;
  }

  to {
    margin-left: -4px;
    opacity: 1;
  }
`

const BriefComplete = styled.span`

background-color: #fffbeb;
padding: 2px 5px 2px 5px;
color: #795d02;
border-radius: 4px;
//margin-left: -4px;
animation: ${fadeIn} 0.5s ease-in;

animation-iteration-count: 1;
font-size: 12px;
font-weight: bold;


`

const Email = styled.span`


color: #795d02;
font-weight: bold;


`

const BriefInComplete = styled.span`


padding: 2px 5px 2px 5px;
color: rgb(14 98 69);
border-radius: 4px;
margin-left: -4px;
font-size: 12px;

`



const Names = () => {
  
  const { user } = useAuth0()
 
  const Incomplete = 'complete your brief \u2192'
  const Complete = 'In Progress'

  return (
    
      <>
        <Card>
          <Title>
        <h2>Your Company Names</h2>
        <p>{user['https://my-app.example.com/brief'] == "completed" && user['https://my-app.example.com/status'] == "paid" && <BriefComplete>{Complete}</BriefComplete> }</p>
        </Title>

        <div>
        <p>We'll despatch your names to <Email>{user.email}</Email> when they're ready. Keep an eye on your inbox over the next few days ⏱ </p>
        </div>


        

        </Card>
      </>
    )
  
}

export default Names
