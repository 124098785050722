import Interstate_Regular from "../fonts/Interstate-Regular.otf"
import Interstate_Light from "../fonts/Interstate-Light.otf"
import AkzidenzGrotesk_Light from "../fonts/AkzidenzGrotesk-Light.otf"
import AkzidenzGrotesk_Light_Bold from "../fonts/akzidenz_grotesk_light_bold.woff2"
import AkzidenzGrotesk_Regular from "../fonts/AkzidenzGrotesk-Regular.woff2"
import AkzidenzGrotesk_Regular_OTF from "../fonts/AkzidenzGrotesk-Regular.otf"
import AkzidenzGrotesk_Medium from "../fonts/AkzidenzGrotesk-Medium.otf"
import AkzidenzGrotesk_Bold from "../fonts/AkzidenzGrotesk-Bold.otf"
import AkzidenzGrotesk_Italic from "../fonts/AkzidenzGrotesk-Italic.otf"
import EuclidFlex_Light from "../fonts/EuclidFlex-Light.otf"
import EuclidFlex_Regular from "../fonts/EuclidFlex-Regular.otf"
import EuclidFlex_Medium from "../fonts/EuclidFlex-Medium.otf"
import EuclidFlex_Bold from "../fonts/EuclidFlex-Bold.otf"
import GoudyOldStyleRegular from "../fonts/GoudyOldStyleRegular.otf"
import GoudyOldStyleBold from "../fonts/GoudyOldStyleBold.otf"
import GoudyOldStyleItalic from "../fonts/GoudyOldStyleItalic.otf"
import GoudyOldStyleItalicBold from "../fonts/GoudyOldStyleBoldItalic.otf"

export {
    Interstate_Regular,
    Interstate_Light,
    AkzidenzGrotesk_Light,
    AkzidenzGrotesk_Light_Bold,
    AkzidenzGrotesk_Medium,
    AkzidenzGrotesk_Regular,
    AkzidenzGrotesk_Regular_OTF,
    AkzidenzGrotesk_Bold,
    AkzidenzGrotesk_Italic,
    EuclidFlex_Light,
    EuclidFlex_Regular,
    EuclidFlex_Medium,
    EuclidFlex_Bold,
    GoudyOldStyleRegular,
    GoudyOldStyleBold,
    GoudyOldStyleItalic,
    GoudyOldStyleItalicBold,



}