import React from "react"
import styled from "styled-components"
import media from "./styles"
import { MainText } from "./contentwrapper"

const Title = styled.h1`
`

const RotatorWrapper = styled.section`

&& h1 {
    margin-top:2.5em;
    ${media.desktop`margin-top:0.75em;`}
}


`

const FullHeight = styled.div`
height: 80%;
display: flex;
flex-flow: column;
justify-content: space-between;
${media.phone`height:85%;`}
${media.desktop`height:95%;`}


`

const FullHeightVH = styled.div`
height: 100vh;

`

const HeightWrap = styled.div`


justify-self: flex-end;


`

const BlockContent = styled.p`
`

const Highlight = styled.div`
//height: 6rem;
height: 150px;
//${media.desktop`height: 9.5rem;`}

`


const MenuLink = styled(Title)`

& {
text-align: left;
font-size: 1.2rem;
color: rgba(0,0,0,1);
padding-top: 3em;
${media.phone`margin-right:0;
                color: rgba(0,0,0,0.5);
                text-align: left;
                padding-top: 3em;`}
${media.desktop`font-size: 2.2rem;
                margin-right: 0;
                padding-top: 4em;
                text-align:left;`}
transition: color 0.2s ease-in-out, margin 0.2s ease-in-out;
display: ${props => props.yes ? "block" : "none"};

}


${RotatorWrapper}:hover & {
    color: rgba(0, 0, 0, 1);
    transition: color 0.15s linear, margin 0.15s ease-in-out;
    
    ${media.phone`margin-left: 0.25em;`}
    ${media.desktop`margin-left: 0.25em;`}
}

`

const YellowSpan = styled.div`

display:inline-block;
background-color: yellow;
padding: 0.25rem;


`

const Arrow = styled.span`
${media.phone`color: rgba(0,0,0,0);`}
color: rgba(0, 0, 0, 1);
transition: color 0.15s linear;
display:inline;

${RotatorWrapper}:hover & {

    

    
    color: rgba(0, 0, 0, 1);
    transition: color 0.4s ease-in-out;
    
    
    
}
`


const HomePageBlock = props => (
<>
<Title>{props.title}</Title>
<BlockContent>{props.blockcontent}</BlockContent>
<MenuLink yes={props.displaylink}>{props.menulink}</MenuLink>
</>
)

export { FullHeightVH, HeightWrap, Arrow, FullHeight, RotatorWrapper, Title, BlockContent, Highlight, MenuLink, HomePageBlock }