import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout_copy"
import SEO from "../components/seo"
import styled from "styled-components"
import media from "../components/styles"
import { useAuth0 } from "@auth0/auth0-react";
import Hero from "../components/landing/hero"
import How from "../components/landing/how"
import Creatives from "../components/landing/creatives"
import Pdf from "../components/landing/pdf"
import Calender from "../components/landing/calender"
import Copy from "../components/landing/copy"
import Who from "../components/landing/who"
import Confident from "../components/landing/confident"
import Mkts from "../components/landing/mkts"
import Pricing from "../components/landing/pricing"
import Clients from "../components/landing/clients"
import Products from "../components/landing/work_products"
import HowItWorks from "../components/landing/howitworks"
import Simplified from "../components/landing/simplified"
import Naming from "../components/landing/naming"
import Branding from "../components/landing/branding"
import Tech from "../components/landing/tech"
import Supercharged from "../components/landing/supercharged"
import Header from "../components/header/header"
import Headstart from "../components/landing/headstart"
import Collab from "../components/landing/collab"
import NFT from "../components/landing/nft"
import Guarantee from "../components/landing/guarantee"
import Promise from "../components/landing/promise"
import Rotator from "../components/landing/rotator"
import Easy from "../components/landing/easy"


const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return <button onClick={() => loginWithRedirect({screen_hint:"signup"})}>Create Account</button>;
};




const Spacer = styled.div `
height: 200px;
`

const BackgroundColor = styled.div `
//background: linear-gradient(#f5f5f7, white);
background: #DCFF93;
`

const BackgroundColorB = styled.div `
//background: linear-gradient(#f5f5f7, white);
background: #A6FF00;
margin: 0 25px 0 25px;
`


const LayoutGridB = styled.div `
display: grid;


row-gap: 10px;


width: auto;


padding: 5px 25px 25px 25px;




${media.desktop`
//grid-template-columns: 50% 50%;
grid-template-columns: 10fr 10fr 10fr 10fr 10fr 10fr 10fr 10fr 10fr 10fr;
column-gap: 0px;
row-gap: 25px;
column-gap: 25px;
//width: 950px;
width: auto;
max-width: 1360px;
margin: 0 auto 0 auto;


align-items: center;
justify-content: center;
                `}

`



const LayoutGrid = styled.div `
display: grid;
row-gap: 25px;
width: auto;
padding: 25px 25px 25px 25px;

${media.tablet`
padding: 25px 50px 25px 50px;

                `}

${media.desktop`
//grid-template-columns: 50% 50%;
grid-template-columns: 10fr 10fr 10fr 10fr 10fr 10fr 10fr 10fr 10fr 10fr;
column-gap: 0px;
row-gap: 25px;
column-gap: 25px;
//width: 950px;
width: auto;
max-width: 1360px;
margin: 0 auto 0 auto;
padding: 25px 50px 25px 50px;
align-items: center;
justify-content: center;
                `}

`

const LayoutGridClients = styled.div `
display: grid;
row-gap: 25px;
width: auto;
padding: 25px 25px 0px 0px;
margin-top: 85px;

${media.tablet`
padding: 25px 50px 0px 0px;

                `}

${media.desktop`
//grid-template-columns: 50% 50%;
grid-template-columns: 10fr 10fr 10fr 10fr 10fr 10fr 10fr 10fr 10fr 10fr;
column-gap: 0px;
row-gap: 25px;
column-gap: 25px;
//width: 950px;
width: auto;
max-width: 1360px;
margin: 100px auto 0 auto;
padding: 25px 50px 0px 50px;
align-items: center;
justify-content: center;
                `}

`

export default ({ data }) => (
  
  


  
  
  <><Layout>

    <SEO title="Nameflow by Human 8 | Brand & Product Naming" description="Human-crafted, meaningful names for your business within three days." favicon="/nflowfav.svg" applepng="/nflowfavicon.png" keywords={[`company names`, `business names`, `brand names`]} />
   

    <Hero />
    
    <HowItWorks />
    
    <Promise />
    <Simplified />
    <Rotator />
   
    <Easy />
    <Branding />
    <Tech />
    <Supercharged />
    <Headstart />
    <Collab />
    <NFT />
    <Guarantee />
    
    <Clients />
    <Pricing />

    {/*}
    <Products />
    <Copy />
    <How />
    
    
    <Creatives />
    <Pdf />
    <Who />
    <Mkts />
    <Confident />
    <Calender />
*/}



    {/*</LayoutGrid>



    <LayoutGridB>
      <Highlights />
      <Work />
    </LayoutGridB>


    <BackgroundColor>
      <LayoutGridClients>
        <Clients />
      </LayoutGridClients>
    </BackgroundColor>






    <LayoutGridB>
      <HighlightsEthos />
      <WorkEthos />
    </LayoutGridB>



*/}


    
    
    

  </Layout>
  
  
    </>
)



export const query = graphql`
query {contentfulHomePage {
  introCopy {
    introCopy
  }bodyCopy {
    bodyCopy
  }
  hero {
    title
    file {
      url}
      fluid(maxWidth: 450) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
  }
 
  allContentfulContentPost (sort: { fields: [createdAt], order: DESC } limit:2)

{
edges {
node {
id
title
slug
createdAt(formatString: "MMMM DD, YYYY")
featuredImage {
  file {url}
  fluid(maxWidth: 500){
    ...GatsbyContentfulFluid_withWebp
  }
 

}
content {childMarkdownRemark {excerpt}}
}
}
}
}




  `
