import React, { useState, useEffect } from 'react';
import { Redirect } from '@reach/router'
import styled, { keyframes } from "styled-components"
import Logo from "../../images/brand/nameflow.svg"
import { CheckmarkCircle } from '@styled-icons/fluentui-system-filled/CheckmarkCircle'
import media from "../styles"
import SEO from "../../components/seo"


/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const fadeIn = keyframes`
  from {
    opacity: 0;
    
  }
  to {
    opacity: 1;
    
  }
`

const Success = styled(CheckmarkCircle)`

height: 46px;

color: #00c853;

animation: 0.25s ease-in ${fadeIn};
padding: 0;
margin: 0;





`

const Mark = styled(Logo)`
width: 85px;
//margin: 30px 200px 10px 220px;
height: auto;
opacity: 0.9;
${media.desktop`
width: 125px;
`}

`

const Background = styled.div`
height: 100%;
background: white;
`

const Grid = styled.div`


justify-items: start;
height: 80vh;

display: grid;
align-items: start;

${media.tablet`
grid-template-columns: 100%;


//column-gap: 20px;
//padding: 50px;
`}
${media.desktop`
grid-template-columns: minmax(100px, 310px) 2fr;

//column-gap: 20px;
//padding: 50px;
`}
${media.large`
grid-template-columns: minmax(100px, 370px) 2fr;

//column-gap: 20px;
//padding: 50px;
`}
${media.xlarge`
grid-template-columns: minmax(100px, 470px) 2fr;

//column-gap: 20px;
//padding: 50px;
`}

`

const Left = styled.div`

top: 0;
  
  width: 100%;
  display: flex;
  flex-direction: row;
  
  align-items: center;
  justify-content: start;
  background-color: rgb(247 249 249);
  padding: 15px 20px 20px 20px;
  box-sizing: border-box;



  ${media.desktop`
  top: 0;
  height: 100vh;
  width: 100%;
  
  flex-direction: column;
  padding: 65px 0 50px 0;
  align-items: center;
  justify-content: center;
`}

`

const Center = styled.div`

display: flex;
align-items: center;
justify-content: center;


${media.desktop`
align-self: center;
`}

`
const Light = styled.div`

color: rgba(0,0,0,0.35);
margin-right: 10px;


`
const Card = styled.div`
background-color: white;
 
  border-radius: 16px;
  padding: 32px;
  color: rgba(0,0,0,0.95);
  font-family: -apple-system, BlinkMacSystemFont, “Segoe UI”, Roboto, Helvetica, Arial, sans-serif;

  h1 {
    font-weight: 700;
 font-size: 28px;
  line-height: 28px;
  margin-bottom: 18px;
  margin-top: 6px;
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  ${media.xsmall`

  line-height: 46px;


                `}

  ${media.desktop`
  font-size: 36px;
  line-height: 46px;
`}
  
  }


  h2 {
    font-weight: 700;
  font-size: 28px;
  line-height: 28px;
  margin-bottom: 6px;
  }

  h3 {
    font-weight: 900;
  font-size: 22px;
  line-height: 28px;
  margin: 0;
  }

  p {
    font-weight: normal;
  font-size: 15px;
  line-height: 23px;
  margin: 28px 0 0 0;
  text-align: center;
  
  }

  label {
    font-size: 12px;
    position: relative;
    vertical-align: middle;
    bottom: 1.25px;

  }

`


const Flex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center:
  justify-content: center;
`

const Thankyou = () => {

  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000)
  }, []);

  

  return (
    <>
      <Background>
      <SEO title="Thank you | Nameflow" description="Human-crafted, meaningful names for your business within three days." favicon="/nflowfav.svg" applepng="/nflowfavicon.png" keywords={[`company names`, `business names`, `brand names`]} />
            <Grid>
              <Left><Mark /></Left>
              <Center>
              <Card>
    {loading
      ? 
      
      <>
      <h2>Thank you</h2>
      <h1><Flex><Light>Payment confirmed</Light><Success/></Flex></h1>
      
</>


 : <Redirect to="/nameflow/welcome" noThrow />} 


</Card></Center></Grid></Background>
        </>
    
  );
}

export default Thankyou
