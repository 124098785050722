import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import Logo from "../../images/brand/nameflow.svg"
import Menu from "./menu";
import Footer from "./footer";
import media from "../styles"

 
const Background = styled.div`
height: 100%;





//background: #f8f6f1;
background: white;
`

const Grid = styled.div`
//padding: 50px 0 0 0;
//margin-left: -15px;



display: grid;
align-items: start;

${media.tablet`
grid-template-columns: 100%;


//column-gap: 20px;
//padding: 50px;
`}
${media.desktop`
grid-template-columns: minmax(100px, 310px) 2fr;

//column-gap: 20px;
//padding: 50px;
`}
${media.large`
grid-template-columns: minmax(100px, 370px) 2fr;

//column-gap: 20px;
//padding: 50px;
`}
${media.xlarge`
grid-template-columns: minmax(100px, 470px) 2fr;

//column-gap: 20px;
//padding: 50px;
`}

`

const Trial = styled.div`
width: 100%;
//min-height: 56px;
background-color: rgb(230 243 247);
padding: 8px;
height: 39px;
box-sizing: border-box;
font-size: 15px;
line-height: 23px;
color: rgb(3 108 140);
//position: sticky;
top: 0;
z-index: 10;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;



`


const LogoutButton = () => {
      const { logout } = useAuth0()
    
      return (
        <button onClick={() => logout({ returnTo: window.location.origin })}>
          Log Out
        </button>
      )
    }

    
 
 
 const DashLayout = ({children}) => (
   
  
       <>
            <Background>
             {/*<Mark />
            
           <nav>
        <Link to="/account/">Home</Link>{" "}
        <Link to="/account/settings/">Settings</Link>{" "}
        
        <LogoutButton />
        
        <a
          href="#logout"
          onClick={e => {
            logout()
            e.preventDefault()
          }}
        >
          Log Out
        </a>
      </nav>*/}
      
      <Grid>
        
      <Menu />
      
      {children}
      
            </Grid>
            </Background>
       </>
     
 )
 
 
 
 export default DashLayout
 