import React from "react"
import styled from "styled-components"
import { useAuth0 } from '@auth0/auth0-react';


/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const Card = styled.div`
 
  background-color: white;
  border: 1px solid;
  border-color: rgb(239 243 244);
  border-radius: 16px;
  padding: 32px;
  color: rgba(0,0,0,0.95);
  font-family: -apple-system, BlinkMacSystemFont, “Segoe UI”, Roboto, Helvetica, Arial, sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  

  h2 {
    font-weight: 900;
  font-size: 28px;
  line-height: 28px;
  }

  h3 {
    font-weight: 900;
  font-size: 23px;
  line-height: 28px;
  margin: 0;
  }

  p {
    font-weight: normal;
  font-size: 14px;
  line-height: 23px;
  margin: 0 0 0 0;
  }

`

const Italic = styled.span`
font-style: italic;
`

const Yellow = styled.span`
color: rgb(251, 206, 55);
padding-right: 15px;
font-size: 38px;
`

const Resubmit = () => {
  

 

  return (
    
      <>
        <Card>
          <div>
        <h3><Yellow>&#128161;</Yellow></h3>
        </div>

        <div>
        <p>You can resubmit your brief anytime if needed, and we'll try to take it into account. Within 24 hours of your payment is best, as the naming process is already underway.</p>
        </div>

        </Card>
      </>
    )
  
}

export default Resubmit
