import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"
import { StaticQuery, graphql } from "gatsby"
//import Img from "gatsby-image"
import styled from "styled-components"
import media from "../styles"
import Circle from "../../images/svg/circle_green.svg"





const Bar = styled.div`
width: 100%;
//height: 80px;
text-align: left;
//background-color: #A6FF00;
//background-color: rgb(224 242 237);

//background: #CF8BF3;
//background-color: rgb(199 185 255);
background-color: #e2fff8;
//background: -webkit-linear-gradient(to right, #FDB99B, #CF8BF3, #A770EF);  /* Chrome 10-25, Safari 5.1-6 */
//background: linear-gradient(to right, #FDB99B, #CF8BF3, #A770EF); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

//font-family: 'AkzidenzGrotesk_Bold';
font-weight: normal;
  font-size: 14px;
  padding: 10px 20px 10px 20px;
  box-sizing: border-box;
  line-height: 23px;
//letter-spacing: -0.03em;
//color: rgba(255,255,255,1);
color: rgba(0,0,0,0.8);
//text-align: center;
margin: auto;

justify-content: center;
align-items: center;
display: none;

 div {
   
 }


${media.tablet`
//width: 250px;
//height: 80px;
font-size: 15px;
color: rgba(0,0,0,0.8);
display: flex;
                `}

`





  
  
const StickyBar = () => 
 
 /* <StaticQuery
  query={graphql`
    query {
      fileName: file(relativePath: { eq: "gatsby-atrsonaut.png" }) {
        childImageSharp {
         fluid(maxWidth: 400, maxHeight: 250) {
            ...GatsbyImageSharpFluid
        }
      }
    }
    }
  `}


  render={data => */

    <>

 <Bar><div>
 Nameflow Creative Promise—if you don't love your names, we'll create more <b>for free</b></div>
 </Bar>
  
</>


export default StickyBar
