import React from "react"
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react"
import { Link } from "gatsby"
import styled from "styled-components"
import DashLayout from "./dashlayout"
import DashWelcome from "./dashwelcome"
import Tip from "./tip"
import Offer from "./offer"
import ProjectBrief from "./projectbrief"
import Logo from "../../images/brand/nameflow.svg"
import Names from "./names"
import Resubmit from "./resubmit"
import Time from "./time"
import Loading from "./loading"
import media from "../styles"
import SEO from "../../components/seo"
import Back from "./back"
import HelpContact from "./helpcontact"


/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const Spacer = styled.div`
  height: 200px;
`

const Mark = styled(Logo)`
width: 85px;
opacity: 0.9;


`

const Center = styled.div`


max-width: 580px;
padding: 20px;
justify-self: center;


${media.tablet`

`}
${media.desktop`
grid-column-start: 2;
grid-column-end: 3;
justify-self: start;
max-width: 820px;
  

`}

//position: relative;
`


const MyButton = styled.span`
cursor: pointer;

`

const Footer = styled.div`

a {
text-decoration: none;
color: #000000f2;
}

display: flex;
align-items: center;
justify-items: center;
width: 100%;
padding: 20px 0 20px 0;
text-align: center;
background-color: rgb(247 249 249);
color: rgba(0,0,0,0.95);
  font-family: -apple-system, BlinkMacSystemFont, “Segoe UI”, Roboto, Helvetica, Arial, sans-serif;
div {
  width: 100%;
  padding: 20px;
}

p {
    font-weight: normal;
  font-size: 15px;
  line-height: 23px;
  margin: 5px 0 0 0;
 
  }

  ${media.desktop`
display: none;
  

`}

`


const LogoutButton = () => {
  const { logout } = useAuth0()

  return (
    <MyButton onClick={() => logout({ returnTo: process.env.GATSBY_AUTH0_LOGOUTURI })}>
      Log out
    </MyButton>
  )
}



const Message = styled.div`
  margin-top:20px;
`

const GetHelp = () => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0()
  
  
    
    getAccessTokenSilently({ ignoreCache: true });

  if (isLoading) {
    return <Loading />
  }

  return (
    isAuthenticated && (
      <>
        <DashLayout>
        <SEO title="Get Help | Nameflow" description="Human-crafted, meaningful names for your business within three days." favicon="/nflowfav.svg" applepng="/nflowfavicon.png" keywords={[`company names`, `business names`, `brand names`]} />


        <Center>
          <Back />
         
        <HelpContact />
        
       
        
        
        
        
       
        
        
        
        
        
       
        
        
        
         
          
</Center>
          <Footer>
          <div><Link to="/nameflow/welcome"><Mark /></Link>
          <p><LogoutButton />&nbsp; &#183; &nbsp;<Link to="/nameflow/help">Get help</Link></p></div>
          </Footer>
        </DashLayout>
        
        
      </>
    )
  )
}

export default GetHelp
