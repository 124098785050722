import React from "react"
import styled from "styled-components"
import { HomePageBlock } from "../components/homepageblock"
import FlexWrapper from "../components/flexwrapper"
import media from "./styles"
import mySVG from "../images/brand/eight.svg"

const ContactWrapper = styled.div`
`

const EightSVG = styled(mySVG)`

width: 11%;
    height: auto;
    margin: 0;
    padding-bottom: 0.2em;
    fill: black;
    opacity: 0.85;
    display:none;
    

    ${media.desktop`width: 12%;
                    padding-bottom:-0.75em;
                    display: none;`}
    ${media.phone`width: 11%;
                    padding-bottom:-0.15em;
                    display: none;`}

                    
                
    



`


const ContactBlock = () => (
  
    
       
      <FlexWrapper>
        <ContactWrapper>
       <HomePageBlock 
        title="CONTACT HUMAN 8"
        blockcontent={
          <>

            <p>growth@human-8.com

            </p>
          </>
                    }
          
      />
      </ContactWrapper>
      <EightSVG />
      </FlexWrapper>  

    
);

export default ContactBlock