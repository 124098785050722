import React, { useState, useEffect } from 'react';

import { Router, Redirect } from '@reach/router'
import { Link } from "gatsby";
import Onboard from "../components/onboard";
import OnboardConsent from "../components/onboard_consent";
import Verify from "../components/verify";
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import Redirecting from "../components/dashboard/redirecting";
import Loading from '../components/dashboard/loading';
import AppLoading from '../components/dashboard/app_loading';




const NotFound = () => <p>Sorry, nothing here</p>

const App = () => {

  
  
  

  return (
    
    <>
    <Router>
    <AppLoading path="/app" />
         
          <Onboard path="/app/onboard" />
          <OnboardConsent path="/app/onboard_consent" />
          <Verify path="/app/verify" />
          <NotFound default />
          

      </Router></>
   
  )
}
export default App