import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Privacy from "../components/leads/privacy"



export default ({ data }) => (
  
  
  
  <Layout>
    <SEO title="Privacy Policy | Human 8" favicon="/faveight.svg" applepng="/fav8.png" keywords={[`brand`, `business`, `design`]} />
    
    <div id="privacypolicy"><Privacy/></div>

  </Layout>
  
)




