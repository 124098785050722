import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import media from "../styles"
import { useAuth0 } from "@auth0/auth0-react";
import Arwin from "../../images/clients/arwin_svg.svg"
import Askey from "../../images/clients/askey.svg"
import Biochem from "../../images/clients/Biochem.svg"
import Deliveroo from "../../images/clients/Deliveroo.svg"
import Samsung from "../../images/clients/Samsung.svg"
import TMobile from "../../images/clients/T-Mobile.svg"
import DFlex from "../../images/clients/textvector.svg"

const ClientA = styled(Arwin)`
fill: rgba(255,255,255,0.75);
`
const ClientB = styled(Askey)`
fill: rgba(255,255,255,0.75);
`

const ClientC = styled(Biochem)`
fill: rgba(255,255,255,0.75);

shape-rendering: geometricPrecision;
`

const ClientD = styled(Deliveroo)`
//fill: rgba(255,255,255,0.75);
fill: rgba(0,0,0,0.35);
shape-rendering: geometricPrecision;

`

const ClientE = styled(Samsung)`
//fill: rgba(255,255,255,0.75);
fill: rgba(0,0,0,0.35);
shape-rendering: geometricPrecision;

`

const ClientF = styled(TMobile)`
//fill: rgba(255,255,255,0.75);
fill: rgba(0,0,0,0.35);
shape-rendering: geometricPrecision;
margin-left: 2px;

`

const ClientG = styled(DFlex)`
//fill: rgba(255,255,255,0.75);
fill: rgba(0,0,0,0.35);
shape-rendering: geometricPrecision;
stroke: rgba(0,0,0,0.35);
stroke-width: 2px;
padding-top: 2px;

`


const Wrap = styled.div`
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
width: 100%;
padding: 100px 20px 100px 20px;
box-sizing: border-box;
color: rgba(255,255,255,1);
margin: auto;
//background-color: rgba(0,0,0,0.85);
background-color: #f7f9f9;
border-radius: 0;
border-bottom: 2px solid;
  border-color: rgb(239 243 244);
  border-top: 2px solid;
  border-color: rgb(239 243 244);
  
  line-height: 23px;
  text-align: center;
  

  ${media.xsmall`
                
  padding: 100px 40px 100px 40px;
                             
              
                              `}
  
  h1 {

    font-family: 'AkzidenzGrotesk_Light_Bold';
    font-variant-ligatures: none;
    letter-spacing: 0px;

font-size: 20px;
line-height: 28px;
white-space: pre-wrap;
font-weight: normal;
margin-bottom: 60px;
color: rgba(0,0,0,0.55);
margin-top: 0;

${media.tablet`

margin-bottom: 48px;
                                           
                            
                                            `}
                

  }

  h2 {

    font-family: 'AkzidenzGrotesk_Light';

font-size: 42px;
line-height: 56px;
white-space: pre-wrap;
font-weight: normal;
margin-bottom: 18px;
color: rgba(0,0,0,0.8);


  }

  p {


font-size: 18px;
line-height: 26px;
white-space: pre-wrap;
margin: 0 0 18px 0;


  }
  ${media.phone`
  text-align: center;
  align-items: center;

                `}

  ${media.tablet`
  flex-direction: column;
align-items: center;
justify-content: center;
padding: 100px 100px 100px 100px;
flex-wrap: nowrap;
text-align: center;
//margin-bottom: 200px;


                `}

               

`


const Grid = styled.div`
display: grid;
grid-template-columns: repeat(2, 75px);
width: 100%;
margin-bottom: 88px;

margin-right: auto;
justify-content: center;
align-items: center;
column-gap: 60px;
row-gap: 60px;

${media.phone`
justify-content: center;
grid-template-columns: repeat(3, 75px);

                `}


${media.tablet`

margin-left: auto;
grid-template-columns: repeat(6, 95px);


                `}

div{
 width: 100%;
  margin: auto;
}

`

const Opacity = styled.div`
opacity: 0.3;

`
  
const Clients = () => (
 

  <StaticQuery
  query={graphql`
    query {
      placeholderImage: file(relativePath: { eq: "adam_black.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            aspectRatio
            ...GatsbyImageSharpFluid
          }
          
        }
      }
    
      beseye: file(relativePath: { eq: "beseye.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            aspectRatio
            ...GatsbyImageSharpFluid
          }
          
        }
      }

      eo: file(relativePath: { eq: "eo_black_tall.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            aspectRatio
            ...GatsbyImageSharpFluid
          }
          
        }
      }


    }

    
  `}

  render={data => (

    <>

 

   <Wrap>
     <h1>Companies our creative network has worked for</h1>
       <Grid>
       <div>
       <Opacity>
       <Img fluid={data.placeholderImage.childImageSharp.fluid} />
       </Opacity>
     </div>

    
     <div>
     <ClientD />
     </div>
     <div>
       <Opacity>
     <Img fluid={data.eo.childImageSharp.fluid} />
     </Opacity>
     </div>

     <div>
     <ClientE />
     </div>

     <div>
     <ClientF />
     </div>

     <div>
     <ClientG />
     </div>
    
    
    </Grid>
    
    </Wrap>
 
  
</>
  )}

  />

)
export default Clients
