import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"
import { StaticQuery, graphql } from "gatsby"
//import Img from "gatsby-image"
import styled from "styled-components"
import media from "./styles"
import Word from "../images/brand/logo.svg"







const BG = styled.div`
width: 100%;
background-color: #EBEBF8;
//position: sticky;
//-webkit-position: sticky;
//top: 0px;
//-webkit-top: 0px;
z-index: 1;


`









const Left = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: flex-start;
width: 100%;
padding: 25px 20px 30px 20px;
box-sizing: border-box;
color: rgba(0,0,0,0.95);
max-width: 1300px;
margin: auto;
a {

text-decoration: none;
line-height: 0;
}
  
  line-height: 23px;
  //margin: 28px 0 0 0;
  text-align: center;


  ${media.desktop`
  flex-direction: row;
align-items: center;
justify-content: flex-start;

                `}



`





const WordMark = styled(Word)`

fill: #131313;
opacity: 0.95;
shape-rendering: geometricPrecision;



//display: none;
width: 95px;

${media.tablet`
display: initial;


                `}

`



const Italic = styled.span`
font-family: 'AkzidenzGrotesk_Italic';
`



const Intro = styled.div`
font-family: 'AkzidenzGrotesk_Regular';
font-size: 23px;
letter-spacing: -0.01em;
line-height: 1.6em;
color: rgba(0,0,0,0.65);
`

const Make = styled.div`
font-family: 'GoudyOldStyle';
font-size: 16px;
letter-spacing: -0.01em;
line-height: 1.6em;
color: rgba(0,0,0,0.65);
padding: 4.5px 0px 0 16px;
margin-left: 17px;
border-left: solid rgba(0,0,0,0.2) 1px;

`


  
  
const Header = () => 
 

    <>

 <BG>
 <Left>
     <Link to="/"><WordMark alt="Human 8 home"/></Link>
     <Make>To Make Human</Make>
    
     
       
       </Left>
   
     </BG>
     
    
    
 
  
</>


export default Header
