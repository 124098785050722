import React from "react"
import { Router, Redirect } from "@reach/router"
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import styled from "styled-components";
import Settings from "../../components/dashboard/settings";
import Home from "../../components/dashboard/home";
import Verified from "../../components/dashboard/verified";
import Thankyou from "../../components/dashboard/thankyou";
import Redirecting from "../../components/dashboard/redirecting";
import GetHelp from "../../components/dashboard/gethelp";
import PaymentTest from "../../components/dashboard/payment_test";
import Canceled from "../../components/dashboard/canceled"

const Spacer = styled.div `
height: 100px;
`

/*function CapitalizeFirstLetter (str) {
  return str.length ? str.charAt(0).toUpperCase() + str.slice(1) : str
}*/





const Account = () => {
 
  const { user, getAccessTokenSilently, isLoading } = useAuth0();
    
    getAccessTokenSilently({ ignoreCache: true });
    const NotFound = () => <p>Sorry, nothing here</p>

  return (

    
    <>
   

      




      <Router>
        <Home path="/nameflow/welcome" />
        <GetHelp path="/nameflow/help" />
        <Verified path="/nameflow/verified" />
        <Thankyou path="/nameflow/thankyou" />
        <PaymentTest path="/nameflow/payment_test" />
        <Canceled path="/nameflow/canceled" />
        <NotFound default />
        
       
        
      </Router>
    </>
  )
}

export default withAuthenticationRequired(Account, {
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: () => <Redirecting />,
});