import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import media from "../styles"
import { useAuth0 } from "@auth0/auth0-react";





const Wrap = styled.div`
display: flex;
justify-content: center;
flex-direction: column;
align-items: start;
width: 100%;
padding: 100px 20px 100px 20px;
box-sizing: border-box;
color: rgba(255,255,255,1);
margin: auto;
background-color: white;
border-radius: 0;
max-width: 1200px;

  
  line-height: 23px;
  text-align: left;

  ${media.xsmall`
                
  padding: 100px 40px 100px 40px;
                             
              
                              `}
  
  h1 {

    font-family: 'AkzidenzGrotesk_Light';
    font-variant-ligatures: none;
    letter-spacing: 0px;
    white-space: pre-wrap;
font-size: 42px;
line-height: 56px;

font-weight: normal;
margin-bottom: 60px;
color: rgba(0,0,0,0.9);
margin-top: 0;


${media.tablet`
               
margin-bottom: 36px;
                                            `}

 ${media.xlarge`
margin-left: -120px;
`}
                

  }

  h2 {

    font-family: 'AkzidenzGrotesk_Light';

font-size: 42px;
line-height: 56px;
white-space: pre-wrap;
font-weight: normal;
margin-bottom: 18px;
color: rgba(0,0,0,0.8);


  }

  p {


font-size: 18px;
line-height: 26px;
white-space: pre-wrap;
margin: 0 0 18px 0;


  }
  ${media.phone`
  text-align: left;
  align-items: flex-start;

                `}

  ${media.tablet`
  flex-direction: column;
  align-items: flex-start;
justify-content: center;
padding: 100px 100px 100px 100px;
flex-wrap: nowrap;
text-align: left;
//margin-bottom: 200px;


                `}

               

`


const Grid = styled.div`
display: grid;
grid-template-columns: repeat(1, 1fr);
grid-template-rows: repeat(400px);
grid-auto-rows: 400px;
align-items: stretch;
//width: 100%;

margin-right: auto;
justify-content: start;

column-gap: 30px;
row-gap: 30px;

${media.phone`
justify-content: start;
grid-template-columns: repeat(1, 1fr);

                `}


${media.tablet`
grid-template-columns: repeat(1, 1fr);
margin-left: auto;


                `}

                ${media.large`

margin-left: auto;
grid-template-columns: repeat(2, 1fr);
grid-template-rows: repeat(2, 500px);
align-items: stretch;
                `}

                ${media.xlarge`

margin-left: auto;
grid-template-columns: repeat(2, 1fr);


                `}


 
 h2 {
 font-family: 'AkzidenzGrotesk_Light_Bold';
    font-variant-ligatures: none;
    letter-spacing: -2px;
    white-space: pre-wrap;
 color: rgba(0,0,0,0.85);
 
  font-weight: normal;
  margin-bottom: 6px;
  ${media.phone`
  font-size: 72px;
  line-height: 72px;
  margin-bottom: 18px;

                `}
 }

 p {
  color: rgba(0,0,0,0.85);
  font-family: 'AkzidenzGrotesk_Light';
 }
 

  margin: auto;
  box-sizing: border-box;
  text-align: left;
  
}

`

const Opacity = styled.div`
opacity: 0.75;

`
const Blue = styled.div`
background-image: linear-gradient(to bottom right, white, #CBF6FF);
display: flex;
flex-direction: column;
justify-items: flex-start;
align-items: flex-start;
margin: 0;
padding: 48px;
padding-bottom: 24px;

`

const Yellow = styled.div`
background-image: linear-gradient(to bottom right, white, #FFF0A9);
display: flex;
flex-direction: column;
justify-items: flex-start;
align-items: flex-start;
margin: 0;
padding: 48px;
padding-bottom: 24px;

`

const Purple = styled.div`
background-image: linear-gradient(to bottom right, white, #C7B9FF);
display: flex;
flex-direction: column;
justify-items: flex-start;
align-items: flex-start;
margin: 0;
padding: 48px;
padding-bottom: 24px;
`

const Green = styled.div`
background-image: linear-gradient(to bottom right, white, #93f0ca);
display: flex;
flex-direction: column;
justify-items: flex-start;
align-items: flex-start;
margin: 0;
padding: 48px;
padding-bottom: 24px;
`

const Flex = styled.div`
margin-top: auto;
width: 100%;
text-align: right;

p {
  color: rgba(0,0,0,0.75);
}
`
  


const Products = () => {
 

  

    
  

  return (

    <>

 

   <Wrap>
     <h1>Product names we've created</h1>
       <Grid>

       <Yellow>
         <div>
       <h2>Aquera</h2>

       <p>Waterproof urban backpack</p>
       </div>
       <Flex><p>Lifestyle / Apparel</p></Flex>
     </Yellow>

    <Blue>
      <div>
     
     <h2>Everlastic</h2>
     <p>Anti-ageing liquid essence</p>
    </div>
    <Flex><p>Beauty & Personal Care</p>
      </Flex></Blue>


     <Purple>
       <div>
      <h2>Hyperlite RLX</h2>
      <p>Ultra-lightweight fly fishing rod</p>
     </div><Flex><p>Sports / Equipment</p>
      </Flex></Purple>

     <Green>
       <div>
     <h2>Wynx</h2>
     <p>Wireless smart-home links</p>
     </div>
     <Flex><p>Technology / Wifi</p>
      </Flex></Green>
    
    
    </Grid>
     
    </Wrap>
 
  
</>
  
  )

  }
export default Products
