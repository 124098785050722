
import { useAuth0 } from '@auth0/auth0-react'
import { PopupButton  } from '@typeform/embed-react'
import React, { useEffect, useState } from 'react';
var axios = require("axios").default;

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */



const Form = () => {

    
    const { user, getAccessTokenSilently } = useAuth0();
    
    var firstName = user.given_name
    var userId = user.sub
   

   


    
    const Click = async () => {

      try {
      const token = await getAccessTokenSilently();

      var Url = `https://nameflow.eu.auth0.com/api/v2/users/${userId}`

      var Bearer = `Bearer ${token}`
      
        var data_b = JSON.stringify({
          
          "user_metadata": { "brief": "completed" }
        });
      
        var config_b = {
          method: 'patch',
          url: Url,
          headers: { 
            authorization: Bearer, 
            'content-type': 'application/json', 
            
          },
          data : data_b
        };
      // call management api and update user profile with access token
      
        axios(config_b)
      .then(function (response) {

        getAccessTokenSilently({ ignoreCache: true });
        // console.log(JSON.stringify(response.data));
        
        
      })
      .catch(function (error) {
        console.log(error);
        
      });
    


      } catch (error) {
        console.error(error);
      }
    }






    return (

        
    <>

{user['https://my-app.example.com/brief'] != "completed" && 
        <PopupButton  id="z4qo4bic" style={{ width: '100%', borderRadius: '9999px', border: 'none', backgroundColor: 'rgb(29 155 240)', minHeight: '44px', color: 'white',
  marginTop: '28px',
  fontSize: '15px',
  fontWeight: 'bold', cursor: 'pointer'}} size={75} hidden={{
    first_name: firstName,
    user_id: userId
    
  }}
  onReady={() => {
    {/*console.log('form ready')*/}
  }}

  onSubmit={() => {
    Click()
  }}
  //hideFooter
  

  //enableSandbox
  
  
  >
    Start &#x270E;
    </PopupButton >
  }



{user['https://my-app.example.com/brief'] == "completed" && 
        <PopupButton  id="z4qo4bic" style={{ width: '100%', borderRadius: '9999px', border: 'none', backgroundColor: 'rgba(29, 155, 240, 0.25)', minHeight: '44px', color: 'white',
  marginTop: '28px',
  fontSize: '15px',
  fontWeight: 'bold', cursor: 'pointer'}} size={75} hidden={{
    first_name: firstName,
    user_id: userId
    
  }}
  onReady={() => {
    {/*console.log('form ready')*/}
  }}

  onSubmit={() => {
    Click()
  }}
  //hideFooter

  //enableSandbox
  >
    Start &#x270E;
    </PopupButton >
  }




    </>
    )
}

export default Form