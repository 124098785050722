import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import media from "../styles"
import { useAuth0 } from "@auth0/auth0-react";
import Board from "../../images/svg/approve.svg"
import Circle from "../../images/svg/promise_n.svg"
import { HomePageTextRotator } from "../homepagetextrotator"
import { Rotator } from "../homepagetextrotator copy"



const Continue = styled.button`
  min-height: 44px;
  padding: 0 40px 0 40px;
  border: none;
  width: auto;
  border-radius: 9999px;
  border: 1px solid black;
  color: #121212;
  margin-top: 16px;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  background-color: #E4FF6E;

  &:disabled {
    background-color: rgba(230, 255, 0, 1);
    cursor: not-allowed;
  }
`



const Image = styled.div`

width: 100%;
height: auto;

img {
  filter: opacity(105%) saturate(135%) blur(0px) drop-shadow(2px 2px 5px rgb(0 0 0 / 0.25)) contrast(110%) brightness(102%);
}
p {
  color: black !important;
  margin-top: 20px !important;
  font-size: 12px !important;
}
`

const ImageWrap = styled.div`

display: flex;
flex-direction: row;
justify-content: center:
align-items: center;
align-self: center;
max-width: 650px;
width: 100%;
margin-top: 40px;


${media.large`
                //margin-right: -100px;
                align-self: flex-start;
                margin-top: 0px;
                `}

                

`


const Whiteboard = styled(Board)`

width: 400px;
`
const Shift = styled.div`
margin: 0 auto 20px auto;
width: 150px;
`






const BackG = styled.div`
//background-color: #F0E2FC;
background-color: #E4FF6E;
width: 100%;
display: flex;
//border-bottom: 1px solid;
  //border-color: grey;
  border-bottom: 2px solid;
  border-top: 2px solid;
  border-color: rgb(239 243 244);
  color: white;
  padding: 50px 0 80px 0;
`

const Wrap = styled.div`
display: flex;
max-width: 1230px;
flex-direction: column;
align-items: center;
justify-content: center;
flex-wrap: wrap;
width: 100%;
padding: 0px 20px 100px 20px;
box-sizing: border-box;
flex-wrap: nowrap;

//max-width: 1200px;
margin: auto;
//margin-bottom: 35px;
//background-image: linear-gradient(-20deg, #b721ff 0%, #21d4fd 100%);
//margin-bottom: 100px;
//background-color: #EBEBF8;
//background-color: white;
//border: 2px solid;
  //border-color: rgb(239 243 244);
  //background-color: rgb(224 242 237);
  //background-color: #CBF6FF;

  //ackground: #78ffd6; /* fallback for old browsers */
//background: -webkit-linear-gradient(to right, #78ffd6, #a8ff78);  /* Chrome 10-25, Safari 5.1-6 */
//background: linear-gradient(to right, #78ffd6, #a8ff78); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

border-radius: 0;
  
  line-height: 23px;
  //margin: 28px 0 0 0;
  text-align: left;

  ${media.xsmall`
                
  padding: 0px 40px 0px 40px;
                             
              
                              `}
  
                              h2 {
font-family: 'AkzidenzGrotesk_Medium';
-webkit-font-smoothing: antialiased;
font-variant-ligatures: none;
letter-spacing: 0px;
margin-top: 0;
font-size: 14px;
line-height: 28px;
white-space: pre-wrap;
margin-bottom: 24px;
font-weight: normal;
color: #121212;
//border-left: 10px solid rgb(230, 255, 0);
//text-align: left;
text-transform: uppercase;

${media.phone`

text-align: center;




                `}


${media.tablet`
font-size: 15px;
line-height: 28px;

                `}

                ${media.large`

text-align: left;

                `}

  }

  {/*h2 {

    font-family: 'GoudyOldStyleRegular';
    
font-size: 22px;
letter-spacing: -1px;
//line-height: 30px;
white-space: pre-wrap;
//max-width: 300px;
//margin-bottom: 20px;
//color: rgb(38 38 39);
color: #121212;
text-align: center;
font-weight: normal;

${media.phone`

text-align: center;
max-width: none;




                `}

${media.tablet`
font-size: 24px;
line-height: 32px;
margin-bottom: 32px;
width: auto;
max-width: none;

                `}
                ${media.large`

text-align: left;
                `}

  }
  */}

  p {


font-size: 18px;
line-height: 26px;
white-space: pre-wrap;
margin: 0 0 48px 0;

${media.large`

text-align: left;

                `}


  }

  ul {
    
    
    margin: 20px 0 0 0;
    
  list-style: none;
  padding: none;
  -webkit-padding-start: 0;
 //margin: 0;
  text-align: center;
  font-size: 14px;
}

  ${media.tablet`
  
align-items: center;
justify-content: center;
padding: 0px 20px 0px 20px;
//flex-wrap: nowrap;
text-align: center;
//margin-bottom: 200px;


                `}

                ${media.large`
                //border-radius: 16px;
                flex-direction: row;
                justify-content: space-between;
                align-items: stretch;
                text-align: left;
                `}


`


const Italic = styled.span `
font-style: italic;

`




const Width = styled.div `
width: 100%;

`



const Tag = styled.span`

background-color: #FF8C78;
padding: 2px 5px 2px 5px;
color: rgba(0,0,0,0.8);
border-radius: 4px;
font-size: 13px;
font-weight: bold;
margin-left: 2px;


`

const Underline = styled.span`
text-decoration: underline 2px solid yellow;
text-decoration-skip-ink: none;

`


const Grid = styled.div`

display: flex;
flex-direction: column;
justify-content: center;
align-items: start;
p{
  margin-bottom: 60px;
}

${media.desktop`
  flex-direction: row;
  padding-left: 100px;
  p{
  margin-bottom: 24px;
 
}



                `}

`

const Item = styled.div`

display: flex;
flex-direction: column;
justify-content: center;
align-items: start;
max-width: 300px;
border-left: 1px solid #f2f2f2;
padding-left: 24px;
position: relative;

h3 {
  
  margin: 0;
  padding: 0;
  font-size: 28px;
  font-family: 'AkzidenzGrotesk_Bold';
  letter-spacing: -1px;
  white-space: pre-wrap;
  text-align: left;
  line-height: 36px;
}

p{
  text-align: left;
  width: 75%;
  margin-top: 24px;
  font-size: 16px;
  line-height: 24px;
  
}

`



const Title = styled.div`

display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;
//height: 100%;
//flex-grow: 1;


//margin-bottom: 80px;
width: 100%;

${media.large`
 width: 40%;
 padding-right: 60px;
 justify-content: space-between;
align-items: start;


                `}



{/*
h2{
  font-size: 16px;
  font-family: 'AkzidenzGrotesk_Regular';
  letter-spacing: -0.3px;
  white-space: pre-wrap;
 margin: 0;
 margin-bottom: 16px;
  line-height: 26px;
  color: rgba(0,0,0,0.5);
}
*/}

h3 {

  font-size: 48px;
  font-family: 'AkzidenzGrotesk_Bold';
  letter-spacing: -2px;
  margin: 0;
  margin-bottom: 48px;
  
 
  line-height: 56px;

}

p {

  font-size: 44px;
  line-height: 52px;
  letter-spacing: -0.5px;
  max-width: 425px;
  height: 400px;
  width: 100%;
  font-family: 'AkzidenzGrotesk_Light_Bold';
  margin-bottom: 24px;
  -webkit-font-smoothing: antialiased;
font-variant-ligatures: none;
color: rgba(0,0,0,0.9);
//margin-left: 48px;
}


`



const Highlight = styled.span`

background-color: #CBF6FF;
padding: 2px 5px 2px 5px;
color: rgba(0,0,0,0.8);
border-radius: 4px;


font-size: 12px;
line-height: 20px;

`

const Goudy = styled.span`

font-family: 'GoudyOldStyleItalic';
font-size: 82px;
`

const Normal = styled.span`

font-family: 'AkzidenzGrotesk_Medium';
color: rgba(0,0,0,0.6);

`
  
  
const Green = () => (
 
<StaticQuery
  query={graphql`
    query {
      placeholderImage: file(relativePath: { eq: "man.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            aspectRatio
            ...GatsbyImageSharpFluid
          }
          
        }
      }
    
      woman: file(relativePath: { eq: "birds.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            aspectRatio
            ...GatsbyImageSharpFluid
          }
          
        }
      }

    


    }

    
  `}

  render={data => (
  
 

    <>

 
<BackG>
   <Wrap>
     <Title>
      
       
    <h2>What we're thinking about</h2>
      <Rotator />
      <Link to="#contact"><Continue>Let's talk</Continue></Link>
           
       
           
       
       
     </Title>
     <ImageWrap>
     
     <Image>
     <Img fluid={data.woman.childImageSharp.fluid} alt=""/><p>Photo by Agostina Schenone</p>
    </Image>
    
    
    </ImageWrap>
     
     
    </Wrap>
 </BackG>
  
</>
 )}

 />

 )

export default Green
