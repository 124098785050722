import React from 'react';
import ReactTextRotator from 'react-text-rotator';
import { Arrow, FullHeight, RotatorWrapper, Title, BlockContent, Highlight, MenuLink, HomePageBlock } from "../components/homepageblock";

const content = [
  {
    text: "How can our brand, products and services be more human-friendly?",
    className: 'classA',
    animation: 'fade',
  },
  {
    text: 'Relationships and experiences are the new advertising.',
    className: 'classB',
    animation: 'fade',
  },
  {
    text: 'Stop interrupting what people are interested in. Become what people are interested in.',
    className: 'classC',
    animation: 'fade',
  },
  {
    text: "Would your customers miss your business if it didn't exist tomorrow?",
    className: 'classD',
    animation: 'fade',
  },
  {
    text: 'Our business is changing, our brand needs to keep up.',
    className: 'classE',
    animation: 'fade',
  },

  {
    text: "There's a better way to do it — find it.",
    className: 'classF',
    animation: 'fade',
  },

  {
    text: "Success doesn't exist without creativity.",
    className: 'classG',
    animation: 'fade',
  },

  {
    text: "Traditional problem-solving creates traditional solutions. Traditional doesn't work.",
    className: 'classH',
    animation: 'fade',
  },

  {
    text: "How can we de-risk ideas and innovation?",
    className: 'classI',
    animation: 'fade',
  },

  {
    text: "Marketing plans require more sophistication than ever before.",
    className: 'classJ',
    animation: 'fade',
  },

  {
    text: "Can we be culturally relevant in foreign markets?",
    className: 'classK',
    animation: 'fade',
  },

  {
    text: "Brands with purpose, meaning and relevance grow faster.",
    className: 'classL',
    animation: 'fade',
  },

  {
    text: "How do we build an in-house creative team?",
    className: 'classM',
    animation: 'fade',
  },

  {
    text: "Good enough isn't good enough.",
    className: 'classN',
    animation: 'fade',
  },

];

const HomePageTextRotator = () => (
  
    <div>
       
           
           <HomePageBlock 
            title="TRANSFORMATIVE, BY DESIGN"
            blockcontent={<Highlight><ReactTextRotator
                            content={content}
                            time={4000}
                            startDelay={0}
                            transitionTime={200}
                            /></Highlight>}
            /*menulink={<div><a href="mailto:growth@human-8.com">Let's Talk<Arrow> →</Arrow></a></div>}
            displaylink="yes"*/
                    
                        
                    
        />
        

    </div>
);

export { content, HomePageTextRotator }