import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"
import { StaticQuery, graphql } from "gatsby"
//import Img from "gatsby-image"
import styled from "styled-components"
import media from "../styles"
import Circle from "../../images/brand/nameflow.svg"
import H8 from "../../images/brand/logo_color_clean_raster.svg"
import { useAuth0 } from "@auth0/auth0-react";
import Flow from "../../images/nflowfav.svg"

const Nflow = styled(Flow)`

height: 50px;

width: auto;
margin-top: 50px;




${media.tablet`
height: 100px;
margin-left: auto;
margin-top: auto;
margin-bottom: auto;

                `}
`





const Left = styled.div`
display: flex;
flex-direction: column;
align-items: start;
justify-content: start;
width: 100%;
padding: 50px 20px 50px 20px;
box-sizing: border-box;
color: rgba(0,0,0,0.95);
max-width: 1200px;
margin: auto;
height: auto;
border-top: 2px solid;
  border-color: rgb(239 243 244);
  line-height: 23px;
  //margin: 28px 0 0 0;
  text-align: left;


  ${media.xsmall`
                
  padding: 50px 35px 50px 35px;
                                           
                            
                                            `}

  ${media.tablet`
  flex-direction: row;
align-items: start;
justify-content: start;
padding: 50px 35px 70px 35px;
//height: 500px;

                `}

P {
  font-size: 13px;
  margin-bottom: 0;
}

`


const Gap = styled.div`
p {
  margin-top: 50px;
  font-size: 12px;
  margin-bottom: 0;
}

`

const Tag = styled.div`
p {
  margin-top: 7px;
  font-size: 16px;

}

`



const CircleMark = styled(Circle)`
//width: auto;
//width: 115px;
//width: 125px;
//top: -320px;
//left: -90px;
//margin-bottom: 50px;
width: 160px;
height: auto;
fill: #131313;
opacity: 0.95;
shape-rendering: geometricPrecision;


${media.desktop`
//width: 250px;

width: 160px;

                `}

`

const H8Mark = styled(H8)`
//width: auto;
//width: 115px;
//width: 125px;
//top: -320px;
//left: -90px;
//margin-bottom: 50px;
width: 70px;
height: auto;
//fill: #131313;
opacity: 0.95;
shape-rendering: geometricPrecision;
//margin-right: 7px;
//margin-top: -3.5px;
margin: 0 1px -2px 2px;

${media.desktop`
//width: 250px;

width: 70px;

                `}

`

const Italic = styled.span`
font-family: 'AkzidenzGrotesk_Italic';
`




const Intro = styled.div`
font-family: 'AkzidenzGrotesk_Regular';
font-size: 23px;
letter-spacing: -0.01em;
line-height: 1.6em;
color: rgba(0,0,0,0.65);
`

const Flex = styled.div`
display: flex;
flex-direction: column;
justify-content: start;
align-items: start;
`
  
  
const Footer = () => 
 
 /* <StaticQuery
  query={graphql`
    query {
      fileName: file(relativePath: { eq: "gatsby-atrsonaut.png" }) {
        childImageSharp {
         fluid(maxWidth: 400, maxHeight: 250) {
            ...GatsbyImageSharpFluid
        }
      }
    }
    }
  `}


  render={data => */

    <>


 <Left> <Flex>
     <CircleMark /><Tag><p><b>brand & product naming</b></p></Tag>
     <p>a <a href="https://www.human-8.com" target="blank"><H8Mark /></a> company</p>


     <p>© Nameflow UK 2022<br />
    {/*91762 Mount Pleasant Avenue, London W1A 6US<br />*/}
    Need help? Contact nameflow@human-8.com</p>

   
     
    {/*<Gap><p>from</p></Gap><H8Mark />*/}
    
    
     </Flex>
    
       <Nflow />
       </Left>
  
     
     
      {/*<Intro>
      <Mark />is a brand & product naming lab based in Taipei, Taiwan. We also create human-friendly, <Italic>purpose-driven</Italic> brand stories & messaging. Feel free to get in touch, from anywhere in the world. &#127758;
      </Intro>*/}

      {/*<Menu>
        APPROACH&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;HIGHLIGHTS&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;CONNECT
      </Menu>*/}
   
 
  
</>


export default Footer
