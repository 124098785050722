import styled from "styled-components"
import media from "./styles"

const ContentWrapper = styled.div`
margin-top: 2rem;

${media.desktop`margin-top: 2rem;`}
width:auto;
`

const MainText = styled.div`

p {
    font-size: 1.35rem;
    margin-right: 0.75rem;
    opacity: 0.85;
    color: black;
    ${media.desktop`font-size: 3.5rem;
                margin-right: 8rem;`}
    line-height: 1.4;
    margin-bottom: 1.5em;
    :last-of-type {
        margin-bottom:0;
    }
 
}

h1 {
    opacity: 0.85;
    color: black;
    font-size: 1rem;
    margin-top: 4em;
    ${media.desktop`font-size: 2rem;`}
    
    text-decoration: none;
    
}

a {
    text-decoration: none;
}

`

export { ContentWrapper, MainText }