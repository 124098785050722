import React from "react"
import { useAuth0 } from '@auth0/auth0-react';
import BriefSuccess from "../briefsuccess";

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const Settings = () => {
    const { user, getAccessTokenSilently } = useAuth0();
    getAccessTokenSilently({ ignoreCache: true });

    return (
    <>
        Settings
    </>
    )
}

export default Settings