import React from "react"
import styled, { keyframes } from "styled-components"
import { useAuth0 } from '@auth0/auth0-react';
import CheckoutDemo from "../checkoutdemo";


/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const Card = styled.div`
 
  background-color: rgb(247 249 249);
  border-radius: 16px;
  padding: 32px;
  color: rgba(0,0,0,0.95);
  font-family: -apple-system, BlinkMacSystemFont, “Segoe UI”, Roboto, Helvetica, Arial, sans-serif;
  //display: flex;
  //flex-direction: row;
  //align-items: center;
  //justify-content: center;
  margin-bottom: 16px;
  
  
  h2 {
    font-weight: 900;
  font-size: 28px;
  line-height: 28px;
  }

  h3 {
    font-weight: 900;
  font-size: 23px;
  line-height: 28px;
  margin: 0;
  }

  p {
    font-weight: normal;
  font-size: 15px;
  line-height: 23px;
  margin: 8px 0 0 0;
  }

`

const Yellow = styled.span`
color: rgb(251, 206, 55);
padding-right: 15px;
font-size: 38px;
`

const Button = styled.button`

  min-height: 44px;
  border: none;
  width: 100%;
  border-radius: 9999px;
  background-color: rgb(29 155 240);
  color: white;
  margin-top: 28px;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;

`


const Title = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;

p {

  margin: 0;
}
`
const fadeIn = keyframes`
  from {
    background-color: rgba(255,255,255,1);
  }

  to {
    background-color: rgb(203 244 201);
  }
`

const slideIn = keyframes`
  from {
    margin-left: -7px;
    opacity: 0;
  }

  to {
    margin-left: -4px;
    opacity: 1;
  }
`

const BriefComplete = styled.span`

background-color: rgb(203 244 201);
padding: 2px 5px 2px 5px;
color: rgb(14 98 69);
border-radius: 4px;
margin-left: -4px;
animation: ${fadeIn} 0.5s ease-out;
animation: ${slideIn} 0.5s ease-out;
animation-iteration-count: 1;
font-size: 12px;
font-weight: bold;

`



const BriefInComplete = styled.span`


padding: 2px 5px 2px 5px;
color: rgb(14 98 69);
border-radius: 4px;
margin-left: -4px;
font-size: 12px;

`


const Highlight = styled.span`

background-color: #FF8C78;
padding: 2px 5px 2px 5px;
color: rgba(0,0,0,0.8);
border-radius: 4px;

//margin-left: 2px;

`
const Strikethrough = styled.span`




text-decoration-line: line-through;
text-decoration-style: solid;
text-decoration-thickness: 1px;
-webkit-text-decoration-line: line-through;
-webkit-text-decoration-style: solid;
-webkit-text-decoration-thickness: 1px;



`

const OfferDemo = () => {
  

  const { user } = useAuth0()
 
  const Incomplete = "Complete your one-time payment and don't miss out \u2192 ✨"
  const Complete = 'Paid'

  return (
    
      <>
        <Card>
          <Title>
        <h3>You've been upgraded &#127873;</h3>
        <p>{user['https://my-app.example.com/status'] == "paid" ? <BriefComplete>{Complete}</BriefComplete> : <BriefInComplete></BriefInComplete>}</p>
        </Title>

        <div>
        <p>✨ You've got an additional two name ideas included at no extra cost. You'll receive <Strikethrough>three</Strikethrough> <Highlight><b>five</b></Highlight> name ideas. {user['https://my-app.example.com/status'] != "paid" && Incomplete}</p>
        </div>

        <div>
          
        {user['https://my-app.example.com/status'] != "paid" && <CheckoutDemo /> }
        
        </div>

        </Card>
      </>
    )
  
}

export default OfferDemo
