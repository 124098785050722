import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"
import { StaticQuery, graphql } from "gatsby"
//import Img from "gatsby-image"
import styled from "styled-components"
import media from "../styles"
import Word from "../../images/brand/nameflow.svg"
import Circle from "../../images/brand/nflowfav.svg"
import { useAuth0 } from "@auth0/auth0-react";


const SignUpButton = () => {
  const { loginWithRedirect } = useAuth0();

  return <SignUp onClick={() => loginWithRedirect({screen_hint:"signup"})}>Sign up</SignUp>;
};

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return <Login onClick={() => loginWithRedirect({screen_hint:"login"})}>Log in</Login>;
};



const BG = styled.div`
width: 100%;
background-color: rgb(247 249 249);
//position: sticky;
//-webkit-position: sticky;
//top: 0px;
//-webkit-top: 0px;
z-index: 1;
`



const SignUp = styled.button`
font-family: -apple-system, BlinkMacSystemFont, “Segoe UI”, Roboto, Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 14px;
//background: #A6FF00;
background-color: rgb(29 155 240);
//background-color: #CBF6FF;
border: none;
border-radius: 9999px;
padding: 6px 0 6px 0;
//min-height: 44px;

width: 70px;
cursor: pointer;
//letter-spacing: -0.01em;


//color: #FF0063;
color: rgba(255,255,255,1);
//color: rgba(0,0,0,0.9);
${media.xsmall`
//width: 250px;
padding: 10px 0 10px 0;
min-height: 44px;
width: 100px;

                `}

`


const Login = styled.button`
font-family: -apple-system, BlinkMacSystemFont, “Segoe UI”, Roboto, Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 14px;
//background: #A6FF00;
background: none;
border: 1px solid rgba(0,0,0,0.5);
border-radius: 9999px;
padding: 5px 0 5px 0;
//min-height: 44px;

width: 70px;
margin-left: 15px;
cursor: pointer;
color: rgba(0,0,0,0.95);
  
//letter-spacing: -0.01em;
//color: #FF0063;
color: rgba(0,0,0,0.9);

${media.xsmall`
//width: 250px;
padding: 10px 0 10px 0;
min-height: 44px;
width: 100px;

                `}

`


const Left = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: flex-end;
width: 100%;
padding: 25px 20px 30px 20px;
box-sizing: border-box;
color: rgba(0,0,0,0.95);
max-width: 1200px;
margin: auto;
  
  line-height: 23px;
  //margin: 28px 0 0 0;
  text-align: center;


  ${media.desktop`
  flex-direction: row;
align-items: center;
justify-content: flex-end;

                `}



`

const Menu = styled.div`
font-family: 'AkzidenzGrotesk_Light';
font-size: 16px;
letter-spacing: 0.05em;
color: rgba(0,0,0,0.9);
`

const Bar = styled.div`
display: grid;
grid-template-columns: 100fr;
grid-template-rows: auto;
row-gap: 25px;
column-gap: 25px;
//background: #eae8e4;
//background: #f8f6f1;
width: auto;



padding: 25px 25px 10px 25px;

${media.desktop`
//grid-template-columns: 50% 50%;
//grid-template-columns: minmax(min-content, min-content) 25fr 25fr 25fr;
grid-template-columns: 25fr 25fr 25fr 25fr;
column-gap: 0px;
row-gap: 25px;
column-gap: 25px;
//width: 950px;
width: auto;
//max-width: 1360px;
max-width: 1060px;
margin: 50px auto 20px auto;
padding: 25px 25px 25px 25px;
align-items: stretch;
justify-content: stretch;

                `}

`



const WordMark = styled(Word)`

fill: #131313;
opacity: 0.95;
shape-rendering: geometricPrecision;
margin-right: auto;
display: none;


${media.tablet`
display: initial;
width: 110px;

                `}

`

const CircleMark = styled(Circle)`

fill: #131313;
opacity: 0.95;
shape-rendering: geometricPrecision;
margin-right: auto;
transform: translate(0, -2px);




display: initial;
width: 50px;

                

                ${media.tablet`
                display: none;

                `}

`

const Italic = styled.span`
font-family: 'AkzidenzGrotesk_Italic';
`

const Wrap = styled.div`
grid-column-start: 2;
grid-column-end: 5;
justify-self: center;
align-self: center;

`

const Intro = styled.div`
font-family: 'AkzidenzGrotesk_Regular';
font-size: 23px;
letter-spacing: -0.01em;
line-height: 1.6em;
color: rgba(0,0,0,0.65);
`


  
  
const Header = () => 
 
 /* <StaticQuery
  query={graphql`
    query {
      fileName: file(relativePath: { eq: "gatsby-atrsonaut.png" }) {
        childImageSharp {
         fluid(maxWidth: 400, maxHeight: 250) {
            ...GatsbyImageSharpFluid
        }
      }
    }
    }
  `}


  render={data => */

    <>

 <BG>
 <Left>
     <WordMark />
     <CircleMark />
     <SignUpButton />
     <LoginButton />
       
       </Left>
   
     </BG>
     
      {/*<Intro>
      <Mark />is a brand & product naming lab based in Taipei, Taiwan. We also create human-friendly, <Italic>purpose-driven</Italic> brand stories & messaging. Feel free to get in touch, from anywhere in the world. &#127758;
      </Intro>*/}

      {/*<Menu>
        APPROACH&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;HIGHLIGHTS&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;CONNECT
      </Menu>*/}
    
 
  
</>


export default Header
