import React, { useState, useEffect } from 'react';
import jwt_decode from "jwt-decode";
import ReactDOM from "react-dom";
import styled, { keyframes } from "styled-components"
import Logo from "../images/brand/nameflow.svg"
import { Spinner8 } from '@styled-icons/icomoon/Spinner8'
import { CheckmarkCircle } from '@styled-icons/fluentui-system-regular/CheckmarkCircle'
import media from "../components/styles"
import SEO from "../components/seo"

var axios = require("axios").default;
const isBrowser = typeof window !== "undefined"


const Light = styled.div`

color: rgba(0,0,0,0.35);
margin-bottom: 12px;


`


const fadeIn = keyframes`
  from {
    opacity: 0;
    height: 20px;
  }
  to {
    opacity: 1;
    height: 25px;
  }
`

const Success = styled(CheckmarkCircle)`

height: 25px;
color: rgb(14 98 69);
animation: 0.25s ease-in ${fadeIn};

`

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`



const Spinner = styled(Spinner8)`

height: 25px;
color: rgba(29, 155, 240, 1);

animation: 1.25s linear ${spin} infinite;
`

const Continue = styled.button`
  min-height: 44px;
  border: none;
  width: 100%;
  border-radius: 9999px;
  color: white;
  margin-top: 16px;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  background-color: rgb(29 155 240);

  &:disabled {
    background-color: rgba(29, 155, 240, 0.25);
    cursor: not-allowed;
  }
`
const Input = styled.input.attrs({ 
  type: 'text',
  
})`

background: white;
border-radius: 4px;
width: 100%;
border: 0.5px solid rgba(0,0,0,0.1);
padding: 12px 20px;

font-size: 15px;
box-sizing: border-box;

&:focus {
  box-shadow:0 0 0 3px rgba(29, 155, 240, 0.5);
  outline: none;
  box-sizing: border-box;
  

}


`
const Spacer = styled.div`

height: 10px;
`
const Mark = styled(Logo)`
width: 85px;
//margin: 30px 200px 10px 220px;
height: auto;
opacity: 0.9;

${media.desktop`
width: 125px;
`}

`

const Background = styled.div`
height: 100%;
background: white;
`

const Grid = styled.div`
//padding: 50px 0 0 0;
//margin-left: -15px;


justify-items: start;
height: 80vh;

display: grid;
align-items: start;

${media.tablet`
grid-template-columns: 100%;


//column-gap: 20px;
//padding: 50px;
`}
${media.desktop`
grid-template-columns: minmax(100px, 310px) 2fr;

//column-gap: 20px;
//padding: 50px;
`}
${media.large`
grid-template-columns: minmax(100px, 370px) 2fr;

//column-gap: 20px;
//padding: 50px;
`}
${media.xlarge`
grid-template-columns: minmax(100px, 470px) 2fr;

//column-gap: 20px;
//padding: 50px;
`}

`

const Left = styled.div`

top: 0;
  
  width: 100%;
  display: flex;
  flex-direction: row;
  
  align-items: center;
  justify-content: start;
  background-color: rgb(247 249 249);
  padding: 15px 20px 20px 20px;
  box-sizing: border-box;



  ${media.desktop`
  top: 0;
  height: 100vh;
  width: 100%;
  
  flex-direction: column;
  padding: 65px 0 50px 0;
  align-items: center;
  justify-content: center;
`}

`

const Center = styled.div`

display: flex;
align-items: center;
justify-content: center;

${media.desktop`
align-self: center;
`}

`

const Card = styled.div`
background-color: white;
font-smooth: always;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-radius: 16px;
  padding: 32px;
  color: rgba(0,0,0,0.95);
  font-family: -apple-system, BlinkMacSystemFont, “Segoe UI”, Roboto, Helvetica, Arial, sans-serif;
  h2 {
    font-weight: 700;
  font-size: 28px;
  line-height: 28px;
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  }

  h3 {
    font-weight: 900;
  font-size: 22px;
  line-height: 28px;
  margin: 0;
  }

  p {
    font-weight: normal;
  font-size: 15px;
  line-height: 23px;
  margin: 0 0 0 0;
  
  }

  label {
    font-size: 12px;
    position: relative;
    vertical-align: middle;
    bottom: 1.25px;

  }

`

const buttonDisabledStyles = {
  backgroundColor: "rgba(29, 155, 240, 0.25)",
  cursor: "not-allowed",
  
}

const buttonStyles = {
 
  
  
}

const buttonSuccessStyles = {
  opacity: "1",
  backgroundColor: "rgb(203 244 201)",
  cursor: "not-allowed"
  
}



const Onboard = () => {


  
  var userID = {}
    
    // decode jwt and get user id

    const urlParams = new URLSearchParams(window.location.search);
    const jwt = urlParams.get('session_token');
   
    var token = jwt;
    var decoded = jwt_decode(token);

    userID = decoded.sub
    
    console.log(decoded);

    var Client_id = process.env.GATSBY_AUTH0_CLIENTID_BACKEND
var Client_secret = process.env.GATSBY_AUTH0_CLIENTID_BACKEND_SECRET
     
    // call management api and get access token
   
    var axios = require('axios');
var qs = require('qs');
var data = qs.stringify({
  'grant_type': 'client_credentials',
  'client_id': Client_id,
  'client_secret': Client_secret,
  'audience': 'https://nameflow.eu.auth0.com/api/v2/' 
});
var config = {
  method: 'post',
  url: 'https://nameflow.eu.auth0.com/oauth/token',
  headers: { 
    'content-type': 'application/x-www-form-urlencoded', 
    
  },
  data : data
};

const [post, setPost] = React.useState(null);
const [inputs, setInputs] = useState({consent: true}); // get form inputs & validate
//loading state
const [loading, setLoading] = React.useState({isLoading: false, success: false});


const onChange = (event) => {
  
  const name = event.target.name;
  const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
  const shouldSetValue = value.length < 20
  
  if (shouldSetValue) setInputs(values => ({...values, [name]: value}))
}

const onBoxChange = (event) => {
  
  const name = event.target.name;
  const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
  setInputs(values => ({...values, [name]: value}))
}

useEffect(() => {
axios(config)
.then(function (response) {
  
    setPost(response.data);
    
})
.catch(function (error) {
  console.log(error);
});
}, []);

if (!post) return null;
if (!loading) return null;

const ac_tok = post.access_token
console.log(post.access_token)

// Sentence case first name on form post

function CapitalizeFirstLetter (str) {
  return str.length ? str.charAt(0).toUpperCase() + str.slice(1) : str
}

// get data to call management api on click

var Url = `https://nameflow.eu.auth0.com/api/v2/users/${userID}`

var Bearer = `Bearer ${ac_tok}`

// get state parameter for destination url

const URLParams = new URLSearchParams(window.location.search);
  const state = URLParams.get('state');




// click handler

function handleClick(e) {

  e.preventDefault()
  setLoading({isLoading: true})
  var givenName = CapitalizeFirstLetter(inputs.firstName)

  var consent = inputs.consent

  var data = JSON.stringify({
    "given_name": `${givenName}`,
    "user_metadata": { "consent": `${consent}`}
  });

  var config = {
    method: 'patch',
    url: Url,
    headers: { 
      authorization: Bearer, 
      'content-type': 'application/json', 
      
    },
    data : data
  };
// call management api and update user profile with access token

  axios(config)
.then(function (response) {
  // console.log(JSON.stringify(response.data));
  setLoading({isLoading: false, success: true})
  // then pass state into return url
  window.location.href = `https://nameflow.eu.auth0.com/continue?state=${state}`;
})
.catch(function (error) {
  setLoading({isLoading: false})
  console.log(error);
});


}





    return (
<>
<Background>
<SEO title="Onboard | Nameflow" description="Human-crafted, meaningful names for your business within three days." favicon="/nflowfav.svg" applepng="/nflowfavicon.png" keywords={[`company names`, `business names`, `brand names`]} />
      <Grid>
        <Left><Mark /></Left>
        <Center>
        <Card>
    
   


      <form onSubmit={handleClick}>
       

        {/*<div>Input value: {inputs.firstName}</div>
        <div>Input value: {inputs.consent ? "true" : "false"}</div>*/}
        <h2><Light>Welcome.</Light></h2>
        <h2>What's your first name?</h2>
        <Spacer /><Spacer /> 
     <p><Input type="text" name="firstName" placeholder ="First name" value={inputs.firstName || ""} onChange={onChange} /></p>
      
     <Spacer /> <Spacer /><Spacer /><Spacer />
      
      <p> <input type="checkbox" name="consent" id="consent" checked={inputs.consent} onChange={onBoxChange} /> <label for="consent"> Get occasional emails from Nameflow about news, updates and features. Unsubscribe anytime. </label></p>
       <Continue disabled={!inputs.firstName} style={ loading.isLoading ? buttonDisabledStyles : loading.success ? buttonSuccessStyles : buttonStyles }
                                                    
                                                    
                                                    type="submit">{ loading.isLoading ? <Spinner /> : loading.success ? <Success /> : "Continue" }
                                                                  </Continue>
     
       
      </form>
      
      
      
      
      
      </Card></Center></Grid></Background>
        </>
    )
  }
  export default Onboard