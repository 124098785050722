import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"
import { StaticQuery, graphql } from "gatsby"
//import Img from "gatsby-image"
import styled from "styled-components"
import media from "../styles"
import Word from "../../images/brand/logo.svg"
import H8 from "../../images/brand/logo_color_clean_raster.svg"
import { useAuth0 } from "@auth0/auth0-react";
import Flow from "../../images/brand/eight.svg"

const BackG = styled.div`
//background-color: #F0E2FC;
background-color: #E4FF6E;
width: 100%;
padding: 120px 0 0px 0;

  border-top: 2px solid;
  border-color: rgb(239 243 244);

`

const Make = styled.div`
font-family: 'GoudyOldStyle';
font-size: 16px;
letter-spacing: -0.01em;
line-height: 1.6em;
color: rgba(0,0,0,0.65);
//padding: 4.5px 0px 0 16px;
margin-top: 17px;
//border-top: solid rgba(0,0,0,0.2) 1px;

`

const Eight = styled(Flow)`

height: 100px;

width: auto;
//margin-top: 50px;
display: none;



${media.tablet`
height: 100px;
margin-left: auto;
margin-top: auto;
margin-bottom: auto;
display: inherit;

                `}
`





const Left = styled.div`

display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-end;
width: 100%;
padding: 50px 20px 50px 20px;
box-sizing: border-box;
color: rgba(0,0,0,0.95);
max-width: 1300px;
margin: auto;
height: auto;
//border-top: 2px solid;
  border-color: rgb(239 243 244);
  line-height: 23px;
  //margin: 28px 0 0 0;
  text-align: left;


  ${media.xsmall`
                
  padding: 50px 40px 50px 40px;
                                           
                            
                                            `}

  ${media.tablet`
  flex-direction: row;
align-items: end;
justify-content: space-between;
padding: 50px 20px 50px 20px;
//height: 500px;

                `}

P {
  font-size: 13px;
  margin-bottom: 0;
}

`


const Gap = styled.div`
p {
  margin-top: 50px;
  font-size: 12px;
  margin-bottom: 0;
}

`

const Tag = styled.div`
p {
  margin-top: 7px;
  font-size: 16px;

}

`



const WordMark = styled(Word)`
//width: auto;
//width: 115px;
//width: 125px;
//top: -320px;
//left: -90px;
//margin-bottom: 50px;
width: 160px;
height: auto;
fill: #131313;
opacity: 0.95;
shape-rendering: geometricPrecision;


${media.desktop`
//width: 250px;

width: 160px;

                `}

`

const H8Mark = styled(H8)`
//width: auto;
//width: 115px;
//width: 125px;
//top: -320px;
//left: -90px;
//margin-bottom: 50px;
width: 70px;
height: auto;
//fill: #131313;
opacity: 0.95;
shape-rendering: geometricPrecision;
//margin-right: 7px;
//margin-top: -3.5px;
margin: 0 1px -2px 2px;

${media.desktop`
//width: 250px;

width: 70px;

                `}

`

const Italic = styled.span`
font-family: 'AkzidenzGrotesk_Italic';
`




const Intro = styled.div`
font-family: 'AkzidenzGrotesk_Regular';
font-size: 23px;
letter-spacing: -0.01em;
line-height: 1.6em;
color: rgba(0,0,0,0.65);
`

const Flex = styled.div`
display: flex;
flex-direction: column;
justify-content: start;
align-items: center;
a {
  text-decoration: none;
  color: rgb(0 0 0 / 95%);
  line-height: 0;
  
}

${media.tablet`
flex-direction: column;
justify-content: start;
align-items: start;

                `}


`
  
  
const Footer = () => 
 
 /* <StaticQuery
  query={graphql`
    query {
      fileName: file(relativePath: { eq: "gatsby-atrsonaut.png" }) {
        childImageSharp {
         fluid(maxWidth: 400, maxHeight: 250) {
            ...GatsbyImageSharpFluid
        }
      }
    }
    }
  `}


  render={data => */

    <>
<BackG>

 <Left> <Flex>
 <Link to="/"><WordMark alt="Human 8 home"/></Link>
     


     <p>© Human 8 Ltd 2018-2023  | To Make Human  | <Link to="/privacy-policy">Privacy Policy</Link> | <Link to="#contact">Contact Us</Link></p>
     
    {/*91762 Mount Pleasant Avenue, London W1A 6US<br />*/}
   

   
     
    {/*<Gap><p>from</p></Gap><H8Mark />*/}
    
    
     </Flex>
    
     <Link to="/"><Eight alt="Human 8 home"/></Link>
       </Left>

       </BackG>
  
     
     
      {/*<Intro>
      <Mark />is a brand & product naming lab based in Taipei, Taiwan. We also create human-friendly, <Italic>purpose-driven</Italic> brand stories & messaging. Feel free to get in touch, from anywhere in the world. &#127758;
      </Intro>*/}

      {/*<Menu>
        APPROACH&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;HIGHLIGHTS&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;CONNECT
      </Menu>*/}
   
 
  
</>


export default Footer
