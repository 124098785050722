import styled from "styled-components"
import media from "./styles"

const FlexWrapper = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
align-items: flex-end;

`

export default FlexWrapper