import React from 'react';
import ReactTextRotator from 'react-text-rotator';
import { Arrow, FullHeight, RotatorWrapper, Title, BlockContent, Highlight, MenuLink, HomePageBlock } from "../components/homepageblock";
import styled from "styled-components"

const Slide = styled.div`
transition: all 2s ease-in;

`




const content = [
  {
    text: "How can our brand, products and services be more human-friendly?",
    className: 'classA',
    animation: 'fade',
  },
  {
    text: 'AI has changed everything.',
    className: 'classB',
    animation: 'fade',
  },
  {
    text: 'Stop interrupting what people are interested in. Become what people are interested in.',
    className: 'classC',
    animation: 'fade',
  },
  {
    text: "How can we empower human-driven change?",
    className: 'classD',
    animation: 'fade',
  },
  {
    text: "Would your customers miss your business if it didn't exist tomorrow?",
    className: 'classE',
    animation: 'fade',
  },

  {
    text: "Our business is changing, our brand needs to keep up.",
    className: 'classF',
    animation: 'fade',
  },

  {
    text: "Success doesn't exist without creativity.",
    className: 'classG',
    animation: 'fade',
  },

  {
    text: "Traditional problem-solving creates traditional solutions. Traditional doesn't work.",
    className: 'classH',
    animation: 'fade',
  },

  {
    text: "How can we de-risk ideas and innovation?",
    className: 'classI',
    animation: 'fade',
  },

  {
    text: "Marketing plans require more sophistication than ever before.",
    className: 'classJ',
    animation: 'fade',
  },

  {
    text: "Can we be culturally relevant in foreign markets?",
    className: 'classK',
    animation: 'fade',
  },

  {
    text: "Brands with purpose, meaning and relevance grow faster.",
    className: 'classL',
    animation: 'fade',
  },

  {
    text: "What does AI look like 10 years from now?",
    className: 'classM',
    animation: 'fade',
  },

  {
    text: "Good enough isn't good enough.",
    className: 'classN',
    animation: 'fade',
  },

];

const Rotator = () => (
  
    
       <Slide>
           <p>
           <ReactTextRotator
                            content={content}
                            time={4000}
                            startDelay={0}
                            transitionTime={200}
                            />
            
                 </p>   
                      </Slide>  
                    
       
        

    
);

export { content, Rotator }