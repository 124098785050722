import React, { useState, useEffect } from 'react';
import jwt_decode from "jwt-decode";
import ReactDOM from "react-dom";
var axios = require("axios").default;




  

const isBrowser = typeof window !== "undefined"





const OnboardConsent = () => {

  var userID = {}
    
    // decode jwt and get user id

    const urlParams = new URLSearchParams(window.location.search);
    const jwt = urlParams.get('session_token');
   
    var token = jwt;
    var decoded = jwt_decode(token);

    userID = decoded.sub
    
    console.log(decoded);
     
    // call management api and get access token
   
    var axios = require('axios');
var qs = require('qs');
var data = qs.stringify({
  'grant_type': 'client_credentials',
  'client_id': 'ez0GoeGOLINRFqUKrAFTrB5EVj5T4DKA',
  'client_secret': 'NuCec8clfK6o-kZXZFdlNFB5sO_wORG9wykOBAAiMtbFa3khK0r8SicCNRxsa0QN',
  'audience': 'https://dev-gtg-bmbe.us.auth0.com/api/v2/' 
});
var config = {
  method: 'post',
  url: 'https://dev-gtg-bmbe.us.auth0.com/oauth/token',
  headers: { 
    'content-type': 'application/x-www-form-urlencoded', 
    
  },
  data : data
};

const [post, setPost] = React.useState(null);
const [inputs, setInputs] = useState({consent: true}); // get form inputs & validate

const onChange = (event) => {
  
  const name = event.target.name;
  const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
  const shouldSetValue = value.length < 20
  
  if (shouldSetValue) setInputs(values => ({...values, [name]: value}))
}

const onBoxChange = (event) => {
  
  const name = event.target.name;
  const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
  setInputs(values => ({...values, [name]: value}))
}

useEffect(() => {
axios(config)
.then(function (response) {
  
    setPost(response.data);
    
})
.catch(function (error) {
  console.log(error);
});
}, []);

if (!post) return null;

const ac_tok = post.access_token
// console.log(post.access_token)

// Sentence case first name on form post

function CapitalizeFirstLetter (str) {
  return str.length ? str.charAt(0).toUpperCase() + str.slice(1) : str
}

// get data to call management api on click

var Url = `https://dev-gtg-bmbe.us.auth0.com/api/v2/users/${userID}`

var Bearer = `Bearer ${ac_tok}`

// get state parameter for destination url

const URLParams = new URLSearchParams(window.location.search);
  const state = URLParams.get('state');

// click handler

function handleClick(e) {

  e.preventDefault()

  //var givenName = CapitalizeFirstLetter(inputs.firstName)

  var consent = inputs.consent

  var data = JSON.stringify({
    //"given_name": `${givenName}`,
    "user_metadata": { "consent": `${consent}`}
  });

  var config = {
    method: 'patch',
    url: Url,
    headers: { 
      authorization: Bearer, 
      'content-type': 'application/json', 
      
    },
    data : data
  };
// call management api and update user profile with access token

  axios(config)
.then(function (response) {
  // console.log(JSON.stringify(response.data));

  // then pass state into return url
  window.location.href = `https://dev-gtg-bmbe.us.auth0.com/continue?state=${state}`;
})
.catch(function (error) {
  console.log(error);
});


}





    return (

        
        <div>
      <p>Onboard HELLO {decoded.email}</p>
      <p>{userID}</p>
      <p>{post.access_token}</p>


      <form onSubmit={handleClick}>
       

        <div>Input value: {inputs.firstName}</div>
        <div>Input value: {inputs.consent ? "true" : "false"}</div>
      
      <input type="checkbox" name="consent" checked={inputs.consent} onChange={onBoxChange} />
      
      
        {/*<button disabled={!inputs.firstName} type="submit">GO</button>*/}
        <button type="submit">GO</button>
       
      </form>
      
      
      
      
      
      </div>
        
    )
  }
  export default OnboardConsent