import React, { useState, useEffect } from 'react';
import styled from "styled-components"
import { useAuth0 } from '@auth0/auth0-react';
import media from "../styles"
import { HelpCircle } from '@styled-icons/boxicons-solid/HelpCircle'
import HeroBG from "../../images/woman3bg.png"
import { Email } from '@styled-icons/material/Email'
import { Link } from "gatsby"


/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */




const EmailIcon = styled(Email)`

width: 35px;
height: auto;
color: #1D9BF0;
margin-right: 8px;
padding-bottom: 1px;
opacity: 0.75;

`


const HelpSVG = styled(HelpCircle)`

width: 50px;
opacity: 0.25;
//z-index: -1;
color: red;
display: inline-block;



`

const Card = styled.div`
  position: relative;
  //background-color: #DCFF93;
  //background-color: rgb(247 249 249);
  //background-color: white;
  border: 1px solid;
  border-color: rgb(239 243 244);
  border-radius: 16px;
  padding: 32px;
  color: rgba(0,0,0,0.95);
  font-family: -apple-system, BlinkMacSystemFont, “Segoe UI”, Roboto, Helvetica, Arial, sans-serif;
  //height: 280px;
  overflow: hidden;
  margin-bottom: 16px;

  ${media.desktop`
  //height: 240px;
`}

  h2 {
    font-weight: 900;
  font-size: 28px;
  line-height: 28px;
  }

  p {
    font-weight: normal;
  font-size: 15px;
  line-height: 23px;
  margin: 8px 0 0px 0;
  
  }


 ul {

  list-style: none;
 }

 a {
  text-decoration: none;
  color: #1D9BF0;
  font-weight: bold;
}

`

const Italic = styled.span`

    font-style: italic;
    
`

const Underline = styled.span`
//border-bottom: 3px solid rgba(29, 155, 240);
    //text-decoration: underline solid rgb(29 155 240);
    //text-decoration-thickness: 2px;
    //text-decoration-skip-ink: none;
    font-style: italic;
    
`

const Title = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;

p {
  font-size: 12px;
  margin: 0;
 
}
`

const EmailStyle = styled.div`
width: 100%;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
margin-top: 38px;
flex-wrap: wrap;


p {
  font-size: 18px;
  margin: 0;
}

a {
  text-decoration: none;
  color: rgba(0,0,0,0.95);
}
`



const HelpContact = () => {
  const { user } = useAuth0();
  
  
  //var newdate = new Date().toLocaleString()
  
 



  
  return (
    
      <>
        <Card>
          
          <Title>
        <h2>Need Help?</h2>
        
          </Title>

          <div>
        <p>To receive your bespoke company names, simply: 
          <ul>
            <li><b>
            1. Create your brief.</b></li>
            <li><b>2. Complete your payment to upgrade your account.</b></li>
            </ul>
            You'll have some fantastic new company names in no time!</p><br />

            <p><i>You can complete your brief and payment in any order.</i></p><br />

            <p><b>Need to resubmit your brief?</b><br />If you make any mistakes in your brief, an accidental submission, or simply have some fresh ideas, you can you redo it any time. (As many times as you need.)</p><p>Although since the naming process is already underway, it isn't recommended more than once/twice or <i>beyond 24 hours of your payment.</i> At that point it can be difficult to accomodate changes, but we'll always do our best.</p><br />

        <p><b>Contact us</b><br />For any questions or if you need help (perhaps you're unsure how to answer some questions during your brief for example) don't hesistate to get in touch via email at <a href="mailto:nameflow@human-8.com">nameflow@human-8.com</a>. We'll be happy to assist you 🙂</p>
          </div>

       


        </Card>
      </>
    )
  
}

export default HelpContact
