// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nameflow-js": () => import("./../../../src/pages/nameflow.js" /* webpackChunkName: "component---src-pages-nameflow-js" */),
  "component---src-pages-nameflow-redirect-test-js": () => import("./../../../src/pages/nameflow/redirect_test.js" /* webpackChunkName: "component---src-pages-nameflow-redirect-test-js" */),
  "component---src-pages-nameflow-welcome-js": () => import("./../../../src/pages/nameflow/welcome.js" /* webpackChunkName: "component---src-pages-nameflow-welcome-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-templates-new-content-post-js": () => import("./../../../src/templates/new-content-post.js" /* webpackChunkName: "component---src-templates-new-content-post-js" */)
}

