/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it


import React from 'react';
import { GlobalStyles } from './src/components/globalstyles';
import { Auth0Provider } from '@auth0/auth0-react';
import { navigate } from 'gatsby';
import Wrapper from './src/components/wrapper';
import './src/styles/global.css'




    const onRedirectCallback = (appState) => navigate(appState?.returnTo || '/nameflow/welcome');
    
    export const wrapRootElement = ({ element }) => {
      return (
        
        <Auth0Provider
          domain={process.env.GATSBY_AUTH0_DOMAIN}
          clientId={process.env.GATSBY_AUTH0_CLIENTID}
          redirectUri={process.env.GATSBY_AUTH0_REDIRECTURI}
          audience='https://nameflow.eu.auth0.com/api/v2/'
        scope='update:current_user_metadata'
        
        onRedirectCallback={onRedirectCallback}
          useRefreshTokens={true}
          cacheLocation="localstorage"
        ><Wrapper><GlobalStyles />
          {element}</Wrapper>
        </Auth0Provider>
      );
      
    };