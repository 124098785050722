/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
//import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Header from "./header"
import StickyBar from "./header/stickybar"
import Footer from "./header/h8footer"




const Layout = ({ children }) => (
  
      <>
       {/*<StickyBar />*/}
       <Header />
     {children}
     <Footer />
      </>
    
  
)



export default Layout
