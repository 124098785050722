import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import media from "../styles"
import { useAuth0 } from "@auth0/auth0-react";
import Board from "../../images/svg/approve.svg"
import Mark from "../../images/brand/eight.svg"
import { HomePageTextRotator } from "../homepagetextrotator"
import FullForm from "../contactfull"


const FormWidth = styled.div`
max-width: 650px;
height: 100%;
width: 100%;
margin: auto;
padding: 36px;
background-color: #EBEBF8;
//box-shadow: 0 5px 18px rgba(37,35,40,.08), 0 2px 10px rgba(37,35,40,.05);
display: flex;
justify-content: flex-end;
align-content: flex-end;
flex-direction: column;
box-sizing: border-box;


border-radius: 4px;
   // box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px;



                ${media.large`
               

                `}

  form {
    text-align: left;
    width: 100%;
    margin: auto;
    
font-weight: bold;
font-size: 14px;


  }

  

  input {
    box-sizing: border-box;
    font-size: 16px;
    line-height: 28px;
    padding: 8px 16px;
    //width: 100%;
    //min-height: 44px;
    border: unset;
    border-radius: 4px;
    outline-color: rgb(84 105 212 / 0.5);
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, 
                rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                rgba(0, 0, 0, 0) 0px 0px 0px 0px;
}


  }

`


const Eight = styled(Mark)`


width: auto;
fill: rgba(0,0,0,0.025);
`


const Image = styled.div`

width: 50%;
height: auto;

img {
  filter: opacity(95%) saturate(135%) blur(0px) drop-shadow(2px 2px 5px rgb(0 0 0 / 0.25)) contrast(110%) brightness(102%);
}

`

const ImageWrap = styled.div`


display: flex;
flex-direction: row;
justify-content: center:
align-items: center;
align-self: center;
max-width: 600px;
width: 100%;
${media.large`
                margin-right: -100px;

                `}

                

`


const Whiteboard = styled(Board)`

width: 400px;
`
const Shift = styled.div`
margin: 0 auto 20px auto;
width: 150px;
`



const SignUpButton = () => {
  const { loginWithRedirect } = useAuth0();

  return <SignUp onClick={() => loginWithRedirect({screen_hint:"signup"})}>Get Started</SignUp>;
};


const SignUp = styled.button`
font-family: -apple-system, BlinkMacSystemFont, “Segoe UI”, Roboto, Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 17px;
background: none;
//background-color: rgb(29 155 240);
border: 1px solid white;
border-radius: 9999px;
padding: 10px 0 10px 0;
min-height: 44px;
width: 300px;
margin-top: 24px;
cursor: pointer;
//letter-spacing: -0.01em;


//color: #FF0063;
color: rgba(255,255,255,1);


`
const BackG = styled.div`
//background-color: #DFF7E8;#EBEBF8
background-color: #EBEBF8;
width: 100%;
display: flex;
`

const Wrap = styled.div`
display: flex;
max-width: 1200px;
flex-direction: column;
align-items: center;
justify-content: center;
flex-wrap: wrap;
width: 100%;
padding: 100px 20px 100px 20px;
box-sizing: border-box;


margin: auto;


border-radius: 0;
  
  line-height: 23px;
 
  text-align: left;

  ${media.xsmall`
                
  padding: 100px 40px 0px 40px;
                             
              
                              `}
  
                              h1 {
font-family: 'GoudyOldStyleBold';
-webkit-font-smoothing: antialiased;
font-variant-ligatures: none;
letter-spacing: -2px;
margin-top: 0;
font-size: 52px;
line-height: 52px;
white-space: pre-wrap;
margin-bottom: 24px;
font-weight: normal;
color: #121212;
//text-align: left;

${media.phone`

text-align: center;




                `}


${media.tablet`
font-size: 64px;
line-height: 64px;

                `}

                ${media.large`
margin-bottom: 100px;
text-align: left;

                `}

  }

  h2 {

    font-family: 'GoudyOldStyleRegular';
    
font-size: 22px;
letter-spacing: -1px;
//line-height: 30px;
white-space: pre-wrap;
//max-width: 300px;
//margin-bottom: 20px;
//color: rgb(38 38 39);
color: #121212;
text-align: center;
font-weight: normal;

${media.phone`

text-align: center;
max-width: none;




                `}

${media.tablet`
font-size: 24px;
line-height: 32px;

width: auto;
max-width: none;

                `}
                ${media.large`

text-align: left;
                `}

  }

  p {


font-size: 18px;
line-height: 26px;
white-space: pre-wrap;
margin: 0 0 48px 0;

${media.large`

text-align: left;

                `}


  }

  ul {
    
    
    margin: 20px 0 0 0;
    
  list-style: none;
  padding: none;
  -webkit-padding-start: 0;
 //margin: 0;
  text-align: center;
  font-size: 14px;
}

  ${media.tablet`
  
align-items: center;
justify-content: center;
padding: 100px 20px 0px 20px;
flex-wrap: nowrap;
text-align: left;
//margin-bottom: 200px;


                `}

                ${media.desktop`
                //border-radius: 16px;
                flex-direction: row;
                justify-content: start;
                margin-bottom: 100px;
                `}


`


const Italic = styled.span `
font-style: italic;

`

const Ratings = styled.div `
display: flex;
align-items: center;
justify-content: center;
flex-direction: row;
margin-top: 50px;

p {
  color: rgba(0,0,0,0.5)
}

div {
  margin: 0 25px 0 25px;
}

`

const Width = styled.div `
width: 100%;

`



const Tag = styled.span`

background-color: #FF8C78;
padding: 2px 5px 2px 5px;
color: rgba(0,0,0,0.8);
border-radius: 4px;
font-size: 13px;
font-weight: bold;
margin-left: 2px;


`

const Underline = styled.span`
text-decoration: underline 2px solid yellow;
text-decoration-skip-ink: none;

`


const Grid = styled.div`

display: flex;
flex-direction: column;
justify-content: center;
align-items: start;
p{
  margin-bottom: 60px;
}

${media.desktop`
  flex-direction: row;
  padding-left: 100px;
  p{
  margin-bottom: 24px;
 
}



                `}

`

const Item = styled.div`

display: flex;
flex-direction: column;
justify-content: center;
align-items: start;
max-width: 300px;
border-left: 1px solid #f2f2f2;
padding-left: 24px;
position: relative;

h3 {
  
  margin: 0;
  padding: 0;
  font-size: 28px;
  font-family: 'AkzidenzGrotesk_Bold';
  letter-spacing: -1px;
  white-space: pre-wrap;
  text-align: left;
  line-height: 36px;
}

p{
  text-align: left;
  width: 75%;
  margin-top: 24px;
  font-size: 16px;
  line-height: 24px;
  
}

`



const Title = styled.div`


width: 100%;

${media.large`
 width: 100%;
 padding-right: 60px;
margin-bottom: 80px;


                `}



h2{
  font-size: 16px;
  font-family: 'AkzidenzGrotesk_Regular';
  border-left: 10px solid rgb(230, 255, 0);
  letter-spacing: -0.3px;
  white-space: pre-wrap;
 margin: 0;
 margin-bottom: 16px;
  line-height: 26px;
  color: rgba(0,0,0,0.5);
}

h3 {

  font-size: 48px;
  font-family: 'AkzidenzGrotesk_Bold';
  letter-spacing: -2px;
  margin: 0;
  margin-bottom: 48px;
  
 
  line-height: 56px;

}

p {

  font-size: 24px;
  line-height: 40px;
  letter-spacing: -0.25px;
  max-width: 650px;
  width: 100%;
  font-family: 'AkzidenzGrotesk_Regular';
  margin-bottom: 24px;
  -webkit-font-smoothing: antialiased;
font-variant-ligatures: none;
color: rgba(0,0,0,0.9);
//margin-left: 48px;
}


`



const Highlight = styled.span`

background-color: #CBF6FF;
padding: 2px 5px 2px 5px;
color: rgba(0,0,0,0.8);
border-radius: 4px;


font-size: 12px;
line-height: 20px;

`

const Goudy = styled.span`

font-family: 'GoudyOldStyleItalic';
font-size: 82px;
`

const Normal = styled.span`

font-family: 'AkzidenzGrotesk_Medium';
color: rgba(0,0,0,0.6);

`
  
  
const Promise = () => (
 
<StaticQuery
  query={graphql`
    query {
      placeholderImage: file(relativePath: { eq: "man.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            aspectRatio
            ...GatsbyImageSharpFluid
          }
          
        }
      }
    
      woman: file(relativePath: { eq: "woman3.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            aspectRatio
            ...GatsbyImageSharpFluid
          }
          
        }
      }

    


    }

    
  `}

  render={data => (
  
 

    <>

 
<BackG>
   <Wrap>
 
     <Title>
      
       
    
    
        <FormWidth>
       <FullForm />
            </FormWidth>
       
       
     </Title>

     {/*<Title>
      
       
    
    
        <ImageWrap> <Eight /></ImageWrap>
            
       
       
     </Title>
  */}
     
    </Wrap>
 </BackG>
  
</>
 )}

 />

 )

export default Promise
