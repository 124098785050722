import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import media from "../styles"
import { useAuth0 } from "@auth0/auth0-react";
import Star from "../../images/svg/stars.svg"
import Board from "../../images/svg/approve.svg"
import moment from 'moment'
import Moment from 'react-moment';
import { Check } from '@styled-icons/bootstrap/Check'
import { Cross } from '@styled-icons/entypo/Cross'
import Word from "../../images/brand/nameflow.svg"
import Promise from "../../images/svg/promise_n.svg"
import Checkout from "../checkout";




const ButtonCenter = styled.div`

display: flex;
flex-direction: row;
justify-content: center;
align-items: center;


`


const PromiseLogo = styled(Promise)`
width: 130px;
height: auto;
fill: rgba(0,0,0,0.8);
shape-rendering: geometricPrecision;
margin-bottom: -3px;


`


const PricePosition = styled.div`

margin-top: auto;



`
const Mark = styled.div`

height: auto;

`
const Circle = styled.div`
height: 75px;
width: 75px;
border-radius: 50%;
background-color: ${props => props.color || "white"};
color: ${props => props.textColor || "white"};
margin: auto;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
border: ${props => props.border || "none"};


`
const Banner = styled.div`

//height: 15px;
background-color: ${props => props.color || "white"};
width: 100%;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
color: ${props => props.textColor || "white"};

p {
  margin: 0 !important;
  padding: 2px 0 2px 0;
  font-size: 14px;
  line-height: 22px;
  
  letter-spacing: 3px;
}
`

const WordMark = styled(Word)`

fill: #131313;
opacity: 1;
shape-rendering: geometricPrecision;

width: 100px;

`


const Popular = styled.div`

color: rgba(0,0,0,0.8);
position: absolute;
top: 30px;
left: 0;
right: 0;

`


const CheckIcon = styled(Check)`

width: 25px;
color: rgb(14 98 69);


`

const CrossIcon = styled(Cross)`

width: 25px;
color: red;

`
const Faded = styled.span`

opacity: 0.5;
`

const Card = styled.div`
background-color: white;
margin: 24px 0 24px 0;
max-width: 360px;
box-shadow: rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 6%) 0px 2px 12px, rgb(0 0 0 / 4%) 0px 8px 14px, rgb(0 0 0 / 2%) 0px 12px 16px;
border-radius: 16px;
width: 100%;
position: relative;
box-sizing: border-box;
min-height: 800px;
display: flex;
flex-direction: column;
justify-content: flex-start;
overflow: hidden;
border: ${props => props.border || "none"};
padding: 0 0 38px 0;

${media.phone`
margin: 24px 8px 24px 8px;

                `}


`

const Features = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
align-items: flex-start;

flex-wrap: wrap;
width: 100%;
//width: 100%;


ul {
    list-style-type: none;
    //margin: 24px 0px 24px 0px;
    padding: 12px 8px 16px 8px !important;
    
    //border: 2px solid;
    border-color: rgb(239 243 244);
    //background-color: rgba(255,255,255, 0.75);
    text-align: center;

    
    ${media.phone`
    padding: 12px 32px 16px 32px;

                `}
    
    
  
    li {
      margin-bottom: 6px;
      font-size: 13px !important;
   
      
      
      
    }

  
  }


`

const Tag = styled.span`
display: inline;
font-size: 12px;
line-height: 20px;
//background-color: rgb(203 244 201);
background: #78ffd6; /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #78ffd6, #a8ff78);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #78ffd6, #a8ff78); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
margin-left: 8px;
padding: 2px 5px 2px 5px;
border-radius: 4px;
`

const Price = styled.div`

font-weight: 900;
  font-size: 28px;
  line-height: 28px;
  color: rgba(0,0,0, 0.25);
  text-align: left;
  margin: 24px 0 0 0;

`
const PriceFont = styled.div`

font-size: 23px;
//margin-bottom: 50px;
white-space: pre-line;
color: ${props => props.color || "white"};

`
const Gold = styled.span`
background: linear-gradient(#F0CB35, 80%, #C02425);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

`

const Heading = styled.div`

padding-left: 8px;
margin-bottom: 24px;
h3 {
font-weight: 900;
  font-size: 24px;
  line-height: 32px;
  color: rgba(0,0,0, 0.95);
  text-align: center;
  margin: 0;
}
span {
  //color: rgba(0,0,0, 0.75);
}
p {
  margin: 0;
  padding: 0;
  font-size: 13px;
  line-height: 21px;
  color: rgba(0,0,0, 0.6);
  text-align: center;
}
`

const Title = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;

p {

  margin: 0;
}
`





const Stars = styled(Star)`

height: 15px;

`


const Whiteboard = styled(Board)`

width: 400px;
`
const Shift = styled.div`
margin: 0 auto 20px auto;
width: 150px;
`



const SignUpButton = () => {
  const { loginWithRedirect } = useAuth0();

  return <SignUp onClick={() => loginWithRedirect({screen_hint:"signup"})}>Get started</SignUp>;
};


const SignUp = styled.button`
font-family: -apple-system, BlinkMacSystemFont, “Segoe UI”, Roboto, Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 17px;
//background: #A6FF00;
background-color: rgb(29 155 240);
border: none;
border-radius: 9999px;
padding: 10px 0 10px 0;
min-height: 44px;
width: 100%;
margin-top: 24px;
cursor: pointer;
//letter-spacing: -0.01em;
//color: #FF0063;
color: rgba(255,255,255,1);



${media.xsmall`
              
width: 300px;
             

              `}


`


const Wrap = styled.div`
//transform: translate(0,-100px);
//margin-top: -100px !important;
display: flex;
position: relative:
flex-direction: column;
align-items: center;
justify-content: center;
flex-wrap: wrap;
width: 100%;
padding: 0;
box-sizing: border-box;
//color: rgba(255,255,255,1);
color: rgba(0,0,0,1);
//max-width: 1200px;
margin: auto;
//margin-bottom: 35px;


//background-color: white;
//border: 2px solid;
  //border-color: rgb(239 243 244);
  // > background-color: rgb(238 91 122);
border-radius: 0;
  
  line-height: 23px;
  //margin: 28px 0 0 0;
  text-align: left;

  ${media.xsmall`
                
  padding: 0;
               

                `}
  
  h1 {
font-family: 'AkzidenzGrotesk_Light_Bold';

font-weight: normal ;
//-webkit-font-weight: bold ;
//font-synthesis: weight;
font-size: 64px;
line-height: 76px;
white-space: pre-wrap;
//margin-bottom: 36px;
margin-top: 0px;


  }

  h2 {


    font-family: 'AkzidenzGrotesk_Light';

font-size: 42px;
line-height: 56px;
white-space: pre-wrap;
font-weight: normal;
margin: 0;
color: rgba(0,0,0,0.8);
margin-top: 24px;


  }

  



  ${media.tablet`
  flex-direction: column;
align-items: center;
justify-content: center;
padding: 0;
flex-wrap: nowrap;
text-align: left;


                `}

                ${media.desktop`
                border-radius: 16px;
                //margin-bottom: 100px;

                `}



`


const Italic = styled.span `
font-style: italic;

`

const Ratings = styled.div `
display: flex;
align-items: center;
justify-content: center;
flex-direction: row;
margin-top: 50px;

p {
  color: rgba(0,0,0,0.5)
}

div {
  margin: 0 25px 0 25px;
}

`

const Width = styled.div `
width: 100%;


`




const Program = styled.span`


padding: 2px 5px 2px 5px;
//color: rgba(0,0,0,0.8);
border-radius: 4px;
font-size: 28px;
font-weight: bold;
margin-left: 2px;


`



const Underline = styled.span`
//text-decoration: underline 5px solid yellow;
text-decoration-skip-ink: none;

text-decoration-line: underline;
text-decoration-style: solid;
text-decoration-thickness:5px;
text-decoration-color: yellow;
-webkit-text-decoration-line: underline;
-webkit-text-decoration-style: solid;
-webkit-text-decoration-thickness: 5px;
-webkit-text-decoration-color: yellow;

`

const Highlight = styled.span`

background-color: #CBF6FF;
padding: 2px 5px 2px 5px;
color: rgba(0,0,0,0.8);
border-radius: 4px;

//margin-left: 2px;

`


const Strikethrough = styled.span`

//text-decoration: solid line-through 3px !important;
text-decoration-line: line-through;
text-decoration-style: solid;
text-decoration-thickness: 3px;
-webkit-text-decoration-line: line-through;
-webkit-text-decoration-style: solid;
-webkit-text-decoration-thickness: 3px;

`

const Color = styled.span`
font-family: 'AkzidenzGrotesk_Light_Bold';
color: #FF8C78;
font-variant-ligatures: none;

`
  
const Pricing = (props) => {
 

  var momentDate = moment()
  return (

    <>

 

   <Wrap>
     
       
     
         
       
       <Features>
       
        <Card>
          <Banner color="#21d4fd" textColor="white"><p>5 x Names</p></Banner>

          
        
        <ul>
        {/*<Mark><WordMark /></Mark>*/}
        <Heading><h3><span><WordMark /></span></h3>
        
        
        
        <p>Get creative, unique names quickly.</p></Heading>

     
        
        
        <li><CheckIcon />Custom name development
          </li>
          <li><CheckIcon />Never machine-generated names
          </li>
         
          
          <li><CheckIcon /><b>Five</b> unique company names
          </li>
          
          <li><CheckIcon />Low cost⁠—<Highlight>only <b>€19.80</b> per name</Highlight>
          </li>
          <li><CheckIcon />Supercharged—ready in three days
          </li>
          <li><CheckIcon />Based on your custom brief
          </li>
          <li><CheckIcon />Real human brand strategists
          </li>
          <li><CheckIcon />Great way to try Nameflow
          </li>
          <li><CheckIcon /><PromiseLogo />
          </li>
          
          
          
          
        </ul>
        
        <PricePosition>
        <Heading><h3><PriceFont><Circle color="#21d4fd" border="solid 2px #21d4fd">€99</Circle></PriceFont></h3></Heading>
        <ButtonCenter>
        <Checkout lineitem="price_1KLGqDLDxKtMxkBNXyHSWktM" color="#21d4fd" />
        </ButtonCenter>
        </PricePosition>
        </Card>


        <Card>
        <Banner color="#b721ff" textColor="white"><p>10 x Names + Screening</p></Banner>
        
        
        
       
        <ul>
        {/*<Mark><WordMark /></Mark>*/}
        <Heading><h3><span><WordMark /></span> <Gold>Premium+</Gold></h3>
    
        <p>Collaborate and explore more name ideas.</p></Heading>

       


        <li><CheckIcon />Custom name development
          </li>
          <li><CheckIcon />Never machine-generated names
          </li>
          
          
          <li><CheckIcon /><b>Ten</b> unique company names
          </li>
          <li><CheckIcon />One feedback round after five names
          </li>
          <li><CheckIcon />First round ready in three days
          </li>
          <li><CheckIcon />Based on your custom brief
          </li>
          <li><CheckIcon />Real human brand strategists
          </li>
          <li><CheckIcon />Preliminary trademark & domain screening
          </li>
          
          <li><CheckIcon />Beautiful PDF presentation
          </li>
          <li><CheckIcon />Optional Zoom call included
          </li>
          <li><CheckIcon /><PromiseLogo />
          </li>
          
          
        </ul>
        <PricePosition>
        
        <Heading><h3><PriceFont><Circle color="#b721ff" border="solid 2px #F0CB35">€249</Circle></PriceFont></h3></Heading>
        <ButtonCenter>
        <Checkout lineitem="price_1KIMlGLDxKtMxkBNwkQr0Utb" color="#b721ff" border="solid 2px #F0CB35" />
        </ButtonCenter>
        </PricePosition>
        </Card>


        
        
        </Features>





     

     
    
     
    
    





     
    </Wrap>
 
  
</>
  )
}
export default Pricing
